.patient-search {
  float: left !important;
  margin: 16px;
  margin-left: 24px;
}

.patient-search-list {
  background-color: white !important;
  /* width: 99%; */
  border-radius: 4px;
  padding-bottom: 5px;
}

.radio-patient-field {
  float: left;
  margin-top: 5px !important;
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
}
.right-block {
  /* margin-left: 250px; */
}
.pat-active-bg {
  background-color: #ecf7f0 !important;
}
.active-badge {
  color: #57bf7f;
  float: left;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: 79px;
  height: 25px;
}
.inactive-badge {
  color: rgb(216, 42, 42);
  float: left;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  width: 79px;
  height: 25px;
}

.dot-active {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -1px;
  width: 8px;
  background-color: #57bf7f;
  margin-top: -2px;
}
.dot-inactive {
  border-radius: 50%;
  margin-top: -2px;
  display: inline-block;
  height: 8px;
  position: relative;
  top: -1px;
  width: 8px;
  background-color: #d43731;
}
/*jayabalaji*/
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active
  > .top-pat-dropdown.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #3182ce !important;
}

.appt-block-main-bill #dropdown-basic {
  background-color: #fff !important;
}

/*jayabalaji*/
.top-pat-dropdown.btn.btn-primary {
  background-color: white;
  border: none;
}
.top-pat-dropdown.btn.btn-primary:focus {
  border: none;
  box-shadow: none;
  background-color: white;
}

.import-clients {
  /* float: left; */
  margin-top: 15px;
  /* margin-left: 15px; */
  color: #2066c2;
  background-color: white;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 7%), 0px 0.5px 2px rgb(0 0 0 / 7%);
  border-radius: 4px;
  /* text-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
}

.import-clients svg {
  margin-right: 5px;
  margin-top: -5px;
}
.btn-pat {
  float: right;
  margin-right: 24px;
}

.loading-more {
  padding-top: 50px;
  padding-bottom: 10px;
}

.create-cli {
  background-color: #007bff !important;
  color: white !important;
  margin-top: 15px;
  margin-left: 15px;
  border-radius: 4px;
  font-weight: 600;
}

.create-cli svg {
  margin-right: 5px;
  margin-top: -5px;
}

.MuiTableHead-root {
  border-top: 1px solid #cbd5e0;
}

/* .MuiTableHead-root tr {
  height: 41px !important;
} */
.MuiTableHead-root th {
  font-size: 14px;
}
.MuiTableRow-root th {
  font-size: 14px;
  font-weight: 500;
}

.MuiTableRow-root.MuiTableRow-head th {
  color: #005fbe !important;
  font-weight: 400;
}

.MuiTableRow-root td {
  font-size: 14px;
}

.spl-color appt-stat m-t-10 {
  color: #005fbe;
  font-size: 13px;
}
/*jayabalaji*/
.MuiTableRow-root th {
  color: #005fbe;
  font-weight: 500;
}
/*jayabalaji*/
.pat-list-thead th {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f1f5f8;
}
.MuiTableCell-root {
  border-bottom: 0px solid #cbd5e0 !important;
}

.MuiTableRow-head {
  border-bottom: none !important;
}

.MuiTableRow-root {
  height: 56px;
  border-bottom: 1px solid #cbd5e0 !important;
}

.patient-list-body tr:nth-child(odd) {
  border-bottom: 1px solid #cbd5e0 !important;
}
.patient-list-body tr:nth-child(even) {
  border-bottom: 1px solid #cbd5e0 !important;
}
.patient-list-body tr th {
  display: flex;
}
.MuiTableContainer-root {
  box-shadow: none !important;
  margin-top: -6px !important;
}

.pat-list-img {
  margin-right: 10px;
}

.age-div {
  color: #2d3748;
}

.avatar-right-div {
  margin-left: 12px;
}

.patient-pro-data {
  width: 100%;
  padding: 16px;
  background: white;
  border-radius: 8px 8px 0px 0px;
  line-height: 1.4;
}
.patient-pro-data-demo {
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
}

.pat-data-left {
  width: 50%;
  margin-left: 25px;
}

.b-R-40 {
  border-radius: 40px;
}
.m-l-212 {
  margin-left: 212px;
}
.pat-left-div {
  width: 17.5%;
  /* background-color: white; */
  border-radius: 8px;
}

.pat-left-icon {
  padding: 10px 0px 10px 0px;
  justify-content: space-evenly;
}
.pat-left-margin {
  margin: 0px 0px 6px 18px;
  /* align-items: center; */
  text-align: left;
  word-break: break-all;
}
.pat-image-right {
  margin-right: 10px;
}
.w-82-5 {
  width: 82.5%;
}
.w-72-5 {
  width: 72.5%;
}
.pat-image-right1 {
  width: 92.6%;
  background: white;
  margin-right: 24px;
  border-radius: 8px 8px 4px 0px;
}
.pat-image-right2 {
  width: 92.6%;
  background: white;
  margin-right: 0px;
  border-radius: 8px 8px 4px 0px;
}
.pat-image-right3 {
  width: 102.6%;
  background: white;
  margin-right: 0px;
  border-radius: 8px 8px 4px 0px;
}
.pat-image-right4 {
  width: 92.8%;
  background: white;
  margin-right: 0px;
  border-radius: 8px 8px 4px 0px;
}
.m-t-0 {
  margin-top: 0px !important;
}
.pat-mid-div {
  width: 57.5%;
  /* background-color: white; */
  border-radius: 8px;
}

.pat-name {
  color: black;
  font-size: 16px;
  font-weight: 500;
}

.pad-bot-8 {
  padding-bottom: 5px;
}

.pat-details {
  color: #718096;
}

.pro-name {
  color: #2066c2;
  font-size: 14px;
  margin-left: 8px;
  font-weight: 500;
}

.other-details {
  color: #2d3748;
  font-size: 13px;
  margin-left: 8px;
  font-weight: 500;
}

.edit-profile {
  float: right;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  background-color: white;
  color: #2066c2;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
  /* text-shadow: 0px 4px 4px rgb(0 0 0 / 25%); */
  font-weight: 500;
}
.edit-profile a {
  color: #718096 !important;
}
.edit-profile a:hover {
  color: white !important;
}
.btn-primary :hover {
  /* color: #88b3e2 !important; */
}

.pat-data-left button svg a:hover {
  color: #718096 !important;
}

.edit-profile svg {
  margin-right: 5px;
}

.img-pat {
  border-radius: 10%;
}
/*jayabalaji*/
.pat-btn-layer {
  border-top: 1px solid #cbd5e0;
  padding-top: 18px;
  padding-bottom: 18px;
  /* float: right; */
  text-align: end;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
}
/*jayabalaji*/
.m-l-r-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.create-appt {
  background-color: #2c7be5;
  height: 32px !important;
  font-weight: 500 !important;
}
.vitals-btn {
  background-color: white;
  border: 1px solid #cbd5e0;
  color: #718096;
  width: 85px !important;
  height: 35px !important;
}

.vitals-block {
  flex: 0 0 13.66% !important;
  border-right: 1px solid #cbd5e0;
}

.b-r-0 {
  border-right: 0px solid #cbd5e0 !important;
}

.vitals_main {
  margin-top: 20px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 10px;
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  display: flex;
  color: #2d3748;
  overflow-x: auto;
}

.vitals-detail {
  text-align: left;
  margin-left: 35px;
}

.vitals-num {
  color: black;
  font-weight: 600;
  margin-right: 10px;
  margin-left: 10px;
}

.patient-pro-data-main {
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
}

.appoint-main {
  /* margin-top: 20px; */
  background-color: white;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  color: #2d3748;
}
.pat-block-main {
  /* margin-top: 20px; */
  margin-bottom: 10px;
  background-color: white;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  color: #2d3748;
}
.appt-block-main {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding-bottom: 20px;
  border-radius: 4px;
  /* box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1); */
  color: #2d3748;
}
.appt-block-main .ant-tabs-nav {
  padding-left: 20px;
  padding-right: 20px;
  /* padding-top: 20px; */
  /* margin-bottom: 0px; */
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2066c2;
}

.appt-head {
  border-top: 0px !important;
}
#f-apt .MuiTableHead-root .MuiTableRow-root {
  border-bottom: 0px !important;
}

/* .table-data-row {
  height: 45px;
} */

.appoint-main .ant-tabs-nav {
  margin-bottom: 0px !important;
}

.pat-right-div {
  width: 25%;
  margin-left: 20px;
  margin-right: 20px;
}
.pat-right-div1 {
  width: 25%;
  margin-left: 16px;
  margin-right: 20px !important;
}
.bill-block {
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
}

.bill-head {
  text-align: initial;
  font-size: 14px;
  font-weight: 500;
}

.bill-left {
  font-size: 13px;
  text-align: initial;
}

.bill-right {
  font-size: 13px;
}

.m-color {
  color: #ea3a3a;
}

.appt-stat {
  text-align: initial;
  font-weight: 500;
}
.spl-color {
  color: #2066c2;
}

.add-pay {
  font-weight: 500;
  margin-top: 10px;
  padding-top: 10px;
  margin-left: -12px;
  margin-right: -12px;
  border-top: 1px solid #cbd5e0;
  cursor: pointer;
}

.insurance-block {
  background-color: white;
  padding: 12px;
  margin-top: 20px;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  border-radius: 4px;
}

.insurance-head {
  text-align: initial;
  font-weight: 500;
  font-size: 14px;
}

.ins-name {
  color: #2066c2;
}

.ins-edit {
  color: #2066c2;
  margin-left: 5px;
}

.arrow-right-bar {
  float: right;
  background-color: white;
  border: 0px;
  padding: 0px;
}
.back-icon {
  margin-top: -4px;
  margin-right: 5px;
}

.pat-demo-data-left {
  width: 300px;
  margin-left: 25px;
}
@media only screen and (max-width: 1100px) and (min-width: 500px) {
  .btn-demo-pat {
    padding: 10px 0 10px 4px;
  }
}
@media only screen and (max-width: 2000px) and (min-width: 1100px) {
  .btn-demo-pat {
    padding: 10px 0 10px 20px;
  }
}

#demographics .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2066c2;
  font-weight: 500;
}
.ant-tabs-ink-bar {
  background: #2066c2 !important;
}
.pat-edit-btn {
  margin-left: -20px !important;
  margin-top: 40px !important;
  background: #2066c2 !important;
  color: white !important;
}

.pat-name-demo {
  color: #2066c2;
  font-weight: 500;
  margin-right: 10px;
  font-size: 16px;
}

.pat-pa {
  color: #718096;
}

.pat-info-form {
  text-align: initial;
}
.search-pro input {
  border: 0px !important;
}

.hr-pat {
  border-top: 1px solid #cbd5e0;
}

.pat-head {
  color: #2d3748;
  font-size: 16px;
  font-weight: 500;
}

.pat-para {
  color: #718096;
  font-size: 12px;
}
.active-badge-pat {
  background-color: #ecf7f0;
  color: #57bf7f !important;
  float: left;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.inactive-badge-pat {
  background-color: rgba(255, 0, 0, 0.2);
  color: #ff0000;
  float: left;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pat-mob-dd {
  border: 1px solid #cbd5e0;
  background-color: white;
  color: #747474;
  height: 32px;
}

.pat-add-mob-dd {
  border: 1px solid #cbd5e0;
  background-color: white;
  color: #2066c2;
  height: 32px;
}
.pat-add-mob-dd:hover {
  background-color: #f4f4f4;
}
.pat-add-mob-dd:focus-within {
  background: none;
  color: #2066c2;
  box-shadow: none;
}
.btn-light:not(:disabled):not(.disabled):hover {
  background-color: #fff;
}
.form-control {
  height: 32px;
}

.pat-add-mob-dd svg {
  margin-top: -2px;
  margin-right: 5px;
}

.del-btn {
  background-color: #ff76761c;
  border: 0px;
  color: #ef5f5f;
  font-weight: 500;
}

.del-btn:hover {
  background-color: #ef5f5f;
  border: 0px;
  color: #ffffff;
  font-weight: 500;
}

.pat-sub-head {
  font-weight: 500;
  color: #2d3748;
  margin-bottom: 10px;
}

.radio-box-pat-ins {
  width: 325px;
}

.con-name {
  text-align: initial;
  color: black;
  font-weight: 600;
  font-size: 16px;
}

.con-left {
  width: 250px;
  text-align: initial;
  color: #718096;
}

.con-right {
  text-align: initial;
  color: #2d3748;
  margin-right: 10px;
}

.contact-block {
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  padding: 12px 24px 24px;
}

.manage-btn {
  margin-left: auto;
  color: #2d3748;
  font-weight: 500;
}

.edit-wo-b {
  color: #2066c2;
  font-weight: 500;
}

.edit-wo-b svg {
  margin-top: -7px;
  margin-right: 5px;
}

.btn-light:hover {
  background-color: none !important;
}
.m-r-l-10 svg {
  color: #005fbe !important;
}
.m-l-11 {
  color: red !important;
}
/* .makeStyles-table-2{
  margin-top: 10px !important;
}
.makeStyles-table-8{
  margin-top: 10px !important;
}
.makeStyles-table-6{
  margin-top: 10px !important;
}
.makeStyles-table-4{
  margin-top: 10px !important;
}
.makeStyles-table-5{
  margin-top: 10px !important;
}
.makeStyles-table-7{
  margin-top: 10px !important;
}
.makeStyles-table-3{
  margin-top: 10px !important;
}
.makeStyles-table-9{
  margin-top: 10px !important;
}
.makeStyles-table-10{
  margin-top: 10px !important;
}
.makeStyles-table-11{
  margin-top: 10px !important;
} */
/*jayabalaji*/
.MuiTableContainer-root {
  margin-top: 10px !important;
}
/*jayabalaji*/
.edit-note svg {
  margin-top: -5px;
  margin-right: 5px;
}
.shedule-date {
  color: #2066c2;
  font-weight: 500;
}
.shedule-time {
  color: #888888;
}

.schedule-pro-name {
  color: #2066c2;
}

.schedule-head-row {
  border-bottom: 1px solid #cbd5e0 !important;
  border-top: 1px solid #cbd5e0 !important;
  background-color: #ffffff;
}
.ant-tabs-nav {
  /* margin-bottom: 0px !important; */
}
.schedule-head-row th {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 0px;
}

.schedule-body-row td {
  padding-left: 18px;
  padding-right: 0px;
}

.prob-top-layer {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.procedure-det-del {
  text-align: center;
  background: #ffffff;
  color: red;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 7%), 0px 0.5px 2px rgb(0 0 0 / 7%);
  border-radius: 4px;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  font-size: 20px;
}
.procedure-det-del:hover {
  background-color: red;
  color: #fff;
}
.imm-main-block .prob-top-layer {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 0px;
}
.prob-head-title {
  color: #2066c2;
  font-weight: 500;
  align-items: center;
  margin-top: 5px;
  font-size: 20px;
}

.prob-head-right {
  display: flex;
  margin-left: auto;
}
.padin {
  padding-top: 5px !important;
  padding-bottom: 9px !important;
}
.m-te {
  margin-top: -2px;
}
.show-inactive {
  color: #99a3ae;
  margin-left: 5px;
}

.active-outlined,
.active-outlined:hover {
  color: #57bf7f !important;
  font-weight: 500;
}
.inactive-outlined,
.inactive-outlined:hover {
  color: #d43731 !important;
  font-weight: 500;
}
.resolved-outlined {
  color: #4019ff !important;
  font-weight: 500;
}

.appt-block-main .MuiTableCell-body {
  color: #636d73;
}

.fam-his-body tr {
  height: unset;
  /* border-bottom: 0px solid #cbd5e0 !important; */
}

.sec-row-fam {
  border-bottom: 0px solid #cbd5e0 !important;
}

.fam-btn {
  border: 1px solid #cbd5e0 !important;
  border-radius: 4px !important;
}

.fam-dromdown svg {
  margin-right: 5px;
}

.delete-color {
  color: #ef5f5f;
}

.imm-btn-ri {
  text-align: end;
  margin-right: 290px;
}
.imm-btn-ri-btn {
  margin-top: 12px;
  position: absolute;
  text-align: right;
  z-index: 100;
  border: 1px solid #cbd5e0;
  color: #2066c2;
  background-color: white;
}

.imm-btn-ri-btn svg {
  margin-top: -3px;
  margin-right: 5px;
}
.imm-main-block .ant-tabs-nav {
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;
}
.imm-main-block {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  color: #2d3748;
}

.imm-head-title {
  color: #2d3748;
  font-size: 16px;
  font-weight: 500;
  margin-top: 7px;
  margin-bottom: 9px;
}

.imm-age .ant-tabs-tab-active {
  border: 1px solid #cbd5e0 !important;
}

.imm-age .ant-tabs-tab {
  border: 1px solid #cbd5e0 !important;
  margin-left: 0px !important;
  border-left: 0px !important;
}
.imm-age .ant-tabs-tab:first-child {
  border-left: 1px solid #cbd5e0 !important;
}

.imm-sch {
  color: #fc9136;
}

.refuse-btn {
  color: #ef5f5f !important;
  background-color: white !important;
  border: 1px solid #cbd5e0 !important;
}

.imm-age .ant-tabs-nav {
  margin-bottom: 20px !important;
  padding-top: 0px;
}

.imm-age .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.imm-form-select {
  margin-bottom: 0px;
}

.imm-para {
  text-align: initial;
  padding-left: 20px;
  color: #718096;
}

.imm-div-1 {
  text-align: initial;
  border-top: 1px solid #cbd5e0;
  border-bottom: 1px solid #cbd5e0;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 500;
}

.imm-div-2 {
  border-bottom: 1px solid #cbd5e0;
  color: #718096;
  text-align: initial;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.imm-btn-row {
  margin-top: 20px;
  text-align: end;
  margin-right: 20px;
}

.imm-reacords label {
  float: left !important;
}

.imm-reacords hr {
  margin-left: -20px;
  margin-right: -15px;
  border-top: 1px solid #cbd5e0;
  margin-bottom: 21px;
}

.edit-pat-demo-data-left {
  width: 100%;
  margin-left: 25px;
}

.edit-pat-demo-data-left .pat-name {
  border-bottom: 1px solid #cbd5e0;
}

.edit-app {
  color: #2d3748;
  font-weight: 500;
  margin-right: 8px;
}

.edit-time {
  color: #747474;
  margin-right: 8px;
}

.edit-pro {
  color: #2066c2;
  font-weight: 500;
}

.edit-app-icon {
  border: 1px solid #cbd5e0;
  color: #718096;
}

.edit-nav ul {
  background-color: transparent !important;
}

.nav-head-edit {
  color: #2d3748;
  font-weight: 500;
}

.edit-nav .ant-menu-item-selected {
  background-color: transparent !important;
  color: #2066c2;
  font-weight: 500 !important;
}

.edit-nav .ant-menu-item-selected:after {
  border-right: 0px solid #1890ff;
}
.edit-nav .ant-menu-item {
  padding-left: 30px !important;
  font-weight: 400;
}

.edit-nav .ant-menu-submenu-title i {
  display: none;
}

.edit-nav .ant-menu-submenu-title {
  font-weight: 500;
  height: 20px !important;
  line-height: 20px !important;
}

.notes-top {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  /* padding-bottom: 16px; */
  font-size: 15px;
  font-weight: 500;
}

.notes-top button {
  margin-left: 10px;
  margin-top: 2px;
}

.edit-form-start {
  padding-left: 29px;
  padding-right: 29px;
  padding-top: 16px;
}

.edit-form-start label {
  float: left;
  color: #000000 !important;
}

.edit-form-start select {
  margin-right: 0px !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.edit-form-start .form-group {
  padding-left: 0px !important;
}

.edit-form-start input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: 29px;
}

.edit-form-start textarea.form-control {
  height: 150px;
  background: #fafafa;
}

.edit-nav .ant-menu-submenu-title {
  padding-left: 26px !important;
  font-size: 15px;
}

.edit-nav .ant-menu-item {
  height: 20px !important;
  line-height: 10px !important;
}

.edit-nav .schedule-title {
  margin-bottom: 10px;
}

.macro-btn {
  color: #2066c2;
}

.allergy-note {
  height: 40px;
}

.table-popup .makeStyles-table-4 {
  min-width: unset !important;
}
.table-popup {
  padding: 0px;
  border: 1px solid #cbd5e0;
}

.table-popup table thead tr {
  border-top: 0px solid #cbd5e0 !important;
}

.table-popup table tbody tr:last-child {
  border-bottom: 0px solid #cbd5e0 !important;
}

.table-popup .MuiTableRow-root th,
.table-popup .MuiTableRow-root td {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.search-contact .modal-header {
  flex-direction: row-reverse;
}

.search-contact .modal-header .modal-title {
  margin-right: auto;
  padding-left: 14px;
}

.search-contact .modal-header .close {
  padding: 0;
  margin-top: 12px;
}

.add-contact-name {
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-bottom: 10px;
}

.add-contact-form p {
  color: #718096;
  font-size: 13px;
}

.add-contact-form .new1 {
  margin-left: -20px;
  margin-right: -20px;
}

.family-history-dashboard .MuiTableCell-sizeSmall {
  padding: 6px 0px 6px 8px !important;
}
.ref {
  margin-left: -88px !important;
}
.gf {
  margin-left: -81px !important;
}
.edit-btm {
  float: right;
  margin-top: -55px;
}
.edit-btm button {
  /* margin-top: -100%; */
}

.ant-tabs-nav-wrap {
  /* padding-top: 10px; */
  /* padding-bottom: 12px; */
}
.ant-tabs-tab {
  padding: 0px 0px 12px 0px;
}
.red-col {
  color: red !important;
}
.width-tab {
  width: 469px;
  margin-left: -20px;
}
.onclick-svg {
  padding: 5px !important;
}
.tab-item-width {
  width: 1000px;
}
.width-group {
  width: 79%;
}
.p-r-30 {
  padding-right: 30px !important;
}
.m-l-l {
  margin-left: -106px;
  margin-top: 10px;
  z-index: 1000;
}
.checked-in {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.no-line {
  border-bottom: 0px solid white !important;
}
/* //////////// */
.table-top {
  margin-top: 1px;
}
.invoice {
  height: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
}
.invoice-no {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  display: flex;
  align-items: top;
  letter-spacing: 0.0025em;
  margin-bottom: 2px;
}
.invoice-date {
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 17px;
  font-weight: 400;
  margin-bottom: 8px;
  display: flex;
  align-items: top;
  letter-spacing: 0.0025em;
  text-align: left;
}
.invoice-logo {
  height: 78px;
  display: flex;
  align-items: center;
  margin-top: 11px;
  margin-left: 10px;
}
.bg-bill {
  background-color: #ffffff;
  padding-top: 14px;
}
.bg-bill1 {
  background-color: #fafafa;
  padding-top: 10px;
  padding-bottom: 10px;
}

.bill-pad {
  margin-left: 10px;
}
.bill-from {
  height: 30px;
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  font-size: 16px;
  font-family: Inter;
  line-height: 19px;
  font-weight: 500;
  margin-bottom: 3px;
}
.bill-text-clr {
  color: #718096;
}
.bill-bottom {
  padding-bottom: 9px;
}
.cont-width {
  width: 780px;
  margin-left: 250px;
  margin-right: 150px;
}
.table-head {
  background: #fafafa;
}
.fixed-height {
  height: 60px;
}

.table-s {
  font-size: 24px;
}
.invoice-no-dt {
  margin-left: 0px;
  padding-left: 4px;
  font-family: Inter, sans-serif;
}
.invoice-no-d {
  margin-left: 0px;
}
.hidden {
  display: none;
}
.pgstyle {
  margin-top: 0.1in;
  margin-bottom: 0in;
  margin-left: 0in;
  margin-right: 0in;
}
.pdfalign {
  margin-left: auto;
  margin-right: auto;
  width: 840px;
}
.invoice-content {
  width: 1010px;
  margin-bottom: 55px;
}
.hr-line1 {
  border-left: 2px solid #000000;
  height: 10px;
}
.font-s {
  font-size: 17px;
  line-height: 27px;
}

.filelabel {
  border: 2px dashed grey;
  border-radius: 5px;
  display: block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
}
.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}
.filelabel i,
.filelabel .title {
  color: grey;
  transition: 200ms color;
}
/* .filelabel:hover {
  border: 2px solid #1665c4;
} */
.filelabel:hover i,
.filelabel:hover .title {
  color: #1665c4;
}
#FileInput {
  display: none;
}

p.ant-upload-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload p {
  margin: 0;
}
p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.spl-color1 {
  color: #2066c2;
}

/*insurance*/
.filelabel {
  border: 2px dashed grey;
  border-radius: 5px;
  display: block;
  padding: 5px;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
  width: 100%;
}
.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}
.filelabel i,
.filelabel .title {
  color: grey;
  transition: 200ms color;
}
/* .filelabel:hover {
  border: 2px solid #1665c4;
} */
.filelabel:hover i,
.filelabel:hover .title {
  color: #1665c4;
}
#FileInput {
  display: none;
}

p.ant-upload-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 22px;
  margin-top: 10px;
}
p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload p {
  margin: 0;
}
p.ant-upload-drag-icon .anticon {
  color: #40a9ff;
  font-size: 48px;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.spl-color1 {
  color: #2066c2;
}
.table-cell-hover:hover {
  background-color: #f4f4f4;
}
.dot-outline {
  background: #ecf7f0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -2px;
}
.m-t-l-7 {
  margin-top: 8px;
  margin-left: 7px;
}
/* .collapsing{
    height: 0px !important;
    width: 100% !important;
    
  } */
.onclick-svg:hover {
  background-color: #f4f4f4 !important;
}
.m-t-14 {
  margin-top: 14px;
}
#ins-info .ant-space {
  width: 100% !important;
}
.ant-dropdown-menu {
  box-shadow: 0 4px 10px 5px rgb(15 0 0 / 3%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 1px rgb(0 0 0 / 1%) !important;
}
/* .collapsing {
 width: 222px !important;
} */
.ant-dropdown-menu-item:hover {
  border-radius: 4px;
  /* margin-left: 6px;
  margin-right: 6px; */
}
.MuiRadio-colorPrimary.Mui-checked {
  color: #007bff !important;
}
.add-payment .modal-dialog {
  width: 390px;
  height: 301px;
  border: 1px solid #cbd5e0;
  /* box-shadow: 0px 1px 7px rgb(20 46 110); */
  box-shadow: 0px 1px 8px rgb(255 255 255);
  border-radius: 9px;
}
.modal-dialog,
.modal-content {
  border-radius: 8px !important;
}
.create_modaltop .modal-dialog {
  width: 568px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  right: 0 !important;
  min-height: calc(100% - 21rem) !important;
  max-height: 500px !important;
  margin: 10.75rem auto;
  /* box-shadow: 0px 0 8px rgb(20 46 110 / 15%); */
  border-radius: 8px;
}
.create-client .modal-content {
  border: none;
}
.create-client .modal-body {
  padding: 1rem 25px;
}
.create-client .modal-title {
  padding-left: 5px;
}

.add-payment .modal-content {
  /* height: 100%; */
  border: none;
  border-radius: 8px !important;
}
.add-payment .modal-header {
  padding: 0px 20px;
  border-radius: 8px !important;
}
.create-client .modal-header {
  padding: 20px 20px;
}
.create-client .form-label {
  color: #2d3748;
  font-size: 16px;
  margin-top: 7px;
}
.create-client .modal-footer {
  padding-left: 25px;
}
.add-payment .modal-title {
  margin-top: 18px;
  padding-left: 9px;
}
.add-payment .close {
  margin-top: 17px;
}
.add-pay-cancel {
  margin-left: 36%;
  margin-top: 2px;
  margin-bottom: 4%;
  width: 87px;
  font-weight: 500;
}
.add-pay-save {
  margin-left: 28px;
  margin-top: 2px;
  margin-bottom: 4%;
  border-radius: 4px;
  font-weight: 500;
}
.search-con .modal-dialog {
  width: 500px !important;
  height: 780px !important;
}
.search-con .modal-content {
  height: 100%;
  border-radius: 8px !important;
}
.search-contact .save-btn {
  width: 130px;
  height: 37px;
  /* background-color: #2C7BE5; */
  border-radius: 6px;
  font-weight: 700;
}
.search-contact .modal-body {
  padding: 25px 20px 10px 22px;
  background: #f4f4f4;
}
.search-contact .modal-header {
  height: 80px;
}
.search-filter {
  height: 50px;
}
.search-con-patnm {
  font-size: 14px;
  font-weight: 500;
  margin: 13px 0px 12px 21px;
}
.search-con-patemail {
  font-size: 14px;
  font-weight: 500;
  margin: -8px 0px 5px 22px;
}
#searchcontact-radio {
  color: #f4f4f4;
  float: right;
  margin: 17px 47px 0px 0px;
}
.client-form-control .form-control {
  margin-bottom: 8px;
}
.client-button .close-btn {
  margin-top: 14px;
  margin-bottom: 15px;
  margin-right: 12px;
}
.client-button .fill-btn {
  margin-right: 12px;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}
.add-prob-btn {
  width: 112px !important;
  font-weight: 500;
}
.add-med-btn {
  width: 140px !important;
  font-weight: 500;
  margin-top: 1px;
}
.imm-upload {
  width: 175px !important;
  font-weight: 500;
}
.imm-skip {
  width: 85px !important;
  font-weight: 500;
}
.imm-r-save {
  width: 90px !important;
  font-weight: 500;
}
.add-lab-btn {
  width: 130px !important;
  font-weight: 500;
}
.bo-t-n {
  border-top: none !important;
}
.red-btn-doc {
  text-align: left !important;
  margin-left: 0px !important;
}
.add-bord {
  border-radius: 8px;
  padding: 6px;
}
.itm-pad-rad:hover {
  background-color: #f4f4f4;
  border-radius: 6px;
}
.itm-pad-rad-red:hover {
  background-color: #f4f4f4;
  border-radius: 6px;
  color: red;
}
.add-fam .p-l-16 {
  padding-left: 16px !important;
}
.m-t-30 {
  margin-top: 25px;
}
.m-t-19 {
  margin-top: 19px;
}
/*jayabalaji*/
.edit-btm--65 {
  float: right;
  margin-top: -45px;
}
/*jayabalaji*/
.ant-picker:not(
    .apt-bill-datepicks.ant-picker,
    .appoin_report_date_picker.dtpk-pat_ledge .ant-picker-range
  ) {
  height: 34px !important;
  border-radius: 4px !important;
}
.m-t-b-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.m-t-7 {
  margin-top: 7px;
}
.send {
  width: 208px !important;
}
.pat-upload-doc .modal-dialog {
  height: 330px;
}
.pat-upload-doc .modal-body {
  padding: 0px 55px 27px 55px;
}
.m-l-r-15 {
  margin-left: 15px;
  margin-right: 15px;
}
.pat-upload-doc .modal-header .close {
  font-size: 3rem !important;
  background: #f4f9fd !important;
  padding: 0px 5px 10px 4px !important;
  border-radius: 8px !important;
  width: 50px !important;
  height: 50px !important;
  margin-right: 35px;
}
.pat-upload-doc .modal-title {
  font-size: 24px;
  margin-top: 5px;
  margin-left: 33px;
}
.m-t-14 {
  margin-top: 14px;
}
.ant-upload-drag-container input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
  width: 381px;
  height: 235px;
  margin: 2px 6px 15px 113px;
}

.file-choose-btn {
  border: 1px solid #9da5bb;
  color: #000000;
  font-weight: 500;
  width: 125px;
  height: 42px;
  font-size: 16px;
  border-radius: 6px;
  margin: 15px 0px 18px 0px;
}
.file-choose-btn:hover {
  background: #f4f4f4;
  border: 1px solid #9da5bb;
}
.FileUploaddoc:hover .file-choose-btn {
  background: none;
}
.progress_bar {
  height: 37px;
  border-radius: 6px;
  background: #e9f0f9;
  font-size: 16px;
  text-align: left;
  padding-left: 15px;
  font-weight: 500;
  padding-top: 4px;
}
.file-icon-bg {
  font-size: 16px;
  color: #2c7be5;
}
/* #file-icon-bg svg{
  margin-top: -16px;
  vertical-align: text-bottom !important;
} */
.filenm {
  margin-top: -16px;
}
.can-file {
  font-size: 18px;
  color: red;
  opacity: 0.7;
  margin-top: 6px;
  margin-right: 10px;
  float: right;
  cursor: pointer;
}
.m-b-22 {
  margin-bottom: 22px;
}
.problem-pat-add .modal-dialog {
  height: 522px;
}

.patient-doc .prob-head-title {
  margin-top: 7px;
  margin-bottom: 1px;
}
.pat_top_title .prob-top-layer {
  padding-top: 23px;
  padding-bottom: 23px;
}
.pat_table_top .MuiTableContainer-root {
  margin-top: 0px !important;
}
.add_allergy .modal-dialog {
  height: 255px;
}
/* .add_medcation .modal-dialog {
  height: 640px;
} */
.lab_order .modal-dialog {
  height: 425px;
}
/* .family_hist .modal-dialog {
  height: 573px;
} */
/* .ant-tabs-content-holder {
    margin-top: 20px;
  } */

.patientflag-title-box {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
/*jayabalaji*/
.app-patientflag-title {
  margin-top: 5px;
  margin-left: 3px;
  height: 40px;
  font-family: Work Sans, sans-serif;
  font-size: 28px;
  line-height: 40px;
  color: #2e2c34;
}
/*jayabalaji*/
.head-for-patientflag {
  color: #298f38;
  margin-top: -15px;
  width: 263px;
  height: 40px;
  font-size: 20px;
  line-height: 24px;
}
.table-for-patientflag {
  color: #2e2c34;
  margin-top: 0px;
}
.popup-for-patientcontol {
  margin-top: -30px;
  width: 50%;
  margin-left: 93px;
}

.popup-for-patientflag-content {
  margin-left: 130px;
  margin-top: -28px;
}
.popup-for-patientflag-name {
  margin-top: 20px;
}
.title-popup-template {
  margin-left: 2px;
}
.modal-footer {
  margin-left: 0px;
}
.add_observation .modal-dialog {
  height: 315px;
}
/*jayabalaji*/
.t_head_library {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  color: #2e2c34;
  line-height: 25px;
  margin-left: 25px;
  margin-top: 18px;
  margin-bottom: 18px;
}
.t_head_library_1 {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  color: #2c7be5;
  line-height: 25px;
  margin-left: 25px;
  margin-top: 18px;
  margin-bottom: 18px;
}
/*jayabalaji*/
.Rev_count {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.lib_prev {
  background-color: #2c7be5;
  height: 32px !important;
  font-weight: 400 !important;
  border-radius: 4px;
}
.lib_table {
  margin-top: 12px !important;
}
/*jayabalaji*/
.bg-back_ {
  margin-top: 12px;
  background-color: #fcfcfc;
  padding-top: 12px;
  padding-bottom: 12px;
}
/*jayabalaji*/
/*jayabalaji*/
.lib_from_ .prob-top-layer {
  padding-bottom: 20px !important;
  padding-left: 25px;
}
/*jayabalaji*/
.ins-top-btn .ant-input-group-addon {
  display: none !important;
}
.ant-input-group-wrapper {
  width: 240px I !important;
}
.first_search_lib {
  border-radius: 4px;
}
.first_search_lib .ant-input-group input {
  border-radius: 6px !important;
}
.second_search_lib .ant-input-group input {
  border-radius: 6px !important;
}
/*jayabalaji*/
.lib_search_ span {
  width: 240px;
}
/*jayabalaji*/
/*jayabalaji*/
.lib_search_ {
  text-align: left;
  margin-left: 20px !important;
}
/*jayabalaji*/
.lib_th .schedule-head-row th {
  padding-left: 25px;
}
.lib_td td {
  padding-left: 25px;
}
.lib_search_2 span {
  width: 200px;
}
/*jayabalaji*/
.b_search {
  width: 100%;
  justify-content: end;
  float: right;
  display: flex;
  margin-right: 25px;
}
/*jayabalaji*/
.limit_file {
  color: #636d73;
  float: right;
  font-size: 14px;
  font-weight: 500;
}

.butt_td {
  margin-right: 35px !important;
}
/*jayabalaji*/
.limit_file_modal {
  width: 70%;
  justify-content: end;
  float: right;
  display: flex;
  margin-left: 34px;
}
/*jayabalaji*/
/*jayabalaji*/
.filter_result_text {
  color: #2c7be5;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin-left: 9px;
}
/*jayabalaji*/
/*jayabalaji*/
.edu_filter_right {
  cursor: pointer;
  float: right;
  width: 75%;
  display: flex;
  justify-content: end;
}
/*jayabalaji*/
.title_d_n_t {
  margin-right: 12px;
  color: #718096;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin-top: 12px;
}
.check_ .ant-checkbox-wrapper {
  margin-top: 12px !important;
}
/*jayabalaji*/
.all_check_box_ {
  margin-left: 10px;
  width: 36%;
  text-align: left;
}
/*jayabalaji*/
.check_label_ {
  color: #2d3748;
  font-size: 14px;
  line-height: 17px;
}
.avatar_name_ {
  margin-left: 15px;
  /* margin-top: 15px; */
}
.btn-dwn_btn {
  color: #2c7be5;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07), 0px 0.5px 2px rgba(0, 0, 0, 0.07);
}
.btn-dwn_btn svg {
  font-size: 25px;
}
.btn-public_btn {
  background-color: #57bf7f;
  color: #ffffff;
  border-radius: 4px;
}
.in_f_row .form-group {
  display: flex;
}
.text_label {
  margin-right: 94px;
  color: #718096;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}
.in_f_row .form-group .form-control {
  margin-right: 50px;
}
.text_label_1 {
  width: 125px;
  margin-right: 32px;
  color: #718096;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}
.text_label_2 {
  margin-right: 79px;
  color: #718096;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}
.text_label_3 {
  margin-right: 51px;
  color: #718096;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}
.text_label_4 {
  margin-right: 94px;
  color: #718096;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}
.pdf_icon {
  color: red;
  font-size: 30px;
  margin-right: 22px;
}
.image_icons {
  color: #636d73;
  font-size: 30px;
  margin-right: 22px;
}
.edu_title {
  color: #007bff;
  cursor: pointer;
  margin-left: 12px;
  font-family: Inter;
  color: #2e2c34;
}
.image_pdf_view .modal-dialog {
  max-width: 900px;
  width: 900px;
  height: 700px;
  margin-left: auto;
  float: none;
  margin-right: auto;
}
iframe {
  width: 100%;
  height: 100%;
}
.image_pdf_view .modal-content {
  height: 100%;
}

iframe body img {
  width: 100% !important;
  height: 100% !important;
}
.practice {
  height: 98% !important;
  padding-top: 8px;
}
#document:not(.ad-tk-item-btn) {
  width: 100%;
  height: 100%;
}
.s-e-t-1 {
  margin-top: 10px;
  font-size: 28px !important;
  margin-left: 20px;
}
.s-e-t-head {
  display: flex;
}
.set-2-box {
  background: #f5f5f5;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 200px;
  height: 32px;
  margin-top: 20px;
  margin-left: 63px;
}
.set-3-box {
  margin-top: -40px;
  margin-left: -50px;
}
.s-e-t-2 {
  margin-top: 4px !important;
}
.add-s-e-t {
  background-color: #2c7be5;
}
.for-search-pharmacy {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #2c7be5;
  margin-left: 15px;
  margin-top: 20px;
}
.for-show-favourite {
  width: 118px;
  height: 32px;

  background: #f4f4f4;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
  margin-top: 17px;
}
.show-favourite-t {
  margin-top: 4px;
  color: #2c7be5;
  font-weight: 400;
}
.for-phar {
  margin-left: 0px;
  height: 64px;

  background: #fafafa;
}
.add-tit {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.add-tit-1 {
  font-size: 14px;
  line-height: 17px;
  display: flex;

  margin-left: 25px;
  align-items: center;
}
.for-add {
  margin-left: 10px;
}
.s-r-t-rect {
  width: 538px;
  height: 495px;
  background: #fafafa;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  margin-left: 20px;
  margin-top: 15px;
}
.s-r-t-rect-clone {
  width: 538px;
  height: 495px;
  background: #fafafa;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  margin-top: 15px;
}
.for-title-inside-rect {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-left: 24px;
  margin-top: 20px;
}
.for-close-l {
  margin-top: 20px;
  margin-left: 26px;
}
.for-type {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin-left: 24px;
  margin-top: 8px;
}
.for-medication {
  width: 75px;
  height: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  margin-left: 24px;
}
.for-sig {
  width: 75px;
  height: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  margin-top: 20px;
}
.for-sig-1 {
  width: 115px;
  height: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  /* margin-left: -9px; */
  margin-top: 20px;
}
.for-sig-2 {
  height: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  margin-left: 80px;
  margin-top: 20px;
}
.for-sig-3 {
  height: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  margin-left: 32px;
  margin-top: 20px;
}

.for-sig-4 {
  height: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
  margin-left: 82px;
  margin-top: 20px;
}
.for-check-1-l {
  margin-top: 15px;
  margin-left: 10px;
}
.for-after-check {
  font-size: 10px !important;
  line-height: 12px;
  color: #718096;
  margin-left: 24px;
}
.for-after-check-l {
  margin-left: 24px;
}
.for-multi-b {
  margin-top: 15px;
  margin-left: 43.2%;
}
.for-after-multi-b {
  margin-top: 15px;
  width: 105px;
}
.for-checobox-1 {
  margin-left: 10px;
}
.for-presc-summary {
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #2e2c34;
  margin-left: 24px;
  margin-top: 12px;
  margin-bottom: -5px;
}
.for-presc-box-1 {
  width: 530px;
  height: 199px;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 20px;
}
.for-presc-box-2 {
  width: 530px;
  height: 199px;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 20px;
}
.for-b-1-h {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-left: 15px;
  margin-top: 15px;
}
.for-b-1-h-d {
  width: 526px;
  height: 55px;
  background: #fafafa;
  margin-left: 6px;
}
.box-1-h-1 {
  margin-left: 20px;
  margin-top: 10px;
}
.box-1-h-2 {
  margin-left: 20px;
  margin-top: 5px;
}
.box-1-h-3 {
  margin-left: 20px;
  margin-top: 5px;
}
.box-1-h-4 {
  margin-left: 20px;
  margin-top: 5px;
}
.for-drug-all {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #2c7be5;
  margin-left: 24px;
  margin-top: 18px;
}
.for-t-t-1 {
  width: 24px;
  height: 24px;
  background: rgba(182, 180, 186, 0.1);
  border-radius: 4px;
  text-align: center;
}
.for-t-t-2 {
  width: 72px;
  height: 24px;
  left: 345px;
  background: rgba(255, 160, 67, 0.1);
  border-radius: 4px;
  text-align: center;
}
.for-med-list {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #2c7be5;
  margin-left: 24px;
  margin-top: 18px;
}
.for-med-list-b-1 {
  width: 20px;
  height: 20px;
  left: 273px;
  top: calc(50% - 20px / 2 + 297.5px);
  background: rgba(0, 165, 255, 0.1);
  border-radius: 4px;
  margin-left: 24px;
  margin-top: 5px;
}
.for-med-list-b-1-b {
  width: 1078px;
  height: 28px;
  margin-left: 0px;
  background: #fafafa;
  margin-top: 1px;
}
.after-table-b-1 {
  width: 1080px;
  height: 108px;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 20px;
  margin-top: 10px;
}
.for-box-2-1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-left: 24px;
  margin-top: 10px;
}
.for-box-2-2 {
  margin-left: 643px;
  margin-top: 10px;
  width: 8%;
  border-left: 1px solid #cbd5c0;
}
.for-box-2-3 {
  margin-top: 10px;
  width: 8%;
  border-left: 1px solid #cbd5c0;
}
.for-box-2-4 {
  margin-top: 10px;
  width: 8%;
  border-left: 1px solid #cbd5c0;
}
.for-box-2-5 {
  margin-top: 10px;
  width: 8%;
  border-left: 1px solid #cbd5c0;
}

.for-box-3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-left: 24px;
}
.for-med-list-b-1-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 5px;
  margin-left: 10px;
}
.after-table-b-2 {
  width: 1080px;
  height: 108px;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 20px;
  margin-top: 20px;
}
.full-manage-macro {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.man-flex-center {
  display: flex;
  align-items: center;
}
.mn-brdr {
  border-radius: 4px;
  border: 1px solid #cbd5e0;
}
.man-mac-title {
  width: 340px;
}
.man-full-right-block {
  padding: 16px 0px 0px 16px;
}
.man-title-text {
  font-size: 28px;
  color: #2e2c34;
  line-height: 38px;
}
.man-mac-title {
  height: 40px;
  margin-bottom: 8px;
}
/* dhilip */
.man-second-text {
  height: 17px;
  line-height: 17px;
  color: #718096;
  margin-bottom: 16px;
}
/* dhilip */
/* dhilip */
.man-top-note {
  background-color: rgba(44, 123, 229, 0.2);
  width: 33%;
  border-radius: 4px;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 33px;
  letter-spacing: 0.0025em;
  color: #2c7be5;
  font-size: 14px;
  justify-content: space-between;
  padding: 8px !important;
}
/* dhilip */
/* dhilip */
.man-tab-container {
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  height: 100%;
}
/* dhilip */
.man-left-tab {
  overflow: auto;
  width: 20%;
  font-size: 0;
  margin-right: 15px;
  border-radius: 4px;
  border: 1px solid #cbd5e0;
}
/*jayabalaji*/
/*jayabalaji*/
.man-tab-added {
  background-color: white;
  color: #363636;
}
/*jayabalaji*/
/* update by dhilip start  */
.man-tab-added,
.man-tab-new {
  font-size: 16px;
  cursor: pointer;
  /* border-bottom: 0px solid #cbd5e0; */
  padding: 8px 0px 8px 15px;
}
/* update by dhilip end  */
.man-right-tab {
  width: 52%;
  cursor: pointer;
  background-color: #fafafa;
}
/* dhilip update start */
.man-right-title {
  /* width: 100%; */
  /* margin: 16px; */
  padding-top: 8px;
  font-weight: 500;
  font-size: 16px;
  background: #173e67;
  padding-bottom: 0px;
  color: white;
  text-align: center !important;
  border-radius: 8px;
}
/* dhilip update end */
.right-top-line {
  background-color: #cbd5e0;
  height: 1px;
  margin: 0;
}
.man-scnd-layer {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 4px 0px 12px 16px;
}
.man-scnd-layer > div {
  margin-top: 12px;
  color: #2d3748;
}
.man-select1,
.man-select2,
.man-select3,
.man-select4 {
  /* margin-right: 12px; */
  height: 32px;
  /* padding-left: 14px; */
  padding-right: 12px;
}
.man-select-box.form-control {
  background-color: #fafafa;
  cursor: pointer;
}
.man-btn1,
.man-btn2,
.man-btn3 {
  padding: 5px 5px;
  height: 32px;
  margin-right: 0px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  cursor: pointer;
  background-color: #fafafa;
}
.dlt-txt {
  margin-left: 8px;
  cursor: pointer;
  color: #ef5f5f;
}
.man-btn-group .btn2 {
  padding: 0px 8px 0px 10px;
  margin: 0px 10px 0px 8px;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
}
.man-select1 {
  width: 210px;
}
.man-select2 {
  width: 200px;
}
.man-select3 {
  width: 170px;
}
.man-select4 {
  width: 200px;
}
.man-select-box.form-control {
  height: 32px;
  padding-left: 8px;
}
.man-third-layer {
  padding: 0px 0px 0px 0px;
  background-color: white;
}
.third-label-lab,
.thrd-txt-lab {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
}
.third-layer-txt {
  font-size: 10px;
  margin: 10px 0px 0px 0px;
  color: #718096;
}
.thrd-lbl-inp {
  width: 47%;
  margin-right: 16px;
}
.thrd-txt-lab {
  margin-top: 16px;
}
.thrd-txt-inp {
  margin-bottom: 24px;
}
/* dhilip */
.thrd-txt-inp textarea {
  padding: 4px 8px;
  width: 100%;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
  color: #495057;
}
/* dhilip */
.thrd-btn .btn-primary {
  border-radius: 4px;
}
.man-legend-text {
  background-color: rgba(44, 123, 229, 0.2);
  padding: 12px 30px 30px 20px;
  color: #2c7be5;
  width: 16%;
  margin-left: 14px;
}
.man-text-area {
  margin: 20px 0px;
}
.man-legend-head {
  font-weight: 800;
}
.man-text-label {
  font-weight: 600;
}
.use_form_btn {
  margin-left: auto;
  margin-right: 18px;
  width: max-content;
}
.form_preview .modal-dialog {
  max-width: 624px !important;
  height: 768px;
}
.form_preview_title {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #2c7be5;
  width: 100%;
  margin-top: 0px !important;
}

.form_preview_title_cont {
  width: 100%;
  display: flex;
}
.form_title_div {
  margin-top: 5px;
}
.form_preview .modal-header .close {
  margin-top: 5px;
}

.form_preview .modal-body {
  padding: 19px 20px 0px 20px;
  height: 640px;
  overflow-x: hidden;
}
.form_preview .modal-dialog {
  height: 766px;
}
.form_modal_body_form_spl {
  width: 100%;
  border-radius: 4px;
  background: #eaf2fc;
  padding: 16px 8px;
  color: #2c7be5;
  /* opacity: 0.1; */
}
.form_spl_title {
  color: #000000;
  font-size: 16px;
  line-height: 16.5px;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
}
.form_pub_det {
  /* color: #000000; */
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 6px;
}
.table_form {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-collapse: collapse;
  border-radius: 30px;
  /* border-style: hidden; */
}
.feed_count {
  background: rgba(182, 180, 186, 0.1);
  border-radius: 4px;
  width: 29px;
  height: 24px;
  text-align: center;
  margin-top: 15px;
  margin-left: 10px;
}
.feedback_sec {
  width: 100%;
  display: flex;
}
.form_footers {
  padding: 3px 20px !important;
}

.cell_pad {
  margin: 5px 10px 11px 18px;
}
.form_pub_det_date {
  margin-left: 8px;
}
.m-l-11_ {
  margin-left: 11px;
}
.m-l-9 {
  margin-left: 9px;
}

.table_form .form-control:disabled {
  background-color: #ffffff;
  color: #718096;
}
.edu_upload_title {
  font-size: 15px;
  font-weight: 500;
  line-height: 17px;
  color: #263446;
  margin-top: 12px !important;
  margin-bottom: 10px !important;
}
.edu_upload_hint {
  font-size: 12px;
  line-height: 15px;
  color: #8a969f;
  margin: 5px;
  font-weight: 400;
  margin-bottom: -2px !important;
}
.edu_upload_ {
  width: 285px;
  background: #fafafa;
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  margin-left: -34px;
}
.edu-choose-btn {
  border: 1px solid #9da5bb;
  background-color: #ffffff;
  color: #000000;
  font-weight: 500;
  width: 125px;
  height: 42px;
  font-size: 16px;
  border-radius: 6px;
  margin: 15px 0px 18px 0px;
  cursor: pointer;
}
.edu-upload-drag-container input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
  width: 280px;
  height: 117px;
  margin: 2px 0px 10px 131px;
}
.FileUploadedu:hover > .edu-choose-btn {
  background: #fafafa;
  border: 1px solid #cbd5e0;
}
.macro-btn-item {
  color: white;
  margin: 10px 15px;
}
.reminder_check {
  display: flex;
  margin-left: -11px;
}
.email_remin_check {
  margin-left: -11px;
  margin-bottom: 12px;
  /* margin-top: -9px; */
}
.text_remin_check {
  margin-left: 10px;
}
.patient_edu_filebar {
  font-size: 14px;
  color: #2c7be5;
}
.Daw_check {
  margin-left: 0px !important;
}
.Daw_group .ant-checkbox-wrapper {
  margin-top: 5px !important;
}
.Daw_group {
  margin-bottom: 5px !important;
}
.result {
  background-color: #ffffff !important;
}
.err_mess_border {
  border: 1px solid red !important;
  border-radius: 4px;
}
/*jayabalaji*/
#cvx_Code {
  width: 133px !important;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*jayabalaji*/
.tk-sel-box.form-control {
  padding: 0px;
  color: #40cfe9;
  border: none;
  height: 32px;
  margin-bottom: 2px;
  font-size: 14px;
}
.tk-sel-box.form-control:hover {
  border: none;
  box-shadow: none;
}
.tk-sel-box.form-control:focus {
  border: none;
  box-shadow: none;
}
.pro-rep-second {
  width: 100%;
  background-color: #fcfcfc;
}
.pro-filter-container {
  display: flex;
  align-items: center;
  background: #f7f7f7;
}
.procedure-report-modal .modal-content {
  width: 100% !important;
}
.procedure-report-modal .modal-dialog {
  min-width: 66.75% !important;
  max-width: 600px;
}
.pro-ord-top,
.pro-det-second {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.pro-det-second {
  width: 100%;
  padding: 0px 20px;
}
.br-side {
  padding: 12px 0px 13px 14px;
}
.ord-left-box,
.ord-right-box {
  border: 1px solid #ebeaed;
  border-radius: 4px;
  margin-right: 32px;
}
.pro-code-lab,
.pro-code-res {
  text-align: left;
  margin-right: 10px;
}
.pro-code-lab {
  width: 150px;
}
.br-bot {
  border-bottom: 1px solid #ebeaed;
  padding: 12px 0px 13px 14px;
  display: flex;
  align-items: center;
}
.ord-right-box {
  margin-right: 50px;
}
.pro-ord-title {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0px 20px 20px;
}
.btn-box {
  margin-top: 20px;
}
.procedure-inp-box .ui.icon.input {
  width: 100%;
  height: 32px;
}
.procedure-add-btn {
  margin: 0px 10px;
  width: 100%;
  height: 32px;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding: 8px;
}
.pat-dash_flag {
  border-radius: 4px;
  margin-right: 8px;
  display: inline-block;
  padding: 4px 12px 4px 10px;
  margin-bottom: 17px;
}
.no-res-text {
  font-size: 20px;
  font-weight: 700;
}
.no-res-board {
  width: 93%;
  background-color: #fafafa;
  margin-bottom: 40px;
  margin-top: 40px;
  margin-left: 40px;
  padding: 40px;
}
.no-res-text1 {
  font-size: 16px;
  font-weight: 500;
}
/* saravanan 24.3.22 7:00  start*/
.no-res-board1 {
  background-color: #fafafa;
  margin: 20px 20px 60px 20px;
  padding: 40px;
}
/* saravanan 24.3.22 7:00  end*/
.pat-crt-appt .modal-dialog {
  width: 375px !important;
}

.inv-stat {
  width: 150px;
  border: 2px solid green;
  border-radius: 4px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 10px;
  color: green;
  padding: 20px 0px;
}
.pr-or-dt.form-control {
  height: 32px;
}
.up-doc-modal .modal-dialog {
  max-width: 800px;
}
.fuap-mod .modal-dialog {
  width: 580px;
}
.diagnosis-modal .modal-dialog {
  width: 500px;
}
.elec-rep-link {
  cursor: pointer;
}
.elec-rep-link:hover {
  color: blue;
  text-decoration: underline;
}
.procedure-inp-box .form-control {
  margin-left: -10 !important;
}

.box .row .col-2 .form-group .onclick-svg {
  padding-top: 10px !important;
}

.appt-block-main .pat_top_title .form-row {
  padding-top: 5px;
}

.pat-block-main .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  /* padding-bottom: 11px !important; */
  padding-top: 16px !important;
  margin-bottom: 0px;
}
.pat-block-main .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  /* margin-bottom: -10px !important; */
}
.pat-block-main .ant-tabs .ant-tabs-nav {
  padding-top: 3px;
}
.pat_add_mob_dd {
  border: 1px solid #cbd5e0;
  background-color: white;
  color: #2066c2;
}

.cv_Code .input {
  height: 34px !important;
  width: 100%;
}
.ant-tabs-nav .uplo {
  padding-top: 2px;
}

.uploaddoc .ant-tabs-nav {
  padding-top: 2px !important;
}

.reminder_check .padding {
  margin-top: 4px !important;
  margin-right: 10px !important;
}
.row1 .row {
  flex-wrap: nowrap !important;
  margin-right: -15px;
  margin-left: -15px;
}
.lab-items-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.error-tbl-bottom {
  margin: 20px;
  text-align: left;
}
.err-tbl-top {
  margin: 0px 20px 20px 20px;
  text-align: left;
}
.tbl-txt-1,
.tbl-txt-2,
.err-tbl-top-txt {
  color: green;
  margin-bottom: 10px;
  font-weight: 600;
}
.lb-chk-res-btn.ant-checkbox-wrapper {
  margin-top: 0px !important;
}

/* dhilip update :start */
.macro-top-text {
  border-bottom: 1px solid #cbd5e0;
  padding: 16px;
  font-size: 16px;
  font-weight: 600;
}
.macro-add-btn {
  cursor: pointer;
  height: 23px;
  width: 21px;
  border-radius: 50%;
  background-color: rgb(11, 95, 255);
  display: inline;
  color: white;
  float: right;
}

.new-man-con {
  background: #173e67;
  border-radius: 8px;
}

.man-comtainer {
  background: #173e67;
  color: white;
  text-align: center !important;
  border-radius: 8px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.man-btn1-ex {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.man-btn2-ex {
  border-radius: 0px;
  border-left: 0px;
}
/* dhilip */
.man-btn3-ex {
  border-radius: 0px;
  border-left: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 12px;
}
/* dhilip */
.thrd-labl-con {
  padding: 16px;
}

.macn-btn {
  background: #173e67;
  padding-top: 16px;
  padding-bottom: 6px;
}

.man-del {
  background-color: #ff767626;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
}
/* dhilip update :end */
/* .patient_education_card_1{
  height:114px  !important;
  
} */
.patient_education_card_1 .card {
  margin-left: 2% !important;
  margin-right: 2% !important;
  background: #f5f8ff;
  margin-bottom: 5% !important;
}
.patient_education_card_1 .card-body .list-group-item .list_icon_2 {
  font-size: 20px !important;
  border-color: #20c9ac;
  border-radius: 50px;
}
.patient_edu_ {
  height: 26px;
  width: 27px;
  border-radius: 23px;
  background-color: #e1e8ff;
  cursor: pointer;
}
.patient_edu .svg {
  color: yellow;
  overflow: hidden;
}

#pat_edu_id > .svg:not(:root) {
  color: yellow;
  overflow: hidden;
  cursor: pointer;
}
.patient_education_card_1 .card-body .list-group-item {
  border: none !important;
  background: #f5f8ff;
}
.pat_name_field {
  font-size: 20px !important;
  color: #2c7be5 !important;
}
.patient_education_card_2 .card {
  margin-left: 2% !important;
  margin-right: 2% !important;
  background: #fff;
}
.patient_education_card_2 .card-header {
  background: #ffff;
}
.patient_education_card_2 .card-body .list-group-item .list_icon_2 {
  font-size: 20px !important;
  border-color: #20c9ac;
  border-radius: 50px;
}
.pat_name_field_1 {
  font-size: 20px !important;
  color: #718096;
}
.patient_education_card_2 .card-body .list-group-item {
  border: none !important;
  background: #f5f8ff;
}
#pat_edu_id > .svg:not(:root) {
  overflow: hidden;
  color: yellow !important;
}
.patient_education_card_1 .card-body .list-group-item .patient_edu {
  font-size: 30px !important;
  color: #2c7be5 !important;
}
.form_card .card {
  margin-left: 2% !important;
  margin-right: 2% !important;
}
.form_card_title {
  float: left;
  color: #2c7be5 !important;
  font-weight: 500;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.form_card .card-header {
  background-color: #ffff !important;
}
.patient_flag_main_card .card {
  background: #ffffff;
  border: 1px solid #ebeaed !important;
  border-radius: 8px;
  border: none;
  margin-bottom: 6% !important;
  width: 108% !important;
}
.patient_flag_main_card {
  display: grid !important;
  margin-top: 25px;
  margin-left: 15px;
  margin-right: 40px;
  margin-bottom: 25px;
}
.patient_flag_main_card .card-body {
  overflow-x: hidden;
}
.patient_flag_main_card .card-header {
  background-color: #ffffff;
  text-align: left;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding-right: 80px;
}
.patient_flag_main_card .patient_flag_card_1 {
  width: 99% !important;
}
.patient_flag_main_card .card-header {
  border-bottom: none;
}
#hoover_effect:hover > .patient_flag_main_card .card {
  cursor: pointer;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%) !important;
}
.card_value_1 {
  display: grid !important;
}
.patient_flag_card_1 {
  margin-right: 2.1%;
}
.patient_flag_card_1_ {
  height: 25px;
  border-radius: 4px;
}
.patient_flag_card_1 .card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.patient_flag_card_1 .card-body {
  text-align: left;
  padding: 0px 21px 0px 0px;
}
.patient_flag_card_1 .card-body .list-group-item {
  padding-left: 1.75rem;
  border: none !important;
  padding-bottom: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.patient_flag_card_1 .card-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #ffff !important;
}
/* .patient_flag_card_1 .card-body .list-group {
  margin-bottom: 25px;
} */
#hoover_effect:hover > #card_footer_flag_apt {
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%) !important;
  cursor: pointer;
  display: block !important;
}
#hoover_effect:hover > .patient_flag_main_card .card {
  cursor: pointer;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%) !important;
}
.ins_title_pft {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.app_pro_text_color_1 {
  margin-left: 10px;
  color: black;
}
.app-color-for-patient_flag {
  margin-right: -54px !important;
  float: right;
  border-radius: 4px;
  width: 60px;
  height: 24px;
  margin-top: 10px;
}
#card_footer_flag_apt {
  border-radius: 0px 0px 6px 6px !important;
  cursor: pointer;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1) !important;
  display: none !important;
}
#card_footer_ {
  display: flex !important;
}
.for-patient-flag {
  width: 64px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 7%), 0px 0.5px 2px rgb(0 0 0 / 7%);
  border-radius: 4px;
  text-align: center;
  padding-top: 6px;
}

.patient_flag_table_value_card .card {
  margin-top: 1% !important;
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-bottom: 1% !important;
}
.pat_fla_head {
  color: #2c7be5;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
}

.patient_flag_table_value_card .card-header {
  color: #2c7be5;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  padding-top: 2%;
  border-bottom: none !important;
}
.app_pat_btn_format {
  margin-top: 2px;
}
.appoint_paat_frmt_table_btn1 {
  border: 1px solid #cbd5e0;
  color: #ffffff;
  width: 35px !important;
  height: 36px !important;
}
.appoint_paat_frmt_table_btn2 {
  border: 1px solid #cbd5e0;
  color: #ffffff;
  width: 35px !important;
  height: 36px !important;
}
.manage_macro_card .card {
  padding-left: 3% !important;
  background-color: #173e67;
  margin-top: 1% !important;
  margin-left: -2% !important;
  margin-right: -2% !important;
  border-radius: 8px;
}
.manage_macro_card {
  width: 53% !important;
}
.man-right-tab-bill {
  width: 99%;
  cursor: pointer;
  background-color: #fafafa;
  margin-left: -1%;
  margin-top: 1%;
  margin-bottom: 9%;
  margin-right: 1%;
}
.manage_micro_head {
  color: #ffff;
  font-size: 18px;
  font-weight: 500;
  border-bottom: none !important;
}
.manage_macro_card .card-header {
  box-shadow: none !important;
  border-bottom: none !important;
  border-radius: none !important;
  padding: none !important;
}
.manage_macro_card .card-footer {
  border-top: none !important;
  box-shadow: none !important;
  border-radius: none !important;
}
.manage_submit_btn {
  color: green !important;
  margin-top: -7% !important;
}
.save_macro {
  background-color: #00bf9e;
}
.man-tab-new > .svg:not(:root) {
  font-size: 14px !important;
  float: right !important;
  color: yellow !important;
  margin-right: 6px;
  height: 22px;
  width: 88px;
}
/* jeyabalaji */
.pat-flag-type-list-box {
  /* margin: 20px !important; */
  margin-left: 20px !important;
  margin-bottom: 20px !important;
  margin-right: 20px !important;
  /* padding-top: 20px !important; */
  /* padding-bottom: 20px !important; */
  border: 1px solid #e2e8ed;
  border-radius: 4px !important;
}
/* jeyabalaji */
.pat_fla_head {
  text-align: left;
  margin-left: 20px !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #2c7be5;
}

.pat-flag-sty-for-upd-date {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #2c7be5;
}
.pat-flag-title-box {
  padding-left: 19px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  align-items: center !important;
  justify-content: space-between;
}
.for-pat-flag-title-add-btn {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
#card_footer_cont1 {
  cursor: pointer;
  display: block !important;
  z-index: 1000;
}
.click-toggle-content path {
  color: #2c7be5;
}
.click-toggle-content {
  background-color: #e2e6ea !important;
}
.for-pat-flag-title-res {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  margin-left: 0px !important;
}
.for-patientflag-edit:hover {
  cursor: pointer;
}
.for-patientflag-delete:hover {
  cursor: pointer;
}
.ins-title-pat-flag-sty {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 6px;
}
.app-patientflag-title-1 {
  margin-top: 0px !important;
  margin-left: 0px !important;
  text-align: left;
  min-width: 350px;
}
.man-tab-new {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #cbd5e0;
  padding: 8px 0px 8px 15px;
}
/*--------------------cheran-----------------------------*/

.crating {
  width: 150px;
}
.Cf {
  /* font-size: 18px; */
  padding: 8px 20px 20px;
  /* font-weight: 500; */
  text-align: left;
  /* font:inter; */
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c7be5;
}
.for-form-lib-head-for-table {
  margin-top: 6px !important;
}
#ib {
  padding-bottom: 0px;
}
#first_search_lib:hover {
  margin-left: -25px;
  border: 1px solid #d9d9d9 !important;
}
#first_search_lib {
  margin-left: -25px;
}
/* jeyabalaji */
.borderc {
  border-top: 1px solid #e2e8ed;
}
/* jeyabalaji */
.form-lib-srch-2 .ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none;
}
.for-form-lib-head1 .for-form-lib-head {
  padding-bottom: 18px !important;
  padding-left: 20px !important;
}
/*--------------------cheran----------------------*/
.for-pat-edu-box-tit {
  text-align: left;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2e2c34;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
}
.for-pat-edu-box-bor {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
/* jeyabalaji */
.filter-result-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-top: 23px !important;
  padding-bottom: 3px !important;
  margin-left: 15px !important;
}
.pat-edu-tab-srch-box .ant-input {
  height: 32px !important;
}
.pat-edu-tab-srch-box ::placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  /* margin-top: 10px !important; */
  font-size: 16px !important;
  line-height: 19px !important;
  display: flex;
  align-items: center;
  color: #242222 !important;
}
.table-for-patientflag tr:last-child {
  border-bottom: none !important;
}
.for-form-lib-head-for-table tr:last-child {
  border-bottom: none !important;
}
.Tab_content_Table tr:last-child {
  border-bottom: none !important;
}
.Tab_Filter_Background ::placeholder {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px !important;
  line-height: 19px !important;
  display: flex;
  align-items: center;
  color: #242222 !important;
}
/* jeyabalaji */
.table_content {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #2e2c34 !important;
}
/* jeyabalaji */
.pat_flag_tab_bor {
  border-radius: 6px !important;
}
#btn_selected:visited {
  background: #3182ce !important;
}
.for-pat-flag-title-add-btn Button:focus {
  background: #3182ce !important;
}

.Border_none tr:last-child {
  border-bottom: none !important;
}

.img_top {
  padding-top: 14px;
  padding-bottom: 14px;
}
.bot_border {
  border-bottom: none !important;
}
.bot {
  padding-bottom: 0 !important;
}

.elect_rep {
  font-family: "WORK SANS", sans-serif !important;
  border: 1px solid black !important;
}
.bor {
  border: 1px solid black !important;
}
.ins-p-s .elect_cal {
  width: 128px !important;
  height: 28px !important;
  background: #ffffff !important;
  /* border: 1px solid #dbdbdb !important; */
  /* box-sizing: border-box !important; */
  border-radius: 4px !important;
}
.elect_cal1 {
  width: 180px;
  height: 34px;
  background: #ffffff !important;
  border: 1px solid #dbdbdb !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  font-family: Inter, sans-serif;
}
.elect_rep_title {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.elect_rep_fil_row {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 20px !important;
  background-color: #f7f7f7 !important;
}

.elect_sec_title {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px;
  color: #2c7be5 !important;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  justify-content: space-between;
  display: flex;
  padding-right: 20px;
  align-items: center;
  padding-left: 20px;
}
.elect_table_bor {
  border: none !important;
}
.elect_data_clr {
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */
  color: #2c7be5 !important;
}

input::placeholder {
  /* color: #e01a9e !important; */
  color: #242222 !important;
  font-family: Inter, sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
}
/* .ins::-webkit-input-placeholder{
  color: #242222;
} */

.fami_title {
  font-family: Work Sans, sans-serif !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 28px !important;
  line-height: 33px !important;
  color: #2e2c34 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.fami_button {
  height: 34px !important;
  background: #2c7be5 !important;
  border-radius: 4px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
}
.fami_sec_title {
  /* border: 1px solid black; */
  padding-top: 0 !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  text-align: left;
  font-family: Inter, sans-serif;
  padding-left: 20px;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #2c7be5 !important;
}
.top {
  margin-top: 0px !important;
}
/* .ins-p-s .ant-input .search_height {
  height: 32px !important;
} */
.search_height {
  height: 34px !important;
}
.pat_education_alt {
  margin-top: 0px !important;
}
.pat_flag_tit_alt {
  /* border: 1px solid; */
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #2c7be5;
  float: left !important;
  font-family: Inter, sans-serif;
}

/* -----------------------------------------------Vanitha----------------------------------------- */

/* update by Christina 14/02/2022 starts here */
/* Medication table starts here*/
.medication-title {
  display: flex;
}

.medication-small-heading {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2c7be5;
  text-align: left;
  margin-top: -4px;
  margin-left: -1px;
}
.medication-border {
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 6px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 11px;
}
.medication-table-outer {
  margin: 15px -20px -20px -20px;
}
.Boreder_none tr:last-child {
  border-bottom: none !important;
}
.add-med-btn {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 16px;
  align-items: center !important;
  color: #ffffff;
  order: 0;
  flex-grow: 0;
  background: #2c7be5;
  border-radius: 4px;
  height: 35px !important;
}
.add-med-btn:hover {
  background-color: #0f6ff0;
}
.add-med-btn:visited {
  background-color: #3182ce;
}
.patient-tables-border-top {
  border-top: none !important;
}
/* ====================================================== */
/* .img_emp_data { */
/* border: 1px solid black; */
/* height: 133px;
  width: 133px;
}
.img_top {
  padding-top: 14px;
  padding-bottom: 14px;
}
.bot_border {
  border-bottom: none !important;
} */
/* ====================================================== */
/* Medication table ends here*/

/* update by Christina 14/02/2022 ends here */

/*---------------------cheran 17-2-2022 start----------------------------------------------------------*/
.arrow-adj {
  -webkit-appearance: none;
  background-image: url("../../assets/images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right 8px top 8px;
  line-height: 1;
}
/*--------------------end-------------------------------------------------------------------------------*/
/* jeyabalaji */
/* for explore sample client Start */
.explore_sample_client .modal-content {
  width: 400px !important;
}
.explore_sample_client .modal-body {
  padding: 0px !important;
}

.explore_sample_client .modal-dialog {
  /* float: left;
  margin-top: 50vh !important;
  margin-left: 20px !important; */
  /* float: left; */
  width: 400px !important;
  position: fixed;
  top: auto;
  right: auto !important;
  /* left: auto; */
  bottom: 0;
  margin-left: 30px !important;
}
/* for explore sample client End */

.get-started-appointment-pop .modal-dialog {
  margin-top: 250px;
  margin-right: 20px;
  height: 208px !important;
  width: 415px !important;
}
.get-started-problems-pop .modal-dialog {
  margin-top: 410px;
  float: left;
  margin-left: 25%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-allergy-pop .modal-dialog {
  margin-top: 30%;
  float: left;
  margin-left: 32%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-billinfo-pop .modal-dialog {
  margin-top: 40px;
  float: left;
  margin-left: 46%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-insinfo-pop .modal-dialog {
  margin-top: 15.4%;
  float: left;
  margin-left: 46%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-immuniz-pop .modal-dialog {
  margin-top: 20.4%;
  float: left;
  margin-left: 46%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-famhis-pop .modal-dialog {
  margin-top: 25.4%;
  float: left;
  margin-left: 46%;
  height: 208px !important;
  width: 415px !important;
}
.get-started-medication-pop .modal-dialog {
  margin-top: 30%;
  float: left;
  margin-left: 46%;
  height: 208px !important;
  width: 415px !important;
}

.for-get-st-arrow-prob {
  transform: rotate(45deg);
  margin-top: 5px !important;
  background: white;
  height: 30px !important;
  width: 30px !important;
  margin-left: -38px !important;
  border-radius: 4px !important;
}
.for-get-st-arrow-billinfo {
  transform: rotate(45deg);
  margin-top: 5px !important;
  background: white;
  height: 30px !important;
  width: 30px !important;
  margin-right: -38px !important;
  border-radius: 4px !important;
  box-shadow: 1px -1px #88888863;
}
.get-st-prob-head {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
  margin-left: 2px !important;
  padding-top: 9px !important;
  padding-left: 6px !important;
  z-index: 1000;
}
.get-st-prob-head-2 {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
  /* margin-left: 2px !important; */
  /* padding-top: 9px !important; */
  /* padding-left: 6px !important; */
  z-index: 1000;
}
.get-st-billinfo-head {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
  /* margin-left: -6px !important; */
  /* margin-top: 6px !important; */
  margin-bottom: 6px !important;
  z-index: 1000;
}
/* for explore sample appointment popup End */
/* jeyabalaji */

#click_event {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 20px;
  margin-left: -60px;
  /* background-color: red; */
  z-index: -10;
}
#click_event_vitals {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 15px;
  margin-left: -250px;
  transform: translate(80px, 306px);
}
#click_event_appointment {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 145px;
  margin-left: 325px;
}
#click_event_problems_tab {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 478px;
  margin-left: 155px;
}
#click_event_allergy_tab {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 478px;
  margin-left: 62px;
}

#click_event_billinfo_tab {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 67px;
  margin-left: 20px;
}
#click_event_insinfo_tab {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 88px;
  margin-left: -1px;
}
#click_event_immunizinfo_tab {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 88px;
  margin-left: -1px;
}
#click_event_famhis_tab {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 88px;
  margin-left: -1px;
}
#click_event_medication_tab {
  border: none !important;
  background: none !important;
  position: absolute !important;
  margin-top: 88px;
  margin-left: -1px;
}

.forbillinfo-popup .ui.left.center.popup:before {
  right: -1.4em;
  top: 20%;
}

.ui.left.center.popup:before {
  right: -0.8em;
  top: 17%;
  bottom: auto;
  left: auto;
  margin-top: -0.30714286em;
  box-shadow: 1px -1px 0 0 #bababc;
  height: 24px;
  height: 24px !important;
  width: 24px !important;
}

.ui.top.center.popup:before {
  bottom: -0.8em;
  height: 24px !important;
  width: 24px !important;
}

.ui.bottom.center.popup:before {
  /* background: pink !important; */
  background: #ffffff;
  top: -0.9em !important;
  left: 45% !important;
  box-shadow: none !important;
  border-top-left-radius: 6px !important;
  border-top: 1px solid #d4d4d5;
  border-left: 1px solid #d4d4d5;
  height: 24px !important;
  width: 24px !important;
}

.ui.right.center.popup:before {
  top: 17%;
  left: -0.8em;
  bottom: auto;
  right: auto;
  margin-top: -0.30714286em;
  box-shadow: -1px 1px 0 0 #bababc;
  height: 24px;
  width: 24px;
}
.ui.popup:before {
  width: 40px;
  height: 40px;
}
/* .for-get-st-arrow-1 {
  display: none;
} */
.ui.popup {
  border-radius: 12px !important;
  box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.06),
    0px 16px 16px -8px rgba(0, 0, 0, 0.06) !important;
}

.ui.bottom.center.popup.transition.visible {
  margin-top: 20px !important;
}

.get-st-next-btn:active {
  background-color: #3182ce !important;
}
.get-st-next-btn:focus {
  background-color: #3182ce !important;
}
.get-st-next-btn:focus-visible {
  background-color: #3182ce !important;
}
.get-st-next-btn:visited {
  background-color: #3182ce !important;
}
/* jeyabalaji */
/* jeyabalaji 15-03-2022 12:10pm */
.tab_text_before_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #636d73;
  float: right;
}

.srch_box_for_apt_edu {
  margin-top: 20px;
  height: 72px;
  background: #f7f7f7;
  display: flex;
  width: 100% !important;
}
.for_pat_edu_web_srch_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
  width: 80px !important;
}
.for_pat_edu_web_srch_1_box input {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  width: 155px;
  align-items: center;
}
.for_pat_edu_web_srch_1_box {
  width: 155px !important;
  margin-left: 16px !important;
}
.for_pat_edu_web_srch_1_box .ant-input-group-addon {
  display: none !important;
}
.for_pat_edu_web_srch_1_box .ant-input-group input {
  border-radius: 6px !important;
}
.for_pat_edu_whole_div_srch {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 24px !important;
  display: flex;
  width: 100% !important;
}

.for_pat_edu_web_srch_2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #000000;
  width: 120px !important;
  margin-left: 22px !important;
}
.for_pat_edu_web_srch_2_box input {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  width: 155px;
  align-items: center;
}
.for_pat_edu_web_srch_2_box {
  width: 150px !important;
  margin-left: 8px !important;
}
.for_pat_edu_pub_pri {
  background: rgba(32, 201, 172, 0.1) !important;
  border-radius: 4px;
}
.for_pat_edu_pub_pri_text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #20c9ac;
}
.for_pat_edu_pub_pri_public {
  background: rgba(85, 66, 246, 0.1);
  border-radius: 4px;
}
.for_pat_edu_pub_pri_text_public {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #5542f6;
}

/* jeyabalaji 15-03-2022  12:10pm */

.pat_edu_user_pic_ins_letter {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  margin-top: 5px !important;
}
.pat_edu_user_pic_ins {
  background: #a8aebe;
  margin-top: 12px !important;
}
.for-par-edu-dwnld-icon svg {
  width: 16px;
  height: 16px;
  margin-left: -4px;
  margin-top: -4px;
}
.for-par-edu-dwnld-icon {
  margin-left: 11px !important;
}
/*-----------cheran 18-3-22---------------*/
.widthPlus .ant-input {
  height: 32px !important;
  width: 157px !important;
}
.widthPlus1 {
  height: 32px !important;
  width: 182px !important;
}
/*-----------cheran 18-3-22---------------*/
.pat_edu_table td {
  padding-left: 0px !important;
}
.for_pat_edu_table_h th {
  padding-left: 0px !important;
}
.for_pat_edu_table_h {
  margin-left: 20px !important;
}
/*-----------cheran 29-3-22 start---------------*/
.MTop {
  margin-top: 0px !important;
}
.fuap-mod1 .modal-dialog {
  width: 540px !important;
}
.fuap-mod1 .modal-content {
  width: 538px !important;
}
.MGroup {
  display: flex !important;
}
.MJC {
  justify-content: space-between !important;
}
.MPH {
  padding: 0px !important;
  height: max-content !important;
}
.MPL {
  padding-left: 20px !important;
}

.btn-remove-shadow.btn.btn-primary:focus {
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none !important;
}

.macro-btn-rem-shadow.btn.btn-primary:focus {
  background-color: #00bf9e;
  border-color: #00bf9e;
  box-shadow: none !important;
}

/*-----------cheran 29-3-22 end---------------*/
/* christina start 3.4.22 */

/* .addproblemStart {
  width: 229px;
} */
.addproblemEnd {
  margin-left: 10px;
  /* width: 199px; */
}
.addproblemSearch .input {
  height: 34px;
  width: 358px;
  margin-right: 10px;
}
#addProblemInput .addproblemSearch .results {
  width: 100%;
}
.familyHistoryBorder:last-child {
  border-bottom: none !important;
}
.addProblemMargin {
  margin-left: 9px;
}
.patEduDownloadBtn {
  height: 32px;
  width: 32px;
  margin-top: 1px;
}
.patEduPadding {
  margin-top: -16px !important;
}
.familyHistBorder {
  overflow: hidden;
}
/*-----------cheran 30-3-22 start---------------*/
.Pft-MB {
  margin-bottom: 11px !important;
}
.LMB-MT {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: -5px !important;
}
.LMB-Pad {
  padding-top: 8px !important;
  padding-bottom: 11px !important;
}
.PFT-ICons {
  display: flex !important;
  justify-content: space-between !important;
  width: 104px !important;
  padding-top: 13px !important;
  /* padding-bottom: 19px !important; */
}
.PFT-edit {
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 7%), 0px 0.5px 2px rgb(0 0 0 / 7%);
  border-radius: 4px;
  text-align: center;
  width: 32px;
  height: 32px;
  color: #2c7be5;
  padding-top: 6px;
}
.PFT-ICONH:hover {
  background-color: #2c7be5 !important ;
  color: #ffffff !important;
}
.PFT-ICONH1:hover {
  background-color: #ef5f5f !important ;
  color: #ffffff !important;
}
/*-----------cheran 30-3-22 end---------------*/
/*-----------cheran 31-3-22 start---------------*/
#cfbtn-pft {
  box-shadow: none !important;
}
/* .Pft-btn .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #3182ce !important ;
  color: #ffff !important;
  box-shadow: none !important;
  border-color: #005cbf !important;
}
.Pft-btn .btn-primary:not(:disabled):not(.disabled):hover {
  background-color: #0f6ff0 !important ;
  color: #ffff !important;
}
.Pft-btn .btn-primary:not(:disabled):not(.disabled):focus {
  background-color: #3182ce !important ;
  color: #ffff !important;
  box-shadow: none !important;
  border-color: #005cbf !important;
} */
.cfbtn-pft .btn-primary:not(:disabled):not(.disabled):focus,
.cfbtn-pft .btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: none !important;
}

.Pft-btn .btn-primary {
  background-color: #2c7be5 !important ;
  color: #ffff !important;
}
.Pft-cfbb .card-footer {
  border-bottom: none !important;
  z-index: 2 !important;
}
.pft-mgr {
  margin-right: 20px !important;
}
/*-----------cheran 31-3-22 end---------------*/
/*-----------cheran 1-4-22 start---------------*/
.fl-pdl {
  padding-left: 16px !important;
}
.fl-pdl2 {
  padding-left: 8px !important;
}
.fl-mlr {
  margin-left: 0px !important;
  margin-right: 10px !important;
}
.fl-mgb {
  margin-bottom: 11px !important;
}
#fl-srch {
  margin-left: 0px !important;
}
.cBtn-fl {
  box-shadow: none !important;
}
.fl-mgl {
  margin-left: 5px !important;
}
.fl-bt-g {
  display: flex !important;
  align-items: center !important;
  width: max-content !important;
}
.fl-padr-cell {
  padding-left: 20px !important;
}
/*-----------cheran 1-4-22 end---------------*/
/*-----------cheran 4-4-22 start---------------*/
.Dpd-pad {
  padding: 20px !important;
}
.fl-mrnil {
  margin-right: 26px !important;
}
.srx-pad {
  padding-top: 6px !important;
  padding-bottom: 11px !important;
}
.Srx-mlb {
  padding-top: 2px !important;
  margin-left: 20px !important;
  margin-bottom: 20px !important;
}
.srx-ht {
  height: 72px !important;
  padding-left: 8px !important;
  margin-right: 0px !important;
}
.ant-srx {
  margin-right: -6px !important;
}
.srx-mtbm {
  margin-top: 20px !important;
  margin-bottom: 21px !important;
}
.Dpd-pad1 {
  padding-top: 20px !important;
}
.rdo-mgb {
  margin-bottom: 6px !important;
}
.srx-iconlf {
  margin-right: 15px !important;
}
.srx-padnil {
  padding: 2px 0px 2px 0px !important;
}
.srx-tab-head {
  padding-left: 1px !important;
  margin-top: 15px !important;
  margin-bottom: 22px !important;
}
.srx-title {
  padding-top: 5px !important;
  padding-left: 1px !important;
  margin-bottom: 20px !important;
}
.srx-m .form-row {
  padding-left: 1px !important;
  margin-bottom: 0px !important;
}
.srx-padr {
  padding-right: 8px !important;
}
/* .srx-iconlf :hover {
  background-color: #2c7be5;
  color: #ffff !important;
} */
.btn-size-fl {
  padding: 5px !important;
}
/* .hover_eff_1:hover {
  position: relative !important;
} */

#hoover_effect:hover > #card_footer_cont {
  cursor: pointer;
  display: block !important;
  z-index: 1000;
}
#demographics
  > div
  > div
  > div.bill_profile_tab_card
  > div
  > div.card_bill_pro_value_1.for-bill-prof-car-bill-value.bfp-mgbnil
  > div:last-child
  > div#hoover_effect:hover {
  position: relative !important;
  margin-bottom: -58px !important;
  transform: scale(1.05) !important;
  transition: transform 1.05s ease-in-out !important;
}
.fl-pdl1 {
  padding-left: 27px !important;
}
.blp-mgbn {
  margin-bottom: 0px !important;
}
.bfp-mgbnil {
  margin-bottom: -1px !important;
}
.bfp-bpdm {
  padding-top: 0px !important;
  padding-bottom: 11px !important;
}
.cfbtn-pft1 .btn-light:not(:disabled):not(.disabled):active {
  box-shadow: none !important;
}
.btn-light:not(:disabled):not(.disabled):hover {
  box-shadow: none !important;
}
.btn-light:not(:disabled):not(.disabled):focus {
  box-shadow: none !important;
}
.btn-prmary:hover {
  color: #fff !important;
  box-shadow: none !important;
}
.btn-prmary:focus {
  box-shadow: none !important;
}
.ant-srx .ant-radio-button-wrapper-checked {
  background-color: #2c7be5 !important ;
  box-shadow: none !important;
}
/*-----------cheran 4-4-22 end---------------*/

/* saravanan 1.4.22 5:00 start */
.btn-remove-shadow.btn.btn-primary:focus,
.btn-remove-shadow.btn.btn-primary:active {
  background-color: #007bff;
  border-color: #007bff;
  box-shadow: none !important;
}

.macro-btn-rem-shadow.btn.btn-primary:focus {
  background-color: #00bf9e;
  border-color: #00bf9e;
  box-shadow: none !important;
}

.macro-add-btn {
  cursor: pointer;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background-color: rgb(11, 95, 255);
  display: inline;
  color: white;
  float: right;
}

.man-left-tab-body {
  overflow-y: auto;
  height: 366px;
}
.man-scnd-layer div {
  cursor: pointer;
}
.thrd-gen-txt {
  padding: 0px 16px 16px;
}
.man-tab-added:hover {
  color: white;
  background: #173e67;
  transition: 0.3s;
}

/* Vanitha 12-04-22 Start */

.search_ins_med {
  font-size: 0.86em !important;
}
.search_input .input {
  margin-top: 1px !important;
  margin-left: 1px !important;
  width: 103% !important;
}
.search_input .icon {
  padding-bottom: -1px !important;
}
.search_input .ui.icon.input > i.icon {
  top: -2px !important;
}

/* Vanitha 12-04-22 End */

/* Vanitha 13-04-22 Start */

.fam_tab_alt {
  margin-bottom: 10px !important;
}

.fam_loadmore_alt {
  padding-top: 25px !important;
}

.fam_obs_act_alt {
  float: left !important;
}
.fam_obs_alt1 {
  border-bottom: 1px solid #cbd5e0 !important;
}

.search_alt .searchWrapper {
  padding: 2px !important;
}

.fam_tog_droptown_alt {
  padding: 10px !important;
}

.add_observation1 .modal-dialog {
  height: 290px !important;
}
.alle_ty_alt .form-control {
  margin-left: -11px !important;
}
/* Vanitha 13-04-22 End */
/*-----------cheran 11-4-22 start---------------*/
.srx-mtpnil {
  margin-top: 0px !important;
}
.srx-m .form-row {
  padding-top: 1px !important;
}
.srx-mtp1 {
  margin-top: 5px !important;
}
.srx-mgtop1 {
  margin-top: auto !important;
}
.srx-n {
  padding-top: 5px !important;
  padding-bottom: 1px !important;
}
.srx-marg-auto-btm {
  margin-bottom: 20px !important;
}
.srx-mgrt {
  margin-right: 20px !important;
  margin-left: 20px !important;
}
.srx-mgrt1 {
  margin-left: 27px !important;
}
.btn-prmary {
  background-color: #2c7be5 !important ;
}
.btn-prmary1:focus {
  box-shadow: none !important;
}
.btn:focus {
  box-shadow: none !important;
}
/* .btn-prmary1:hover {
  color: #ffff !important;
} */
.radio_btn_alt .ant-radio-inner {
  top: 1px !important;
}
.radio_btn_alt_sel .ant-radio-inner {
  left: 2px !important;
  top: 1px !important;
}
.radio_btn_alt_cli .ant-radio-inner {
  left: 1px !important;
}
.radio_btn_alt_ins .ant-radio-inner {
  top: 1px !important;
  left: 2px !important;
}
.pat_info_active_alt .btn-danger {
  border: none !important;
}
.pat_info_active_alt .btn:focus {
  border: none !important;
  box-shadow: none !important;
}
/* Vanitha 04-04-22 */
/* Vanitha 29-03-22 */
.bill_prof_alt .schedule-body-row td {
  padding-left: 22px !important;
}
/* Vanitha 29-03-22 */
/* Vanitha 1-04-22 */
.reminder_check_pad {
  margin-left: -9px !important;
}
.demo_cont_btn_alt {
  margin-top: -65px !important;
  padding-bottom: 2px !important;
}
.demo_add_cont_alt {
  display: flex;
}
/* .demo_btn_alt {
  padding-top: 2px !important;
} */
.demo_flag_alt {
  margin-top: -53px !important;
}
.table_hd_alt .bill-total-table .schedule-head-row th {
  font-weight: 400 !important;
}

.med_act_alt {
  display: flex;
}

.bill_txt_btn_alt .btn-primary :hover {
  color: white !important;
}
button:hover {
  cursor: pointer;
}
.active-badge-pat button:hover {
  cursor: pointer;
  background-color: #ecf7f0 !important;
  color: #57bf7f !important;
}
.top-pat-dropdown :active {
  cursor: pointer;
  background-color: #ecf7f0 !important;
  color: #57bf7f !important;
}
.active-badge-pat button:focus {
  cursor: pointer;
  background-color: #ecf7f0 !important;
  color: #57bf7f !important;
}

.search_input .ui {
  font-size: 0.9em !important;
}
.search_input1 .ui {
  font-size: 0.91em;
  margin-left: 5px;
  margin-bottom: 15px;
}
.cont_btn_alt {
  box-shadow: none !important;
}
.table_lastch_bor tr:last-child {
  border-bottom: none !important;
}

/* Vanitha 1-04-22 */

/* Vanitha 04-04-22 */
/* .pat_info_active_alt .btn-primary :hover{
color: #57bf7f !important;
background-color: none !important;
} */
.pat_info_active_alt button:focus {
  background-color: none !important;
  border: none !important;
}

/* Vanitha 05-04-22  Start*/
.patflag_cls_btn_alt {
  margin-top: 17px !important;
}
.pat_flag_add_alt .form-row {
  margin-right: 45px !important;
  margin-top: 6px;
}
.pat_flag_in_des_alt {
  display: flex;
}
/* Vanitha 05-04-22  End*/
.demo_cont_alt .search {
  font-size: 0.92em !important;
  margin-left: 7px !important;
  margin-bottom: 20px !important;
}
.top-pat-dropdown .dropdown-toggle .btn .btn-primary {
  background-color: white !important;
}
.pfl-demo {
  margin-top: 0px !important;
}
.pst-bod-demo {
  border: 1px solid rgb(226, 232, 237);
  margin: 20px 0px 20px 0px !important;
  padding: 1px 0 2px;
  border-radius: 4px !important;
}
.pst-bod-srx {
  border: 1px solid rgb(226, 232, 237);
  margin: 0px 20px 0px 20px !important;
  padding: 1px 0 2px;
  border-radius: 6px !important;
  overflow-x: hidden;
}
.d-flex.btn-primary:not(:disabled):not(.disabled):active,
.show > .top-pat-dropdown.btn-primary.dropdown-toggle {
  background-color: #fff !important;
}
.cl1 .modal-title {
  margin-top: 6px !important;
}
.cl1 .modal-header .close {
  margin-top: 7px !important;
  margin-right: 0px !important;
}
.pfl-demo-con {
  padding-bottom: 20px !important;
}
.contact-demogr-box-down {
  margin-top: 20px !important;
}
/* saravanan 19-04-22 */
.up-doc-padt {
  padding-top: 10px;
}
.doc-up-block {
  margin-top: 7px;
  margin-bottom: 13px;
  display: flex;
  width: 100%;
  border-top: 0px;
}
.upload-btn-right {
  display: flex;
  justify-content: right;
  margin-left: auto;
}
.up-d-mt4 {
  margin-top: 4px !important;
}
.up-d-tab-wid {
  min-width: 300px;
}

.up-d-mod-head {
  border: 0px;
  margin-top: 13px;
}
.ant-upload-hint {
  margin: 5px;
}
.up-d-mar-t-25 {
  margin-top: 25px;
}
.up-d-icon-f {
  font-size: 24px;
  color: #2c7be5;
  margin-right: 5px;
}
.up-d-save-btn {
  margin-top: 15px;
  border-radius: 5px;
  width: 110px;
}
.up-d-edit-mod {
  height: 380px;
  display: grid;
  place-items: center;
}
.up-d-mod-mb {
  margin-bottom: 4px;
}
.up-d-mess-unsup {
  font-size: 20px;
}
.lab-res-sub-btn.btn.btn-primary {
  height: 32px;
  margin-bottom: 20px;
}
.lab-items-block {
  display: grid;
  margin-left: -10px;
  grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
}
.lab-res-items-list {
  display: flex;
  align-items: center;
  justify-content: start;
}
@media (max-width: 1100px) {
  .lab-res-items-list span:not(span.ant-checkbox) {
    font-size: 10px;
  }
}
.lab-res-sub-head {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin: 16px 0px 0px 0px;
}
.lab-res-spin {
  display: grid;
  place-items: center;
}
.lb-rs-sm-spin {
  height: 80px;
}
.lb-rs-md-spin {
  height: 250px;
}

.lb-rs-sub-hd {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
.ed-doc-mod-title {
  font-size: 18px;
  font-weight: 600;
}
.ed-doc-mod-body {
  max-height: 700px;
  overflow: auto;
}
.document_report_card .card {
  margin: 0px 20px;
}
.document_report_card .card-header {
  color: #2c7be5;
  font-size: 18px;
  font-weight: 500;
  float: left !important;
  background-color: #fff;
  border-bottom: none !important;
}
/* Ahamed_Changes_21.04.22_Start */
.click_event_appointment_active {
  margin-top: 258px !important;
}
.click_event_problem_active {
  margin-top: 590px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #000;
}
/* Ahamed_Changes_21.04.22_End */

/*---------------cheran -----25-4-22-------*/
.ininfo {
  margin-top: 21px !important;
}
.hr-mgbtm {
  margin-bottom: 20px !important;
}
/*---------------cheran -----25-4-22-------*/

/*  Code already given */
.act_inact-outlined_alt {
  border-radius: 20px !important;
  overflow: hidden;
  height: 35px;
  text-align: center;
  padding-top: 7px !important;
  width: 100px !important;
  display: flex;
}
/*  Code already given */

/* Vanitha 30-04-22 */

/* Vanitha 02-04-22 */

.pt_med_tab_alt {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

/*  code already given */

/* .dot__alt {
  margin-right: 4px !important;
  margin-top: 6px !important;
  margin-left: 23px !important;
} */

/*  code already given */

/* Vanitha 05-05-22 */

#add-patientflag .modal-header .close,
.favorite_prescription_add .modal-header .close {
  padding: 0px 2px 4px 1px !important;
  font-size: 1.5rem !important;
  margin-top: 0px !important;
}

.dot_inact_alt {
  margin-right: 4px !important;
}
/*--------------------------Update by cheran--------------------*/
.arrow-adj1 {
  -webkit-appearance: none;
  background-image: url("../../assets/images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right -1px top 1px;
  line-height: 1;
  padding-right: 18px !important;
}
.Cblck-pad {
  padding: 20px !important;
}
.pfcolor {
  margin-left: -77px !important;
  margin-right: 49px !important;
}
.pf-modal-body {
  padding-bottom: 6px !important;
}
.pf-cancelbtn {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 4px;
  margin-right: 16px;
}
.pf-modalfooter {
  padding: 16px !important;
}
.pf-modal-body .form-control:disabled,
.form-control[readonly] {
  background: none !important;
}
.pf-zip {
  margin-left: 29px;
  padding-right: 4px;
}
.pf-st {
  width: 94px;

  margin-left: 5px;
}
.pf-dis-bck {
  padding: 0px !important;
}
.for-fl-pad-btm {
  padding-bottom: 9px !important;
}
.pf-mb-1 {
  margin-bottom: 6px !important;
}
.pf-mb-2 {
  margin-top: auto !important;
}
.problemTitle {
  color: #2c7be5 !important;
}
.problemsTableHeadSmall {
  font-weight: 600 !important;
}
.title_patflg_alt {
  margin-top: -3px !important;
  margin-left: 0px !important;
  font-size: 20px !important;
}

.patflag_pop_clr_alt {
  margin-left: 49px !important;
  width: 50% !important;
  margin-top: -36px !important;
  margin-right: 20px !important;
  /* height: 36px !important; */
  margin-top: -35px !important;
}

.patflag_pop_namealt {
  margin-top: 9px !important;
}

.pat_flag_clr input[type="color"] {
  border: 1px solid #ced4da !important;
}

.pat_flag_tit_alt1 {
  font-weight: 600 !important;
  /* font-size: 20px !important; */
}

.for_dot_inact_bg {
  /* background-color: pink; */
  background-color: #ffe5e3 !important;
  position: relative !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px !important;
}

.for_dot_act_bg {
  position: relative !important;
  /* background-color: lightgreen !important; */
  background-color: #c9f7e6 !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px !important;
}
.for_dot_resolved_bg {
  position: relative !important;
  background-color: #dbf3fa !important;
  border-radius: 50% !important;
  height: 16px;
  width: 16px;
  margin-right: 5px !important;
}
.add_med_btn_alt {
  width: 160px !important;
}

.med_dot_inact_ {
  margin-left: 0.3px !important;
}
/* Vanitha 05-05-22 */
.patEduTableRadius {
  border-radius: 6px !important;
  margin-bottom: 19px !important;
}
#patientEduTab {
  padding-bottom: 1px !important;
}
.patEduLoadMore {
  margin-top: 21px;
  margin-bottom: 9px;
}
.problemsBottom {
  margin-bottom: 10px !important;
}
.problemsLoading {
  margin-top: 10px;
  margin-bottom: -10px;
}

/*Ahamed_Changes_07-05-22*/
.pat-list .MuiTableHead-root {
  border-top: 0px;
}
#immunize-record .imu-bor-set1 {
  border-top: 1px solid #cbd5e0;
  margin: 2px -15px 20px -20px;
}
#immunize-record .imu-bor-set2 {
  border-top: 1px solid #cbd5e0;
  margin: 2px -15px 0px -20px;
}
#immunize-record .imu-bor-set3 {
  border-top: 1px solid #cbd5e0;
  margin: 2px -15px 14px -20px;
}
.imm-main-block .set-lab-top {
  padding: 20px !important;
}
.create-client .modal-header {
  padding: 19px 15px 20px 15px;
}
.create-client .modal-body {
  padding: 0rem 20px;
}
.create-client .form-label {
  margin-top: 8px;
}
.form-group {
  margin-bottom: 1rem;
}
.create-client .modal-footer {
  margin-top: 16px;
  padding: 6px;
  padding-bottom: 7px;
}
.create-client .modal-dialog {
  height: 413px;
}
.proc-order .set-lab-top {
  padding: 16px 20px 0px 20px !important;
}
.right-block .appt-block-main.proc-order {
  padding-bottom: 0px;
}
.proc-order-det .set-lab-top {
  padding-top: 10px !important;
  padding-left: 20px !important;
  padding-bottom: 6px !important;
}
.proc-order-det .row {
  margin-left: 0 !important;
}
.proc-order-det.appt-block-main {
  padding-bottom: 20px !important;
}
.proc-order-det .btn-box {
  margin-top: 0px !important;
}
.proc-order-det .box {
  padding-bottom: 12px !important;
}
.procedure-det-del {
  width: 25px;
  height: 25px;
  font-size: 15px;
  margin-top: 3px;
  margin-left: 16px !important;
}
.proc-order-det .pro-ord-det-table {
  padding-bottom: 20px !important;
}
.proc-order-det div.border-top {
  padding-bottom: 20px !important;
}
.pat-list .appt-block-main {
  padding-bottom: 20px !important;
}
.pat-list .loading-more {
  padding-bottom: 0px !important;
}
.pat-list .inactive-badge {
  background-color: rgba(239, 95, 95, 0.1) !important;
}
.pat-list .inactive-outlined:active {
  color: #d43731 !important;
  background-color: #f6e8e8 !important;
}
/*Ahamed_Changes_07-05-22*/

/* Vanitha 06-05-22 */

.med_act_btn_alt {
  display: flex;
}

.pat_top_tit_alt {
  display: flex !important;
  margin-bottom: 21px !important;
}

.med_tit_alt {
  margin-top: 7px !important;
}

/* Vanitha 06-05-22 */

/* Vanitha 07-05-22 */

.top_tit_temp_dis {
  display: flex;
}

.alle_tab_tit {
  margin-top: -1px !important;
}

.alle_tab_top {
  margin-top: 20px !important;
}

.alle_tab_top_one {
  margin-bottom: 10px !important;
}

.alle_tab_tit_one {
  margin-top: 6px !important;
}

/* Vanitha 07-05-22 */

/* Vanitha 08-05-22 */

.add_alle_mod_tit_alt {
  margin-top: -5px !important;
}

.fam_hist_tab_alt {
  display: flex !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  margin-right: 20px !important;
}

.addfamhis_mod_alt {
  margin-top: 14px !important;
}

/* Vanitha 08-05-22 */

/* Vanitha 09-05-22 */
.add_allergy .modal-dialog {
  height: 30px !important;
}

.pat_flag_tot_card_bot_alt {
  margin-bottom: -26px !important;
}

.dot-act_inactive_alt {
  position: absolute;
  top: 6px !important;
  left: 4px;
}

.alle_med_frst_col_alt {
  color: #2e2e2e !important;
  font-weight: 600 !important;
}

.famhis_obser_tab_clr_alt {
  background-color: #fafafa !important;
}

.fam_obs_alt .MuiTableRow-root.MuiTableRow-head th {
  color: #2e2e2e !important;
  border-bottom: 1px solid #cbd5e0 !important;
  background-color: #fafafa !important;
  font-weight: 500 !important;
}

/* Vanitha 09-05-22 */
#patEduTitleContent {
  color: #2e2e2e !important;
  font-weight: 600 !important;
}
.patuploadbuttom svg {
  margin-top: -5px;
  margin-right: 8px;
}
.uploadTitleInput {
  margin: 2px 0px 2px -12px !important;
}
.uploadPrivacyInput {
  margin: 2px 0px 2px -14px !important;
}
.uploadTitleDescription {
  margin: 0px 0px 0px -13px !important;
}
.uploadTagsInput {
  margin: 0px 0px 0px -13px !important;
}
.uploadButtonFooter {
  padding-top: 16px;
  padding-bottom: 16px;
}
.add_new_edu_resource .modal-footer {
  padding-left: 16px !important;
}
.add_new_edu_resource .modal-header .close {
  margin-top: 7px !important;
}
/* saranya */

.add_new_edu_resource1 .modal-dialog {
  width: 400px !important;
}
/* saranya */

.all-tbl-hd-sty {
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #2e2c34;
}

.upload-doc-pat {
  margin-top: 16px;
}

.upload-doc-pat .card-header {
  padding: 15px 20px 15px 20px !important;
  /* border-bottom: 1px solid #e2e8ed !important; */
  /* margin-bottom: 20px; */
}
.lab-res-items-list .ant-checkbox-wrapper {
  margin-top: 16px !important;
}
.correct-crd-padng .card-header {
  padding: 20px 20px 16px 20px !important;
}
.el-rep-tbl-marg {
  margin-bottom: 10px !important;
}
.mk-marg-nill.appt-block-main {
  margin-top: 0px !important;
}
.pro-ord-mdbd.modal-body {
  height: 637px;
  overflow: auto;
}
.pr-mdl-rw.MuiTableRow-root th {
  background: #f1f5f8 !important;
  color: #718096 !important;
}
.pr-mdl-top-hd.MuiTableRow-root.MuiTableRow-head th {
  color: black !important;
}
.pdr-md-foot.modal-footer {
  padding: 20px !important;
}
.MuiTableRow-root .pdr-br-lft {
  border-left: 1px solid #ebeaed !important;
}
.all-marg-b-nill {
  margin-bottom: 0px !important;
}
.pdr-res-tbl .MuiTableHead-root .MuiTableRow-root {
  border-bottom: 1px solid #ebeaed !important;
}
#problemsTableDiv {
  margin-top: -1px !important;
}

.up-d-xcir-ic {
  margin-left: 6px;
  margin-right: 0px !important;
}
/*Ahamed_Changes 10-05-22*/
.create-client .modal-content {
  min-height: 413px;
}
.pat-list .dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef !important;
}
.pat-list .patient-search {
  margin: 16px 16px 12px 20px !important;
}
.pat-list .bgset-search {
  background-color: #f7f7f7;
  height: 66px;
  display: flex;
  justify-content: space-between;
}
.appt-block-main-bill #dropdown-basic {
  background-color: #f7f7f7 !important;
}
.pat-list .MuiTableContainer-root {
  margin-top: 20px !important;
}
.pat-list .avatar-right-div {
  color: #2a2a2a !important;
}
.add-payment .modal-header {
  padding: 20px !important;
}
.add-payment .modal-title {
  padding-left: 0px !important;
}
.add-payment .close {
  margin-top: 0px !important;
}
.add-payment .modal-header {
  /* width: 400px;
  border */
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.create-client .form-group {
  margin-bottom: 0rem !important;
}
.imm-main-block#demographics > div {
  padding-bottom: 0px !important;
}
#immunize-record .imm-head-title {
  margin-top: 11px;
  margin-bottom: 9px;
}
/*Ahamed_Changes 10-05-22*/

/*Ahamed_Changes 11-05-22*/
.pat-dashboard .patient-pro-data {
  padding: 20px 17px 17px 16px !important;
}
.pat-dashboard .pat-btn-layer {
  padding-top: 16px !important;
  padding-bottom: 20px !important;
}
.pat-dashboard .create-appt.btn-primary {
  margin-right: 20px !important;
}
.pat-dashboard .appoint-main {
  padding-top: 20px !important;
}
.imm-reacords label {
  margin-top: 2px;
}
.vitals-block .vitals-pad {
  padding-top: 9px;
}
/*Ahamed_Changes 11-05-22*/
.ht-adj-pflg {
  margin-top: -3px !important;
  margin-right: 0px !important;
}
.fl-mgr-not {
  margin-right: 0px !important;
}
.flxd {
  flex-direction: column !important;
  margin-top: -6px !important;
}
.sig-mg-0 {
  margin-top: 0px !important;
}
.pfl-fin {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
}
.pfl-demo-con .tbc-bdr {
  border-radius: 6px !important;
}
.newhr-pf {
  margin-top: 2px !important;
  margin-bottom: 16px !important;
}
.hr-pi-last {
  margin-top: 2px !important;
}
.adc-demo-mg-16 {
  margin-top: -4px !important;
}
.adc1-demo-mg-16 {
  margin-top: 2px !important;
}
.ph-adj-mgtop {
  margin-top: -3px !important;
}
.srx-m .srx-marg-auto-btm {
  margin-bottom: 20px !important;
}
.button-pad-srx {
  padding-top: 5px !important;
}
/* Vanitha 13-05-22 */

.load_more_alt2 {
  padding-top: 9px !important;
  padding-bottom: 11px !important;
}

.pat_flag_dlt_btn_alt {
  width: 82px !important ;
}

.pat_flg_loadmore_btn_alt {
  padding-top: 10px !important;
  padding-bottom: 1opx !important;
}

.pat_flg_loadmore_card_btn_alt {
  padding-top: 26px !important;
}

/* Vanitha 13-05-22 */
.up-d-progress_bar {
  border-radius: 6px;
  background: #e9f0f9;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  padding: 6px 10px 6px 10px;
  word-break: break-all;
}
/*-----------------update by Cheran Start -----------------------*/
.srx-m .button-pad-srx {
  padding-top: 5px !important;
}
.srx-pre-buton-mgbtm {
  margin-bottom: 0px !important;
}
.mdl-close .modal-header .close {
  font-size: 1.5rem !important;
}
#demographics
  > div
  > div.srx-n.button-pad-srx.form-row
  > div:nth-child(2)
  > button
  > span
  > svg {
  padding-bottom: 2px;
}
.prim-hght {
  height: 34px !important;
}
#ht-srx-ad {
  padding: 9px !important;
}
/*-----------------update by Cheran End -----------------------*/

.past-future-table-row tr:last-child {
  border-bottom: 0px solid #cbd5e0 !important;
}

.dot_inactive_problems {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
  vertical-align: middle;
  margin-top: -4px;
  background-color: #d43731;
}

.dot_active_problems {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
  background-color: #57bf7f;
  vertical-align: middle;
  margin-top: -7px;
}
.dot_resolved_problems {
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
  vertical-align: middle;
  margin-top: -5px;
  background-color: #4019ff;
}
/*-------------------cheran update 25/5/22----------------*/
.bgset-search .ui.icon.input > input {
  height: 34px !important;
}
.frw-pop .modal-body {
  height: 400px !important;
}
.frw-pop .modal-dialog {
  height: max-content !important;
  width: 620px;
}
.das-flag-align {
  text-align: left !important;
}
.ad-fmh .modal-header {
  padding-bottom: 20px !important;
}
.imp-svg {
  box-shadow: none !important;
  padding-top: 4px !important;
}
.imp-svg svg {
  margin-top: 1px;
}

.rnav-trans {
  transition: height 1s;
}
.ad-bck #dropdown-basic.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #fff !important;
}

/*-------------------cheran update 25/5/22----------------*/

/* Vanitha 15-06-22 */

.bor_alt_rad {
  border-bottom-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  overflow-x: hidden !important;
}

.bor_rad_alteration {
  border-radius: 6px !important;
  overflow-x: hidden !important;
}

/* Vanitha 15-06-22 */
/*-----------------update by Cheran Start ---------------------*/
.widget-ht > h1,
.widget-ht > p,
.widget-ht > .explore-vozo-button {
  display: none;
}
/* .adj-mdb-ht-scrll {
  height: 331px !important;
  overflow-y: auto;
} */
/*-----------------update by Cheran End -----------------------*/

.pat_name {
  padding-bottom: 12px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #2d3748;
}
.editnote_date {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #2d3748;
}
.edit_note_time {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #747474;
}

.edit_note_prov {
  font-family: "Inter";
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #718096;
  margin-top: 3px;
}
.edit_note_prov_name {
  font-family: "Inter";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #2c7be5;
  margin-top: 3px;
}

.macro-btn-properties {
  box-shadow: 0px 0 8px rgb(20 46 110 / 37%);
  border-radius: 4px;
}
.macro-btn-properties svg {
  position: absolute;
  right: 4px;
  font-size: 20px;
  cursor: pointer;
}
.macro-btn-properties .buttons-row {
  height: 150px;
  overflow-y: scroll;
}
.clinical-notes .edit-form-start {
  padding-top: 20px;
}
.review-notes .ant-select-selector,
.med-form-fam .ant-select-selector {
  max-height: 120px;
  overflow-y: auto;
}

/*-----------------update by Preetha Start -----------------------*/

.patient-pro-data-note {
  width: 99% !important;
  margin-top: 5px;
  padding: 16px 24px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  text-align: right;
}

.patient-sign-lock {
  width: 99px !important;
  margin-right: 16px;
}

.preview_table_print {
  background-color: #ffffff;
  border: 1px solid #ebeaed;
  color: #718096;
  width: 86px;
  height: 34px;
  border-radius: 4px;
}

.whole-div {
  text-align: right;
}

.status-div {
  font-weight: 500;
  font-size: 16px;
  float: left;
  color: #57bf7f;
  line-height: 19px;
  padding-top: 8px;
}

.patient-title {
  margin-left: 15px !important;
}

.for-pat-nav-arrow1 svg {
  margin-left: 88px !important;
}

.for-pat-nav-arrow2 svg {
  margin-left: 89px !important;
}

.for-pat-nav-arrow3 svg {
  margin-left: 35px !important;
}

/*-----------------update by Preetha End -----------------------*/

.edit-note-nav-title {
  font-size: 15px;
  font-weight: 500;
  font-family: "Inter";
  color: #2d3748;
  margin: 4px 0 0 17px;
  text-align: left;
  width: 100%;
}
button.edit-note-nav-acc {
  width: 100%;
}
.ed-note-menu-list {
  padding-left: 15px;
}
.ed-note-menu-list a {
  font-size: 14px !important;
}

.iframe_for_print_doc {
  display: none;
}
@media print {
  .iframe_for_print_doc {
    display: block;
  }
}
.prov_pat_name {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding-left: 9px;
  padding-top: 6px;
}
.prov_pat_name_div {
  width: 28px;
  height: 28px;
  background: #a8aebe;
  border-radius: 50%;
  margin-right: 12px;
}

/*---------update by cheran  27/7/22 start---------*/
.erxset-weno {
  margin: 0px !important;
}
.weno-erx-btn-disbled .btn-primary:disabled {
  border-color: #ced5e0 !important;
  background-color: #ced5e0 !important;
}
.erx-tag-wenochckbox {
  margin-top: -3px !important;
}
.weno-adj-mgb {
  margin-bottom: 10px !important;
}
/*---------update by cheran  27/7/22 end---------*/
.assessment_form {
  padding: 12px 20px;
}

.consent_form_head {
  font-family: "Manrope";
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #2e2c34;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 0px !important;
  margin-bottom: 9px !important;
}
.consent_form_details {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0015em;
  color: #718096;
  padding-left: 20px;
  padding-bottom: 10px;
  text-align: left;
}
.Consent_form_add_button {
  background: #2c7be5;
  border-radius: 4px;
  height: 32px;
  margin-right: 16px;
}
.Consent_form_add_button svg {
  margin-top: -4px;
}

.Consent_form_add_button_text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.0015em;
  color: #ffffff;
  margin-left: 10px;
}
.consent_form_archive_btn {
  background: #ebeaed;
  border-radius: 4px;
  margin-left: 16px;
  height: 32px;
}
.consent_form_archive_btn_text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #2d3748;
}
.con_form_tab_title {
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2c7be5;
  word-break: break-word;
  max-width: 80%;
}

.con_form_tab_owner {
  font-family: "Work Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #2e2e2e;
}

.table_cell_for_checkox .con_form_tab_mandatory {
  margin-top: -10px !important;
}
.table_cell_for_checkox .con_form_tab_default {
  margin-top: -10px !important;
}
.con_form_modal_body .con_form_upload_checkbox {
  margin-top: -3px !important;
}

.con_form_upload_checkbox .ant-checkbox-inner {
  height: 20px;
  width: 20px;
}

.consent_form_upload_title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
}
.upload_consent_form .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center !important;
  right: 0 !important;
  margin: 1.75rem auto;
  width: 600px !important;
}
/* .upload_consent_form .modal-content {
  height: 100% !important;
} */
.upload_consent_form .modal-header {
  padding: 20px;
}
.cons_form_up_Pat_text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #718096;
  padding-top: 2px;
}
.c_f_p_dropdown {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}
.con_form_up_sign {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #718096;
}
.con_form_up_file_up_tit {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #718096;
}
.con-form-up-file {
  border-radius: 4px;
  margin-left: 20px;
  color: #2c7be5;
  cursor: pointer;
}

.con_form_up_doc_btn {
  background: #2c7be5;
  border-radius: 4px;
  height: 32px;
  width: 144px;
  margin-left: 20px;
}

.con_form_cancel_btn {
  background: #ecedef;
  border-radius: 4px;

  width: 87px;
  height: 32px;
}
.con-form-tab-doc-name {
  display: flex;
  align-items: center;
}
.con-form-tab-doc-name svg {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.add_edit_con_form {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #718096;
}
.add_edit_con_form_name {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: 559px;
  height: 40px;
  padding-left: 10px;
}
.add_edit_con_form_name:focus {
  outline: none;
}

.up-doc-modal .modal-dialog {
  width: 600px;
  height: 468px;
}

.ed-doc-mod-body .con_form_upload_checkbox {
  margin-top: -3px !important;
}
.add_edit_con_form_editor_title {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #718096;
  margin-top: 16px;
}
.cons_form_up_Pat_text_add_edit {
  margin-top: 16px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #718096;
  padding-top: 2px;
}
.add_edit_con_form_upload_btn {
  background: #2c7be5;
  border-radius: 4px;
  width: 144px;
  height: 32px;
}
.add_edit_con_form_cancel_btn {
  background: #ecedef;
  border-radius: 4px;
  width: 87px;
  height: 32px;
}
.con-form-table-data th {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 0px;
}

.con-form-table-data tr {
  height: 40px;
}

.img_circle {
  display: flex;
  /* height: 50px;
  width: 50px; */
  border-radius: 50%;
}
.frm-lbr-mrg {
  margin: 8px 0 10px 0;
}

.patname_first {
  font-weight: 400;
  color: #ffffff;
  font-family: "Work Sans", sans-serif;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.patname_dashboard {
  font-size: 52px !important;
}

.patname_patinfo {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
  background: #a8aebe !important;
  font-size: 52px !important;
}

.dashboard_img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #a8aebe;
}

/* jeyabalaji 02-09-2022 */
.edit-note-form-tit-1 svg {
  margin-left: 20px;
}
.edit-note-form-tit-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: normal;
  word-wrap: break-word;
}
.edit-note-form-tit-2 svg {
  margin-left: 10px;
  font-size: 10px;
}
.edit-note-form-tit-3 svg {
  margin-left: 74px;
}
/* Vanitha 02-09-22 */
.explore_widget {
  height: 26px !important;
}
.explore_widget:hover {
  height: 176px !important;
  cursor: pointer;
}
.explore-vozo-button:hover {
  /* background: #ffffff !important; */
  color: #3f8cff !important;
}
/* Vanitha 02-09-22 */
/* Vanitha 09-09-22 */
.patinfor_profpic_border {
  border-top: 1px solid #cbd5e0 !important;
}

body
  > div.fade.client-popup.cl.modal.show
  > div
  > div
  > div.patinfor_profpic_border.modal-body
  > div
  > label
  > div
  > input {
  position: absolute !important;
  width: 368px !important;
  height: 11px !important;
  margin: 6px 7px 3px 11px !important;
  padding: 122px 91px 65px 131px !important;
}
/* Vanitha 09-09-22 */

/*  Vanitha 13-09-22 */

.closebtn_patinfo {
  display: flex;
  align-items: center;
}

/*  Vanitha 13-09-22 */

/*  Vanitha 15-09-22 */

.upload_consent_form .modal-body .errmsgconsent .modal-footer {
  padding-bottom: 20px;
}

/*  Vanitha 15-09-22 */

/*  Vanitha 16-09-22 */

/*  Vanitha 16-09-22 */

.appt-block-main.schedule-comp1.consent_form {
  padding-bottom: 20px !important;
}

.patinfo_phnerrmsg {
  margin-top: -10px;
  margin-bottom: 8px;
}
.patinfo_emailerrmsg {
  margin-bottom: 2px;
  margin-left: 11px;
}
/*Ahamed 22-09-22*/
#revision-popup.modal-dialog {
  width: 870px !important;
  max-width: 870px;
}
#unlock-popup.modal-dialog {
  width: 576px !important;
  max-width: 576px;
}
/*Ahamed 22-09-22*/

/* cheran */
.sled-checkbox-pat_edu .ant-checkbox-wrapper {
  margin: 0px !important;
}
.sled-checkbox-pat_edu .ant-checkbox-wrapper .ant-checkbox-inner {
  height: 16px;
  width: 16px;
}
.sled-checkbox-pat_edu
  .ant-checkbox
  .ant-checkbox-checked
  .ant-checkbox-inner
  ::after {
  height: 1px !important;
  width: 1px !important;
}
.ant-search-pat-edu
  .ant-input-wrapper
  .ant-input-group
  .ant-input-group-addon
  .ant-btn
  .ant-btn-icon-only
  .ant-input-search-button {
  border-left: none;
}
.select-pat-edu {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06), 0px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.unselect-pat-edu {
  background: #f7f7f7;
}
.correction-pdf-view .modal-dialog {
  width: 709px !important;
  height: 829px !important;
}
.input-test[value]:nth-child(1) {
  border: 1px solid black;
}
.checkbox-top .ant-checkbox-wrapper {
  margin-top: 0px !important;
}
/* cheran */

/* Ramachandhiran Start */

.FetuM-BR-45 {
  margin-bottom: 4px;
  margin-right: 5px;
}

.FutFontSize {
  font-size: 20px;
}
.FutAlignCenter {
  align-items: center;
}
.FutTextAlignCenter {
  text-align: center;
}
.FutBackWhi {
  background-color: #ffffff;
}
.FutoverFlow {
  overflow: hidden;
}
.FutVideoShow {
  display: block;
}
.FutVideoNone {
  display: none;
}
.FutPad-BTVideo {
  padding-top: 9px;
  padding-bottom: 0px;
}

.patient-Amendments {
  margin-right: 16px;
  cursor: pointer;
}

.preview_table_Amendments {
  background-color: #ffffff;
  border: 1px solid #ebeaed;
  color: #718096;
  border-radius: 4px;
}
.preview_Amendments_icon {
  margin-right: 4px;
  margin-top: -2px;
  height: 18px;
  width: 18px;
}
.preview_Amendments_icon + span.badge.badge-pill {
  border-radius: 50%;
}
.pro-mod-top {
  background-color: #fafafa;
  padding: 16px 24px 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
}
.modal-dialog.Amendments_Container {
  width: 576px;
  display: flex;
  align-items: center;
  justify-content: center !important;
  right: 0 !important;
  min-height: calc(100% - 1rem) !important;
  margin: 1.75rem auto;
}

.Amendments_Container_Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #cbd5e0;
  padding: 20px 20px 20px 20px;
  margin-bottom: 8px;
}
.Amender_label {
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 0;
}
.Amendments_Icon {
  background-color: #f1f2f5;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.Adem_Content .table {
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.Adem_Content table thead th {
  background: #f1f5f8;
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #777984;
  padding: 12px 20px 12px 20px;
}
.Adem_Content table tbody tr {
  font-family: "Inter";
  line-height: 17px;
  color: #2e2e2e;
}
.Adem_Content table td {
  padding: 14px 0px 14px 20px;
}
.Adem_Content table tbody tr:last-child {
  border-bottom: 1px solid #cbd5e0;
}
.Adem_Add_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 9px 12px 20px;
  margin-top: 3px;
}
.Amendments_Container_Footer {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #cbd5e0;
}
.adem_Button {
  background: #ecedef;
  color: #414d55;
  border-radius: 4px;
  padding: 7px 20px 9px 20px;
  border: none;
}
/* .adem_Button:hover {
  background: #ff00aa;
  border: none;
} */
/* .badge-pill {
  background: rgb(91, 141, 211);
  color: #ffffff;
  top: -5px;
  right: -5px;
} */

/* Ramachadhiran End */

.select_dropdown_head_box {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  height: 40px;
  width: 560px;
  padding-left: 10px;
}
.select_dropdown_head_box_upload {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  height: 40px;
  width: 458px;
  padding-left: 10px;
}
.consent_dropdown_total_box_none {
  display: none;
}
.select_dropdown_head_box:focus {
  outline: none;
}
.select_dropdown_head_box_upload:focus {
  outline: none;
}
.consent_dropdown_total_box {
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 560px;
  max-height: 176px;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.consent_dropdown_inside_total_box {
  padding: 2px;
}

/*-----------cheran--26-9-22-------------*/
.ant-search-pat-edu > span > span > span > button {
  border-left: none !important;
  height: 34px !important;
}
.ralign-pedu {
  margin-left: 20px !important;
}
.tabs-homeWork {
  margin: 20px 0px 0px 0px !important;
}
.tabs-homeWork .for-pat-edu-box-tit {
  padding: 0px !important;
}
.md-homework .modal-header .close {
  margin-right: 20px !important;
}
.md-homework .modal-dialog {
  width: 505px !important;
}
.sled-checkbox-pat_edu .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 6px !important;
  height: 6px !important;
  border-radius: 4px;
}
.ui_tick .ui.checkbox input:checked ~ label:after {
  color: #2c7be5 !important;
  background-color: #fafafa;
}
.ui_tick .ui.checkbox input ~ label:before {
  border: none !important;
}
.ant-search-pat-edu .ant-input {
  height: 34px !important;
}
.ant-search-pat-edu .ant-input-search .ant-input:hover {
  border-color: #cbd5e0 !important;
  border-right: none;
}
.ant-search-pat-edu .ant-input-search .ant-input:focus {
  border-color: #cbd5e0 !important;
  border-right: none !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.ant-search-pat-edu .ant-btn:hover:hover {
  border-color: #cbd5e0 !important;
}
.resourceView .modal-dialog {
  width: 100% !important;
  border: none !important;
  max-width: 100% !important;
  height: 100% !important;
  position: fixed;
  top: 0px;
}
.resourceView .modal-content {
  background-color: rgba(0, 0, 0, 0.09) !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh !important;
  align-items: center !important;
}

.doc-edu-mdl-new .modal-dialog {
  border: none !important;
  margin-right: 24% !important;

  max-width: 700px !important;
  width: 700px !important;
}
.for-ant-patedu-web .ant-input-search .ant-input:hover {
  border-color: #cbd5e0 !important;
}

/*---------- cheran --26-9-22-------------*/
.pat-assign-Modal .modal-dialog {
  width: 510px !important;
}
.serch-hm-hover .ant-input-search .ant-input:hover {
  border-color: #cbd5e0 !important;
}
.pat_education_alt .MuiTableRow-root.MuiTableRow-head th {
  /* color: #777984 !important;
  font-family: "Work Sans", sans-serif !important; */
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
}

.reso-video .modal-dialog {
  margin-right: -1px !important;
}

/* Vanitha 15-10-22 */

.form_lib_alt {
  margin-bottom: 2px !important;
}

/* Vanitha 15-10-22 */
.labcustom {
  margin-top: 24px;
  margin-left: 0px;
  margin-right: 0px;
}
.labcustomresult {
  margin-top: 6px;
  margin-left: 0px;
  margin-right: 0px;
}

.lab_results .loading-more {
  padding-top: 10px;
  padding-bottom: 20px;
}

.problemsBottom.tab_title_main.medication-border.labcustom {
  margin-bottom: 0px !important;
}

/* Vanitha 28-10-22 */

.patflag_card_alt .card:hover {
  transform: scale(1.05) !important;
  transition: transform 1.05s ease-in-out !important;
}

.empty_data_pic_alt {
  padding: 20px !important;
  padding-top: 10px !important;
}

/* Vanitha 28-10-22 */

/* Vanitha 15-12-22 */

.imm-reacords .spinner-border {
  margin-top: 25% !important;
  margin-bottom: 25% !important;
}

.pat-right-div .med_block_alt {
  margin-bottom: 20px !important;
}

/* Vanitha 15-12-22 */

/* Vanitha 26-12-22 */

.pro_ord_errmsg {
  text-align: left;
  margin-top: -10px !important;
}

.pro_ord_err_msg_appt {
  margin-left: -20% !important;
}

.pro_ord_err_msg_prov {
  margin-left: -24% !important;
}

.pro_ord_err_msg_details {
  margin-top: 5px !important;
}

/* Vanitha 26-12-22 */
/* Vanitha 04-01-22 */

.procedure_input_alt .ui.icon input {
  margin-right: 0px !important;
}
.procedure_input_alt .transition {
  width: 100% !important;
}

.procedure_input_alt {
  padding-left: 10px !important;
}

/* /* Vanitha 04-01-22 */
/* Vanitha 02-02-23 */
.searchbox_billProf .ui.input > input {
  margin-right: 0px !important;
}
/* Vanitha 02-02-23 */
/* .mb-percent {
  margin-bottom: 15%;
} */
.patinfo_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.patient_name_patinfo {
  width: 80;
  height: 80;
  border-radius: 50%;
  background: #a8aebe;
}

.patinfo_icon_btn {
  height: 23px;
  top: 12px;
}

.patinfo_edit_icon {
  height: 16px;
  width: 16px;
  border: none;
}

.drop_toggle_alt {
  background-color: white !important;
}

.patinfo_phone_alt {
  margin-bottom: 0px !important;
}

.patinfo_inputlist_alt {
  border: none !important;
  margin-top: 2px !important;
}

.patinfo_inputlist_rowalt {
  margin-top: 0px;
  margin-left: -25px;
}

.patinfo_fomrgp_alt {
  margin: 10px 10px;
  width: 100%;
}

/* update by cheran */
.desc-patient_flag_algn {
  justify-content: space-between;
}
.flex-end-pick-color {
  display: flex;
  justify-content: flex-end;
}
.flag-name-algn-par {
  padding-left: 0px;
  display: flex;
  justify-content: flex-end;
}
/* update by cheran */

/* Vanitha 09-02-23 */

.othersamnt_val {
  min-width: 500px;
  display: flex;
}
.othersamnt_val #amountvalue {
  margin-left: 55px !important;
}
/* Vanitha 09-02-23 */

/* Vanitha 08-02-23 */
.ant-upload-drag-container1 input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer !important;
  margin: 1px 2px 1px 9px !important;
  width: 415px;
  height: 206px;
}

/* Vanitha 08-02-23 */
.block-display {
  display: block;
}
.m-b-21 {
  margin-bottom: 21px !important;
}
.p-t-0 {
  padding-top: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.view_previous_pat_list {
  width: 153px;
  height: 35px;
  border-radius: 4px;
  color: #2c7be5;
  background-color: #ffffff;
}
.add_note_pat_list {
  width: 153px;
  height: 35px;
  background: #2c7be5;
  border-radius: 4px;
}
.show_hide_status_app_dash {
  height: 16px;
  width: 22px;
}
/* Ramachandhiran Inline CSS Start */
.flagModalWidth .modal-dialog {
  width: 400px !important;
}
.flagInputs {
  width: 210px !important;
}
.problemsTableHeadSmall {
  font-weight: 600;
}
.demoError {
  border: 1px solid red;
  border-radius: 5px;
  width: 95%;
}
.demoDeleteIcon {
  color: red;
  height: 20px;
  width: 20px;
  padding-top: 3px;
  cursor: pointer;
}
.demoEmailGroup {
  margin: 0px 10px;
  width: 100%;
}
.demoBox {
  border: none !important;
  margin-top: 2;
}
.BtnmarginBottom {
  margin-bottom: 14px;
}
.errPaddingLeft {
  padding-left: 12px;
}
.demoCancelBtn {
  color: #414d55;
  background-color: #f6f7f9;
  margin-right: 20px;
}
.demoMenuItems {
  margin-left: 10;
  margin-right: 10;
  margin-top: 10;
}

.demoCardF {
  display: none;
  margin-top: 3%;
}
.demo_MarginLet {
  margin-left: -24px;
}
.appStatus {
  padding-top: 3;
  padding-left: 20;
  padding-right: 0;
}
.appPadingLeft20 {
  padding-left: 20px;
}
.ApponPaddTop8 {
  padding-top: 8px;
}
.ApponTextAlingC {
  text-align: center !important;
}
.apponDivblock {
  display: block;
}
.PatientFlagML {
  margin-left: 54%;
}
.PatFlagFlex {
  display: flex;
  width: 100%;
  margin-top: 1px;
  margin-bottom: -5px;
  margin-left: 0px;
}
.PatFlagNone {
  display: none;
  width: 100%;
  margin-top: 1px;
  margin-bottom: -5px;
  margin-left: 0px;
}
.PatFlagColor {
  width: 213;
  margin-right: 0;
  padding: 0;
  height: 22px;
}
.maxContentDiv {
  width: max-content;
  padding: 4px;
}
.PatErrMsg {
  color: red;
  margin-left: 54%;
  margin-top: -10px;
}
.PatHeight36 {
  height: 36px;
  display: flex;
}
.PatWidth120 {
  padding-left: 20px !important;
}
.PatWidth50 {
  width: 50px;
}
.PatNewErr1 {
  border: 1px solid red;
  border-radius: 4px;
  margin-left: 76;
  height: 40;
  width: 212;
}
.PatNewErr0 {
  border: 1px solid red;
  border-radius: 4px;
  margin-left: 76;
  height: 40;
  width: 212;
}
.PatHomeBtn {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ebeaed !important;
  display: flex;
  align-items: center;
}
.PatMR6 {
  margin-right: 6px;
  font-size: 15px;
}
.PatHomeWap {
  flex-wrap: wrap;
}
.PatBT {
  border-top: 1px solid #ebeaed;
}
.PatHomeDesp {
  color: #9ea0a8;
  padding-left: 0px;
  font-family: Inter;
  line-height: 16.94px;
}
.PatHomRadius {
  border-radius: 12px;
}
.PatHomUserT {
  margin-bottom: 0px;
  margin-left: 12px;
  font-family: Inter;
  line-height: 16.94px;
}
.PatHomeFloatF {
  display: flex;
  float: right;
  padding-left: 0;
}
.PatMargin20 {
  margin: 20px 20px 8px 20px;
  border-bottom: 1px solid #cbd5e0;
}
.PatModRev {
  padding: 20px;
  flex-direction: row-reverse;
}
.PatM0Auto {
  margin-top: 0;
  margin-right: auto;
}
.PatModalPat {
  padding: 0px 0px 20px 0px;
}
.PatSearch {
  border-radius: 4px;
  width: 300px;
  border-right: none;
}
.PatFilter {
  color: #545f82;
  font-size: 13px;
  margin-right: 6px;
}
.PatShowDataBase {
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: baseline;
}
.PatShowDataCenter {
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: baseline;
}
.PatShowBlock {
  display: block;
}
.PatShowNone {
  display: none;
}
.PatAppoiAva {
  padding: 20px 20px 17px 20px !important;
  border-radius: 8px;
  height: 80px;
  width: 80px;
}

/* Ramachandhiran Inline CSS End */

/* Nisha Inline CSS Start */

.pro-img-circle {
  margin-left: 20px;
}
/* Nisha Inline CSS End */
/* update by cheran */

.bg-bill1 th {
  font-size: 14px;
  font-family: Inter;
  line-height: 17px;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: none !important;
}
.in-tab-exm td {
  font-size: 14px;
  line-height: 17px;
  border-top: none;
  border-bottom: 1px solid #ebeaed;
  padding-top: 12px;
  padding-bottom: 12px;
  font-family: Inter;
}
.color-td {
  color: #2d3748;
}
.color-td1 {
  color: #718096;
}
.inv-header-pad {
  padding: 18px 16px 19px 35px;
}
.fxh-pop td {
  padding-top: 4px;
  padding-bottom: 16px;
}
.in-tab-footer {
  font-size: 14px;
  line-height: 17px;
  font-family: Inter;
  padding-top: 24px !important;
  padding-bottom: 12px !important;
  border-top: none !important;
  text-align: left;
}
.color-td2 {
  color: #000000;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fxh-pop {
  line-height: 19px;
}
@media only screen and (max-width: 1376px) and (min-width: 1200px) {
  .mb-percent {
    margin-bottom: 15%;
  }
}
/* update by cheran */
/*Ahamed_changes_08-02-23_Start*/
.pad-b-1 {
  padding-bottom: 1px !important;
}
.pat-list .app-prof-title {
  text-align: left;
  min-width: 400px;
  padding: 20px 20px 20px 20px;
  font-size: 32px;
}
.pat-list .dropdown {
  margin-top: 18px;
  display: inline-block;
  margin-left: 0px;
}
.pat-list .active-outlined {
  padding-left: 8px;
  font-size: 14px;
}
.pat-list .inactive-outlined {
  font-size: 14px;
}
.pat-list button.create-cli {
  height: 34px;
  padding-top: 4px;
  margin-right: 20px;
  border-bottom: 1px solid #0062cc;
  width: 140px;
}
.pat-list .pat_explore {
  text-align: left;
  padding-left: 140px;
}
.pointer_cursor {
  cursor: pointer;
}
.pat-list .pat_logo_frst {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.pat-list .patname_first {
  background: #a8aebe;
}
.pat-list .bot_border > td,
.for-form-lib-head1 .bot_border > td {
  text-align: center;
}
.for-form-lib-head1 .table-data-row > td {
  color: #2e2e2e;
}
.full-manage-macro .vid-play-box {
  top: 73px;
  position: fixed;
  z-index: 1001;
}
.font-int-sans {
  font-family: "Inter", sans-serif;
}
.color-nero {
  color: #242222;
}
.elec-repo .fil_txt {
  padding: 6px;
}

.elec-repo .to-dt-sel {
  color: red;
  font-size: 12px;
  margin-bottom: -20px;
}
.elec-repo .elec-rep-link {
  margin-left: 12px;
  padding-top: 4px;
}
.elec-repo .e-text-ellipsis {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.elec-repo .hypen-space {
  padding-right: 8px;
  padding-left: 7px;
  padding-top: 0px;
}
.elec-repo .elect_cal1 {
  width: 213px;
}
.elec-repo .elec-pri-btn {
  height: 32px;
  padding-top: 4px;
}
.elec-repo .loading-more {
  padding-top: 20px !important;
  padding-bottom: 0px !important;
}
.elec-repo .elec-tbl-err {
  color: red;
  margin: 10px 0px 10px 0px;
}
.alert-danger.ele-rpt-art {
  height: 80px;
  width: 500px;
}
.blue-col {
  color: blue;
}
.procedure-report-modal .sign_lab {
  width: 82px;
  margin-right: 23px;
}
.procedure-report-modal .cls_btn {
  width: 82px;
}
.ord-left-box .br-bot:nth-child(1) span:nth-child(2) {
  margin-left: 61px;
}
.ord-left-box .br-bot:nth-child(2) span:nth-child(2) {
  margin-left: 38px;
}
.ord-left-box .br-bot:nth-child(3) span:nth-child(2) {
  margin-left: 53px;
  margin-right: 37px;
}
.ord-left-box .br-bot:nth-child(4) span:nth-child(2) {
  margin-left: 42px;
}
.ord-left-box .br-side span:nth-child(2) {
  margin-left: 99px;
}
.ord-right-box .br-bot:nth-child(1) span:nth-child(2) {
  margin-left: 71px;
}
.ord-right-box .br-bot:nth-child(2) span:nth-child(2) {
  margin-left: 56px;
  margin-right: 10px;
}
.ord-right-box .br-bot:nth-child(3) span:nth-child(2) {
  margin-left: 51px;
  margin-right: 38px;
}
.ord-right-box .br-bot:nth-child(4) span:nth-child(2) {
  margin-left: 24px;
}
.ord-right-box .br-side span:nth-child(2) {
  margin-left: 30px;
}
.procedure-report-modal button.blue-col {
  background-color: rgba(32, 201, 172, 0.1);
}
#form-build.vozo_margin {
  position: relative;
}
#form_clipboard_field .spinner_main {
  top: 50%;
  left: 37%;
}
/*Ahamed_changes_08-02-23_End*/
/* preetha 20/02/23 */

.immune-control {
  font-size: 13px;
}

.btn-fam {
  font-family: "sans-serif", "work Sans";
}

.btn-fam-add {
  padding-right: 3px;
  width: 1em;
  height: 1em;
  font-size: 1.8em;
  padding-bottom: 0px;
  margin-bottom: -2px;
}

.div-proce-order {
  padding: 4px 0px 11px 0px;
  font-size: 28px;
  font-weight: 600;
}

.div-save-proce-order {
  margin: 9px 0px 10px 0px;
}

.form-proce-order {
  margin: 0px 0px 6px 0px;
}

.form-group-proce-order {
  margin-bottom: 7px;
}

.col-proce-order {
  padding: 0px 10px;
}

.erx-div {
  margin: 10px 20px 0px 0px;
}

.width-wrx-table1 {
  min-width: 105px;
}

.width-erx-table2 {
  min-width: 130px;
}
.width-erx-table3 {
  min-width: 130px;
}

.erx-style-div {
  color: #2e2c34;
  margin: 10px 0px 0px 20px;
}

.form-contain-immune {
  padding-top: 1px !important;
}

.immune-search {
  display: flex !important;
  width: 198px;
}

.form-grp-immune {
  flex-direction: column;
}

.fam-row-grp {
  margin-bottom: -6.5px;
}

.fam-observ-table {
  background-color: #3a87ad;
  color: white !important;
}

.width-phar-input {
  width: 476px !important;
}

.phar-row-margin {
  margin-bottom: 14px !important;
}

.f-phar {
  font-size: 10px !important;
}

.phar-m-l-col {
  margin-left: 31px;
}

.div-pro-sty-pad {
  padding: 10px 0px 10px 0px;
}

.pro-p-t-div {
  padding-top: 7px;
  padding-bottom: 7px;
}
.p-b-imm .ant-tabs-nav {
  padding-top: 0px !important;
}

.wid-pro-row {
  width: 82% !important;
}

/* preetha 20/02/23 */
/* jeyabalaji 21-04-2023 */
.img_emp_data {
  /* border: 1px solid black; */
  height: 133px;
  width: 133px;
}
.consen_form_card {
  margin-bottom: 10px;
}
.tab_tit_consen_form {
  width: 25%;
}
.tab_mand_consen_form {
  width: 12%;
}
.tab_ad_consen_form {
  width: 15%;
}
.tab_ac_consen_form {
  width: 23%;
}
.consen_form_edit_btn {
  border: 1px solid #2c7be5 !important;
  background: white;
  width: 83px;
  height: 32px;
}
.btn-light.consen_form_edit_btn {
  background: white;
  color: #2c7be5;
}
.btn-light.consen-archive {
  background: white;
}
.consen-archive:hover {
  background-color: rgb(249 223 223) !important;
}
.consen-archive:active {
  background-color: rgb(255 195 195) !important;
}
.consen_form_edit_img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
.c_f_cancel_btn {
  width: 83px;
  height: 32px;
  color: #718096;
}
.c_f_s_btn {
  margin-left: 10px;
  width: 83px;
  height: 32px;
  color: #2c7be5;
}
.c_f_u_d_row {
  margin-left: 0px;
  margin-bottom: 16px;
}
.c_f_u_d_d {
  display: flex;
}
.c_f_u_d_ai {
  margin-left: -30px;
  margin-top: 5px;
}
.c_f_u_d_a_s_r {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.c_f_u_d_f_r {
  margin-left: 0px;
}
.c_f_u_d_f_r_d {
  display: flex;
  margin-top: 12px;
  align-items: baseline;
  margin-left: 5px;
}

.c_f_a_e_p_r {
  margin-left: 0px;
  margin-bottom: 16px;
}
.c_f_u_d_a_e_p {
  margin-top: 0px;
}

.pat_flag_il_cs_hd .pat_flagtab_alt {
  display: flex;
}
.pat_flag_il_cs_hd .p_f_btn_s {
  height: 40px !important;
  padding-bottom: 5px;
  font-weight: 600;
}
.pat_flag_il_cs_hd .p_f_d_s {
  margin-left: 12px;
  margin-top: 15px;
}
.pat_flag_il_cs_hd .p_f_t_e_d {
  margin-left: 170px;
  margin-top: -30px;
}
.pat_flag_il_cs_hd .p_f_l_i_c {
  color: #636d73;
}
.pat_flag_il_cs_hd .p_f_c_f_c {
  margin-left: 111px;
  color: #2c7be5;
  cursor: pointer;
}
.pat_flag_il_cs_hd .p_f_io_c_s {
  padding-left: 6px;
}
.pat_flag_il_cs_hd .p_f_c_f_d {
  margin-left: 20px;
  color: #ef5f5f;
  cursor: pointer;
}
.pat_flag_il_cs_hd .p_f_icons {
  color: #2c7be5;
}
.pat_flag_il_cs_hd .p_f_img_t {
  cursor: pointer;
}
.pat_flag_il_cs_hd .p_f_ad_btn_tem {
  margin-left: 16px !important;
}
.pat_flag_il_cs_hd .p_f_pft_edit_icon {
  color: #ef5f5f;
}
.pat_flag_il_cs_hd .p_f_table_cell {
  text-align: center;
}

.pat_ed_f_d {
  padding-bottom: 0px;
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
}
.pat_ed_tit_nam {
  text-align: left;
}

.pat_ed_up_fl_sze {
  width: 25%;
  text-align: right;
}
.pat_ed_up_nw_rsrce {
  min-width: 190px;
  margin-left: 4px;
}
.patuploadbuttom {
  font-weight: 600;
  height: 33px;
}
.pat_ed_box_head_tit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pat_ed_m_t_-10 {
  margin-top: -10px;
}
.pat_ed_ff_pr_jc {
  font-family: Inter;
  padding-right: 4px;
  justify-content: space-between;
}
.pat_ed_m_r_16 {
  margin-right: 16px;
}
.pat_ed_m_t_4 {
  margin-top: 4px;
}
.pat_ed_ml_w_mt_p {
  margin-left: 24px;
  width: 80px;
  margin-top: 4px;
  padding: 5px 12px;
}
.pat_edu_m_t_20 {
  margin-top: 20px !important;
}
.pat_ed_m_l_20 {
  margin-left: 20px;
}
.pat_ed_tc_d_ml {
  display: flex;
  margin-left: 20px;
}
.pat_ed_tc_c_lh {
  color: #9ea0a8;
  line-height: 17px;
}

.pat_ed_tc_d_c_ff_lh {
  display: flex;
  color: #2d3478;
  font-family: Inter;
  line-height: 16.94px;
}
.pat_ed_tc_d_c_ff_lh img {
  margin-top: 10px;
  border-radius: 12px;
}
.pat_ed_tc_avt_df {
  display: flex;
}
.pat_ed_tc_btn_h {
  height: max-content;
}
.pat_ed_tc_btn_clr {
  color: #20c9ac;
}
.pat_ed_tc_ta_pt_pb {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.pat_ed_fr_mb {
  margin-bottom: 10px;
}
.pat_ed_fl_mr_mt {
  margin-right: 84px;
  margin-top: 0px;
}
.pat_ed_err_msg_mltb {
  margin-left: 31%;
  margin-top: -6px;
  margin-bottom: 16px !important;
}
.pat_ed_up_file_div {
  margin: 10px;
  height: max-content;
  display: flex;
  align-items: center;
  padding: 6px;
  justify-content: space-between;
}
.pat_ed_icon_span_w {
  width: 144px;
}
.pat_ed_fl_mr_a_p {
  margin-right: 22px;
}
.pat_ed_br_ddi {
  border-radius: 0px;
}
.pat_ed_up_btn_foot {
  margin-right: 13px;
}

/* jeyabalaji21-04-2023 */
/* Ramachandhiran Start */
.title_d_n_t_01 {
  margin-right: 12px;
  color: #000;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  margin-top: 12px;
}

.for_autoComplete_bi_Patient {
  padding-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
  display: flex;
  position: relative;
  width: 230px;
}
.for_autoComplete_bi_Patient svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 18px;
  height: 18px;
}
.for_autoComplete_bi_Patient .ant-select-auto-complete {
  position: absolute;
  width: 100%;
}
.for_autoComplete_bi_Patient:not(.top-nv-pat-srch) .ant-select-selector {
  padding-left: 40px !important;
  border-radius: 4px !important;
  height: 34px !important;
}
.for_autoComplete_bi_Patient input {
  border: none !important;
}
.for_autoComplete_bi_Patient
  .ant-select-selection-search:not(
    .create-client-country-code .ant-select-selection-search
  ) {
  padding-left: 28px;
}
.create-client-country-code {
  margin: 0px !important;
}

.create-client-country-code .ant-select-selector {
  height: 34px !important;
  padding-left: 12px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: none !important;
}
.top-nv-pat-srch .ant-select-selection-placeholder {
  font-size: 12px !important;
  margin-left: -8px !important;
}
.search_icon_whole .ant-select-selection-placeholder {
  font-size: 12px !important;
  margin-left: -8px !important;
}
/* saranya */

#PatId .ant-select-selection-placeholder {
  font-size: 12px !important;
  margin-left: 0px !important;
}
.sel_not_take_span input::placeholder {
  font-size: 12px;
  color: #aaa;
}
/* saranya */

.search_icon_whole1 .ant-select-selection-placeholder {
  font-size: 12px !important;
  margin-left: 0px !important;
}
.for_autoComplete_bi_Patient .ant-select-selection-placeholder {
  color: #000;
  padding-top: 1px;
}

.for_autoComplete_bi_Patient
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.pat_Stmt_tab_bot_alt .ant-tabs-tab {
  padding-top: 0px;
}

.pat_Stmt_tab_bot_alt .for_autoComplete_bi_Patient {
  padding-left: 0px !important;
}
.ant-select-dropdown-empty .ant-select-item-empty,
.ant-select-item-option .ant-select-item-option-content {
  color: #000;
  font-weight: 600;
}
#pat_stmnt_table .ant-checkbox-wrapper,
#pat_all_stmnt_table .ant-checkbox-wrapper {
  margin-left: 201px;
}
#pat_stmnt_table .form-group span,
#pat_all_stmnt_table .form-group span {
  margin-top: -3px !important;
}
.pat_pay-tbl #search-btn-hover:focus {
  border: none;
}
.pat_ledgr .for-span-pat {
  margin-left: 87px;
}
.fee-schedule-head .for_autoComplete_bi_Patient {
  padding-left: 0px !important;
  margin-right: 0px !important;
}
.for_autoComplete_bi_Patient.card-tran-srch svg {
  margin-top: 1px;
}
.for_autoComplete_bi_Patient.card-tran-srch .ant-select-selector {
  height: 34px;
  width: 100%;
}
/* Ramachandhiran End */

/* Vanitha  17-02-23*/
.lab_img {
  text-align: center !important;
}
.prb_pen {
  display: flex !important;
  margin-top: 5px;
}
.all_btn {
  margin-top: 1px;
  padding-bottom: 1px;
  display: flex;
}

.all_btn_plus {
  padding-right: 3px;
  width: 1em !important;
  height: 1em !important;
  font-size: 1.8em !important;
  padding-bottom: 0px;
}

.upl_des_tab {
  display: flex !important;
  align-items: center !important;
  padding-top: 14px !important;
}

.upl_img_tab {
  height: 28px;
  width: 28px;
  border-radius: 50%;
}
.upl_margin {
  margin: 0px 0px 0px 103px;
}
.upl_img {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}

.add_prb_fot {
  flex-wrap: nowrap !important;
}
/* Vanitha  17-02-23*/

/* vignesh 25-4-23 */

.patient-pop .modal-dialog {
  width: 400px !important;
}

/* vignesh 25-4-23 */
/* cheran */
.err-bd .ant-select-selector {
  border-color: red !important;
}
.md-width-400 .modal-dialog {
  width: 400px !important;
}
.w-211 {
  width: 211px !important;
}
.ad-contact-autocomplete {
  align-items: center;
}
.ad-contact-autocomplete svg {
  margin-top: 0px !important;
}
.ad-contact-autocomplete
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 36px;
}
.m-t-8 {
  margin-top: 8px !important;
}
.m-b-28 {
  margin-bottom: 28px !important;
}
/* cheran */
.patient-pro-data .user-image-size {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}
.pat-dashboard .pl-140 {
  padding-left: 140px;
}
.pat-dashboard .p-t-3 {
  padding-top: 3px;
}
.vitals_main .dash-vitals-block {
  padding: 0px 8px;
  text-align: left;
  width: max-content;
}
.vitals_main .dash-vitals-pad {
  display: flex;
  align-items: center;
  width: max-content;
}
.vitals_main .size-30 {
  width: 30px !important;
  height: 30px !important;
}
.pat-dashboard .dash-cursor-pointer {
  cursor: pointer;
}
.pat-dashboard .dash-insurance-style {
  max-width: 126px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.family-history-dashboard .dash-immu-code-size {
  font-size: 12px !important;
  width: 70px;
}
.pat-dashboard .f-s-12 {
  font-size: 12px !important;
}
.family-history-dashboard .dash-immu-data {
  color: #2066c2;
  font-size: 12px !important;
}
.erx_table_border {
  border-bottom: none !important;
}
/*Ahamed_05-05-23*/
.procedure-inp-box.procedure_input_alt .for_autoComplete_bi {
  padding-left: 0px !important;
  margin-right: 0px !important;
}
/*Ahamed_05-05-23*/
/* Vanitha 08-05-23*/

.Fam_his_tbl_responsive th {
  padding: 16px 0px 16px 9px !important;
}
.observation_td_resp td {
  padding: 16px 0px 16px 9px !important;
}
.fam_his_row_resp td {
  padding: 16px 0px 16px 9px !important;
}

.sec-row-fam td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.main-fam-row td {
  padding: 16px 0px 16px 9px !important;
}

.Fam_his_tbl_responsive th:last-child {
  padding-right: 20px !important;
}
.fam_obs_alt1 {
  height: 42px !important;
}
/* Vanitha 08-05-23*/
.dash-fut-apt-cell {
  padding-left: 20px !important;
}
.dash-fut-apt {
  color: rgb(44, 123, 229);
  font-weight: 500;
}
.elec-rep-table-bor:last-child {
  border-bottom: none !important;
}
.man-flex-center {
  margin-top: -4px;
}
.man-flex-center .ant-checkbox-wrapper {
  margin: 2px 0 0 0 !important;
}
.consen_form_card.card .card-header {
  margin-bottom: 0px !important;
}
/* saranya modal 16-05-23 */
.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}
.popup_centre .modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center !important;
  right: 0 !important;
  min-height: calc(100% - 4rem) !important;
  margin: 3.25rem auto;
}
.topcreate_popup_centre .modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center !important;
  right: 0 !important;
  min-height: calc(100% - 4rem) !important;
  width: 500px;
  margin: 1.75rem auto;
}
.add_new_edu_resource .modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center !important;
  right: 0 !important;
  height: 801px !important;
  width: 500px;
  margin: 1.75rem auto;
}
.payment_popup_centre .modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center !important;
  right: 0 !important;
  width: 500px;
  margin: 1.75rem auto;
}
/* saranya modal 16-05-23 */
/* nisha start 23-05-23*/
.modal-width .modal-dialog {
  width: 400px !important;
}
/* nisha end 23-05-23*/
.right-block2-m {
  margin-left: 211px !important;
}
.right-block-250-w {
  width: calc(100% - 250px) !important;
}
.lab-res-items-list .ant-checkbox-wrapper {
  margin-left: 10px !important;
}
.edit-note-acc {
  height: calc(100vh - 125px) !important;
}
.edit-note-acc .edit-note-additional {
  height: calc(100% - 120px) !important;
  overflow: auto;
}
.erx-img svg {
  float: right;
}

.erx-img div {
  text-align: initial;
}
.p-l-12 {
  padding-left: 12px;
}
.incomingfaxselect {
  width: 191px;
  cursor: pointer;
}
.incomingfaxselect1 {
  width: 120px;
  cursor: pointer;
}
.faxheight .form-control {
  height: 36px;

  border-radius: 4px;
  color: #2e2e2e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* padding: 9px 12px; */
}
.downloadfax svg {
  height: 21px;
  width: 21px;
}
.assignfax .form-control {
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(46, 46, 46, 0.25);
  background: #fff;
  color: #2e2e2e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.assignfax .incomingfaxselect {
  -webkit-appearance: none;
  background-image: url("../../assets/images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right 10px top 9px;
  line-height: 1;
  padding-right: 18px !important;
}
.faxheight .incomingfaxselect1 {
  -webkit-appearance: none;
  background-image: url("../../assets/images/arrow-down.png");
  background-repeat: no-repeat;
  background-position: right 10px top 9px;
  padding-right: 18px !important;
}
.incomingfax_padding {
  padding: 2px 23px 31px 0px;
}
div.ad-ref-date-picker .ant-picker {
  width: 100%;
  height: 28px !important;
}
.ad-ref-select-inp.ant-select-single.ant-select-show-arrow {
  width: 100%;
}
.ad-ref-select-inp input {
  margin-top: -2px;
}
.ad-ref-manage-search.for_autoComplete_bi svg {
  height: 16px;
  width: 16px;
  margin: 0px 0 0 8px !important;
}
.ad-ref-manage-search.for_autoComplete_bi {
  margin-right: 0px;
}
.ad-ref-manage-search.ad-ref-red-border.for_autoComplete_bi
  .ant-select-auto-complete {
  border: 1px solid red !important;
}
.ad-ref-manage-search.for_autoComplete_bi .ant-select-auto-complete {
  height: 30px !important;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-radius: 4px;
}
.ad-ref-manage-search.for_autoComplete_bi .ant-select-selector {
  font-size: 12px;
  height: 26px !important;
  border: none;
  padding-left: 22px !important;
}
.ad-ref-manage-search.for_autoComplete_bi .ant-select-selector input {
  height: 30px;
  /* margin-top: -3px; */
}

.ad-ref-select-inp .ant-select-selector,
.ad-ref-select-inp .ant-select-selector input {
  height: 30px !important;
}
.ad-ref-select-inp .ant-select-selection-search input {
  margin-top: -3px !important;
}
.ad-ref-select-inp .ant-select-selector {
  border: 1px solid #2e2e2e40 !important;
  border-radius: 4px;
  height: max-content !important;
}
.ad-ref-select-inp.ad-ref-red-border .ant-select-selector {
  border: 1px solid red !important;
  border-radius: 4px;
}
.ad-ref-select-inp .ant-select-selection-overflow {
  padding: 2px 38px 2px 12px;
}
.ad-ref-select-inp .ant-select-selector .ant-select-selection-placeholder,
.add-ref-mng-select-srch.ant-select-multiple .ant-select-selection-placeholder {
  color: #000;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  padding-left: 15px !important;
}
.ad-ref-manage-search.for_autoComplete_bi .ant-select-selection-search {
  padding-left: 20px !important;
}
.ad-ref-manage-search.for_autoComplete_bi .ant-select-selection-placeholder {
  padding-left: 10px !important;
}
.ad-ref-manage-search.for_autoComplete_bi,
.ad-ref-manage-search.for_autoComplete_bi .ant-select-auto-complete {
  width: 100% !important;
}
.ad-ref-type-select.ant-select,
.ad-ref-type-select.ant-select .ant-select-selector {
  height: 30px !important;
}
.ad-ref-type-select .ant-select-selector {
  padding-left: 6px !important;
}
.ad-ref-type-select.ant-select .ant-select-selector {
  border: 1px solid rgba(46, 46, 46, 0.25) !important;
}
.add-ref-mng-select-srch.ant-select-multiple {
  width: 100%;
}
.add-ref-mng-select-srch.ad-ref-red-border.ant-select-multiple
  .ant-select-selector {
  border: 1px solid red !important;
}
.add-ref-mng-select-srch.ant-select-multiple .ant-select-selector {
  border: 1px solid rgba(46, 46, 46, 0.25) !important;
  height: max-content;
}
.add-ref-error_txt {
  position: absolute;
  bottom: -20px;
  z-index: 10;
}
.refadd .ant-select-selector {
  max-height: 60px;
  overflow: auto;
}
.add-ref-error_txt18 {
  position: absolute;
  bottom: -18px;
}
.add-ref-error_txt14 {
  position: absolute;
  bottom: -14px;
}
.add-ref-mng-select-srch.ant-select-multiple
  .ant-select-selection-search
  input {
  margin-top: -3px !important;
}
.add-ref-code-drop-list
  .ant-select-item-option
  .ant-select-item-option-content {
  padding: 4px !important;
}
.ad-ref-select-inp .ant-select-selection-item {
  text-align: left;
  font-size: 12px;
  color: #2e2e2e;
  font-family: "Open Sans", sans-serif;
}
svg.add-ref-modal-close {
  cursor: pointer;
}

/* Ref mangement style */

.Rf_Modal.modal-body {
  width: 82vw;
  margin: 0 auto;
  height: 90vh;
  padding: 2px 0px 12px 0px;
}
h1.ref-manage-preview-active {
  color: #005fbe !important;
}
.referral_modal .modal-header {
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.ref-man-pre-transmit.ant-select-single {
  width: 100px !important;
}

.ref-man-pre-transmit.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #2c7be5;
}

.tras-drop-arr.ant-select-arrow {
  color: #000;
  font-size: 9px;
  top: 53%;
}

.ref-man-pre-transmit span.ant-select-selection-search,
span.ant-select-selection-item {
  padding-left: 0px !important;
  color: #2e2e2e !important;
}
/* search change ref */

.Ref-drop_style.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 30px;
  padding: 0 11px;
}

.ref_cont_search .for_autoComplete_bi_Patient {
  padding-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 4px;
  display: flex;
  position: relative;
  width: 242px;
}

.ref_cont_search
  .for_autoComplete_bi_Patient:not(.top-nv-pat-srch)
  .ant-select-selector {
  height: 30px !important;
  padding-left: 30px !important;
}

.ref_cont_search .for_autoComplete_bi_Patient svg {
  margin: 8px 0px 0px 7px;
}
.ref_cont_search
  .for_autoComplete_bi_Patient
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-search {
  left: 2px;
}

/* Ref mangement style */

/* Ahamed_changes */
.send-fx-ml-inp {
  padding: 6px 0 6px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}
.send-fx-ml-redstar {
  color: #e23d28 !important;
  font-size: 14px !important;
  padding-left: 0px !important;
}
.send-fx-ml-mod-cls {
  outline: none;
  border: none;
  border-radius: 50%;
}
.send-fx-ml-mod-cls svg {
  margin-top: -3px;
}
.sendMlFax span.ant-select-selection-placeholder {
  padding-left: 0px !important;
}
.Rf_Modal.overY {
  overflow-y: auto;
}
.referral_modal.ref-in-out > #fullscreen-popup .modal-content {
  height: 100% !important;
}
.referral_modal.ref-in-out > #fullscreen-popup {
  margin: unset !important;
  width: 100% !important;
}

.referral_modal.ref-in-out > #fullscreen-popup .Rf_Modal.modal-body {
  width: 80%;
}
@media only screen and (max-width: 1130px) {
  .referral_modal.ref-in-out > #fullscreen-popup .Rf_Modal.modal-body {
    width: 90% !important;
  }
}
.referral_modal.ref-in-out > #fullscreen-popup .modal-header {
  padding-right: 0;
  width: 102%;
}
.referral_modal.ref-in-out {
  padding-left: 0 !important;
}

/* Ahamed_changes */
.ad-ref-md-bd.Rf_Modal.modal-body {
  margin-bottom: 20px;
}

/* Nisha_changes */
.ref_InOut_search .for_autoComplete_bi_Patient {
  padding-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 4px;
  display: flex;
  position: relative;
  width: 160px;
}

.ref_InOut_search .for_autoComplete_bi_Patient svg {
  z-index: 1;
  margin: 8px 0px 0px 10px;
  width: 16px;
  height: 16px;
}

.ref_InOut_search
  .for_autoComplete_bi_Patient:not(.top-nv-pat-srch)
  .ant-select-selector {
  height: 29px !important;
  padding-left: 30px !important;
}
/* Nisha_changes */
.ad-ref-select-inp.ant-select .ant-select-arrow {
  color: #2e2e2e;
}
.ad-ref-insurance-block svg {
  position: absolute;
  right: 8px;
  top: 8px;
}
/* saranya 20-09-23*/

.ins-info-tab .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.ins-info-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #fff !important;
  background: #2c7be5 !important;
  border-radius: 4px 0px 0px 4px;
}
#demographics
  .ins-info-tab
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff !important;
  font-weight: 600;
  font-size: 14px;
}
.ins-info-tab .ant-tabs-tab {
  margin-left: 0px;
}

.ins-info-tab .ant-tabs-tab-btn {
  color: #2e2e2e !important;
  font-weight: 600;
  font-size: 14px;
}
.ins-info-tab
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 0px !important;
}
.ins-info-tab .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-bottom: none !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.pat-block-main .ins-info-tab .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  padding-bottom: 0px !important;
  padding-top: 9px !important;
  margin-bottom: 0px;
}

.pat-block-main #ins-info-tab-add .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  padding-left: 23px;
  padding-right: 21px;
  padding-bottom: 0px !important;
  padding-top: 5px !important;
  margin-bottom: 0px;
}

#ins-info-tab-add .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  width: 160px;
  margin: 0;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-bottom: 1px solid rgba(46, 46, 46, 0.25) !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ins_form .form-control {
  height: 30px;
}
.ins_form .arrow-adj {
  background-position: right 8px top 6px;
}

.ins_card .ant-upload-drag-container1 input[type="file"] {
  width: 232px;
  height: 80px;
}
.ins_card p.ant-upload-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-top: 10px;
}
.ins_backcard p.ant-upload-text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  margin-top: 10px;
}
.ins_backcard .ant-upload-drag-container1 input[type="file"] {
  width: 232px;
  height: 80px;
}
.ins_backcard .col {
  padding-right: 20px;
}
.ins_card .filelabel {
  width: 100%;
}
.ins_backcard .filelabel {
  margin-left: 16px;
  width: 99%;
}
.relation_ins_form .form-group {
  margin-bottom: 0px;
}

.pat-block-main .pat-insurance {
  padding-left: -15px !important;
  padding-right: -15px !important;
}
/* saranya 20-09-23 */
/* Saranya 28-09-23*/

.save_active:active {
  background-color: rgba(44, 123, 229, 0.75) !important;
}
.save_active:disabled {
  background-color: rgba(44, 123, 229, 0.5) !important;
  cursor: not-allowed;
}
.close_active:active {
  background-color: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.close_active:disabled {
  color: rgba(44, 123, 229, 0.5) !important;
  background-color: #fff !important;
  cursor: not-allowed;
}
.save_active:hover {
  background-color: #005fb2 !important;
}
.close_active:hover {
  background-color: rgba(244, 246, 249, 0.75) !important;
  border: 1px solid #2c7be5;
  color: #2c7be5 !important;
}

.save_active.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff !important;
  background-color: rgba(44, 123, 229, 0.75) !important;
  border: rgba(44, 123, 229, 0.75) !important;
}
.close_active.btn-primary:not(:disabled):not(.disabled):active {
  background-color: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.modal-footer .close_active1 {
  width: 79px;
  height: 32px;
  border: 1px solid #2c7be5;
  background: #fff;
  color: #2c7be5;
}

.close_active1 {
  width: 79px;
  height: 32px;
  border: 1px solid #2c7be5;
  background: #fff;
  color: #2c7be5;
  font-weight: 500;
}
.close_active2 {
  border: 1px solid #2c7be5;
  background: #fff;
  color: #2c7be5;
  font-weight: 500;
}
.pat_add_mob_btn {
  border: 1px solid #2c7be5;
  background-color: white;
  color: #2c7be5;
}
.pat_add_mob_btn:hover {
  background-color: rgba(244, 246, 249, 0.75) !important;
  border: 1px solid #2c7be5;
  color: #2c7be5;
}
.pat_add_mob_btn:active {
  background-color: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.fut_app_close_btn .btn-light:focus {
  border: 1px solid #2c7be5;
  background: #fff;
  color: #2c7be5;
}
.save_active.btn-primary {
  background-color: #2c7be5;
}
.close_active.btn-primary:focus {
  border: 1px solid #2c7be5;
  background: rgba(44, 123, 229, 0.1);
  color: #2c7be5;
}
.app_det_close_active {
  border: 1px solid #2c7be5;
  border-radius: 4px !important;
  color: #2c7be5 !important;
  background-color: #fff !important;
}

.ndc_focus.btn-light:focus {
  background-color: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5;
  color: #2c7be5 !important;
}
.close_active.btn-light:not(:disabled):not(.disabled):hover {
  background-color: rgba(244, 246, 249, 0.75) !important;
  border: 1px solid #2c7be5;
  color: #2c7be5 !important;
}
.edit_conf_border .modal-content {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
/* Saranya 28-09-23*/
/* nisha change 25-09-2023 */
.save_active.btn-primary:hover {
  background-color: #005fb2 !important;
}
.save_active.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: rgba(44, 123, 229, 0.75) !important;
  border-color: rgba(44, 123, 229, 0.75) !important;
}
/* nisha change 25-09-2023 */
/* nisha 29-09-23 */
.add-ref-mng-select-srch.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f1f5f8 !important;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding-inline-end: 4px;
}
/* nisha 29-09-23 */
.ref_InOut_search .for_autoComplete_bi_Patient .ant-select-selection-search {
  padding-left: 19px;
}
.ref_InOut_search .for_autoComplete_bi_Patient .ant-select-selection-search {
  padding-left: 19px;
}
.referal_custom_select {
  position: relative;
}

.referal_custom_select .ant-select-selector {
  padding-right: 30px;
  padding-left: 10px;
}

.referal_custom_select .referal_search_icon {
  position: absolute;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
}

.ant-select-selector .ant-select-selector .ant-select-selection-overflow {
  padding-left: 10px;
}

.referal_custom_select .ant-select-selection-overflow {
  padding-left: 10px;
}
/* nisha  05-10-2023*/

.tras-drop-arr.ref-transmit-2.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #2c7be5 !important;
}

.tras-drop-arr.ref-transmit-2.ant-select-single .ant-select-selector {
  border-color: none !important;
  outline: none !important;
  background-color: #fff !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}

.tras-drop-arr.ref-transmit-2.ant-select-single .ant-select-selector :hover {
  background-color: #fff !important;
}
.tras-drop-arr.ref-transmit-2 .ant-select-selection-item {
  color: #2c7be5 !important;
}

#fullscreen-popup .ref-transmit-2 span {
  padding: 0px !important;
  color: #2c7be5 !important;
}

.ref-transmit-2.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 102px;
  height: 34px;
  padding: 0 11px;
}

.Ref-drop_style .ant-select-selection-placeholder {
  overflow: hidden;
  color: #000 !important;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

/* nisha  05-10-2023*/
/* saranya  10-10-2023*/
.insurance_tooltip1 .ant-tooltip-inner {
  color: black;
  background-color: white;
  cursor: pointer;
}

.insurance_tooltip1 .ant-tooltip-arrow {
  left: 50% !important;
  transform: translateX(-50%);
}
/* saranya  10-10-2023*/

.insurance_front_and_back_pat {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 210px;
  display: block;
}

/* Vanitha 16-11-23 */

.min_height_alt .modal-dialog-centered {
  min-height: calc(100% - 4rem) !important;
}

div:is(.problemPopup) {
  position: absolute !important;
  transform: translate(247px, -375px) !important;
}

div:is(.problemPopup1) {
  position: absolute !important;
  transform: translate(247px, -520px) !important;
}

div:is(.problemPopup2) {
  position: absolute !important;
  transform: translate(230px, -460px) !important;
}

div:is(.problemPopup3) {
  position: absolute !important;
  transform: translate(230px, -410px) !important;
}

div:is(.problemPopup4) {
  position: absolute !important;
  transform: translate(230px, -350px) !important;
}

div:is(.problemPopup5) {
  position: absolute !important;
  transform: translate(230px, -295px) !important;
}
/* emptypic */
/* transform: translate(0px, -46px) !important; */
/* 1 row */
/* transform: translate(0px, -176px) !important; */
/* 2 row */
/* transform: translate(0px, -136px) !important; */
/* 3 row */
/* transform: translate(0px, -76px) !important; */
/* 4 row */
/* transform: translate(0px, -16px) !important; */
/* 5 row */
/* transform: translate(0px, 46px) !important; */

div:is(.allergyPopup) {
  position: absolute !important;
  transform: translate(0px, -46px) !important;
}
div:is(.allergyPopup1) {
  position: absolute !important;
  transform: translate(0px, -176px) !important;
}

div:is(.allergyPopup2) {
  position: absolute !important;
  transform: translate(0px, -136px) !important;
}

div:is(.allergyPopup3) {
  position: absolute !important;
  transform: translate(0px, -76px) !important;
}

div:is(.allergyPopup4) {
  position: absolute !important;
  transform: translate(0px, -16px) !important;
}

div:is(.allergyPopup5) {
  position: absolute !important;
  transform: translate(0px, 46px) !important;
}

div:is(.allergyPopup1) {
  position: absolute !important;
  transform: translate(0px, -176px) !important;
}

div:is(.allergyPopup2) {
  position: absolute !important;
  transform: translate(0px, -136px) !important;
}

div:is(.allergyPopup3) {
  position: absolute !important;
  transform: translate(0px, -76px) !important;
}

div:is(.allergyPopup4) {
  position: absolute !important;
  transform: translate(0px, -16px) !important;
}

div:is(.allergyPopup5) {
  position: absolute !important;
  transform: translate(0px, 46px) !important;
}

div:is(.newApptPopup) {
  position: absolute !important;
  transform: translate(-357px, -60px) !important;
}

div:is(.telePopup) {
  position: absolute !important;
  transform: translate(-357px, -60px) !important;
}

div:is(.addServicePopup) {
  position: absolute !important;
  transform: translate(-4px, -37px) !important;
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  div:is(.addServicePopup) {
    transform: translate(-178px, -37px) !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1499px) {
  div:is(.addServicePopup) {
    transform: translate(-8px, -37px) !important;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1599px) {
  div:is(.addServicePopup) {
    transform: translate(68px, -37px) !important;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1699px) {
  div:is(.addServicePopup) {
    transform: translate(116px, -37px) !important;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1900px) {
  div:is(.addServicePopup) {
    transform: translate(170px, -37px) !important;
  }
}

@media screen and (min-width: 1901px) and (max-width: 2100px) {
  div:is(.addServicePopup) {
    transform: translate(270px, -37px) !important;
  }
}

@media screen and (min-width: 2101px) and (max-width: 2300px) {
  div:is(.addServicePopup) {
    transform: translate(370px, -37px) !important;
  }
}

@media screen and (min-width: 2301px) and (max-width: 2450px) {
  div:is(.addServicePopup) {
    transform: translate(470px, -37px) !important;
  }
}

@media screen and (min-width: 2451px) and (max-width: 2750px) {
  div:is(.addServicePopup) {
    transform: translate(550px, -37px) !important;
  }
}

div:is(.userMenuPopup) {
  /* position: absolute !important; */
  transform: translate(-6px, 30px) !important;
}

div:is(.addStaffPopup) {
  transform: translate(-140px, 121px) !important;
}

div:is(.rolesPopup) {
  transform: translate(-104px, 22px) !important;
}
/* Vanitha 16-11-23 */

.prodProceAlt .ant-select-selection-item {
  display: flex !important;
}

.apptele_minwidth {
  min-width: 100%;
}
.app_minwidth {
  min-width: 90%;
}
.imp_text .ant-select-selection-item {
  text-align: left;
}
.imm_comp {
  resize: none;
}
.m-t-4 {
  margin-top: 4px;
}
.sidenav_demog_width {
  width: calc(100% - 80px);
}
.m-l-80 {
  margin-left: 80px;
}
.note_taking_btm {
  height: max-content;
  padding-bottom: 16px;
}
.problems_pad_mar {
  margin-top: -4px;
  height: max-content;
  padding-bottom: 6px;
}
/* vignesh sidenav styles */
.patbgcolor {
  background-color: #f7f7f7 !important;
}
/* vignesh sidenav styles */

.zIndex10 {
  z-index: 10 !important;
}

.appt-block-main .eob_tab_date_picker .ant-picker span {
  display: grid !important;
  place-content: center !important;
}
/*  vigneshmacromodalfix*/
.mar .modal-dialog {
  margin: 0px !important;
}

.bg-position {
  background-position: right -1px top 1px !important;
}

/*  vigneshmacromodalfix*/

.billdropdown {
  width: 339px;
  height: 32px;
  margin-left: 15px;
  padding: 5px;
  margin-bottom: 25px;
  text-align: left;
}
.billdropdown .ant-select-arrow {
  top: 68%;
  right: 20px;
}
.m-t--9 {
  margin-top: -9px !important;
}

.add-ref-error_txt19 {
  position: absolute;
  bottom: -18px;
}
@media only screen and (max-width: 840px) {
  .add-ref-error_txt19 {
    bottom: -36px;
  }
}
.phone_val .phone_val1 {
  margin-bottom: 5px;
  margin-top: 10px;
}
.val1email {
  margin-bottom: 5px;
}
.email_val {
  margin-top: 0px;
  margin-left: -25px;
}
.p-l-10 {
  padding-left: 10px;
}
/*  vigneshreferalpopup-date*/

div.referalpopDate .ant-picker {
  width: 100%;
  height: 30px !important;
}
div.referalpopDate .ant-picker-suffix {
  color: rgba(0, 0, 0, 1) !important;
}
/*  vigneshreferalpopup-date*/

.p-t-5 {
  padding-top: 5px !important;
}
.m-t--8 {
  margin-top: -8px !important;
}
.diag-code-inp-width {
  width: 100px;
}
.diag-code-inp-width.ant-select,
.diag-code-inp-width.ant-select .ant-select-selector {
  height: 34px !important;
}
.diag-code-inp-width .ant-select-selector {
  padding-left: 6px !important;
}
.diag-code-inp-width.ant-select .ant-select-selector {
  border: 1px solid rgba(46, 46, 46, 0.25) !important;
}
.proc-order-diag-width {
  width: 93%;
}

.tag_dd_doc {
  width: 200px;
  height: 36px;
  cursor: pointer;
  margin-right: 24px;
}
.err_tag_doc {
  text-align: left;
}
.edit-consent-editor-popup .modal-dialog {
  width: 916px;
  height: max-content;
}
.consent-form-titlename {
  font-size: 14px;
  font-family: "Open sans", sans-serif;
  max-width: 93%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.toolbarClassName .rdw-option-wrapper,
.rdw-dropdown-wrapper {
  background-color: transparent !important;
}
.rdw-inline-wrapper,
.rdw-text-align-wrapper,
.rdw-list-wrapper,
.rdw-block-wrapper {
  border-right: 1px solid rgba(46, 46, 46, 0.5);
}
.template-margin .DraftEditor-root {
  margin-left: 25px;
}
.macro-btn-item1 {
  color: #000;
  margin: 2px 15px;
  border-color: #fff;
  background-color: #fff;
}
.macro-btn-item2 .btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active
  > .top-pat-dropdown.btn-primary.dropdown-toggle {
  color: #000 !important;
  margin: 2px 15px;
  border-color: #fff !important;
  background-color: #fff !important;
}
.macro-btn-item2 .btn-primary:hover {
  color: #000 !important;
  margin: 2px 15px;
  border-color: #fff !important;
  background-color: #fff !important;
}
.make_width {
  width: 18% !important;
  padding: 1.75rem 0 0.75rem 0.75rem !important;
}

.edit_note .main_column_vozo {
  margin-top: 8px;
}

.edit_note .form-check-label {
  margin-bottom: 0px !important;
}
.mr-m3 {
  margin-right: -3px !important;
}
.m-l-43 {
  margin-left: 43% !important;
}
.is-disabled .selection {
  cursor: not-allowed !important;
}
.app-popup.hp.new_appt:not(.new-appt-modal-height) .modal-content {
  margin-top: 100px;
}
.for_jodit_editor .jodit-workplace {
  height: 350px !important;
  overflow: auto !important;
}
.prov_pat_name_div_full {
  display: flex;
  align-items: baseline;
}
.circle_presc {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.circle_presc_can_bg {
  background-color: #eb173e;
}
.circle_presc_ord_bg {
  background-color: #187a16;
}
.circle_presc_txt_cancel {
  color: #eb173e;
}
.circle_presc_txt_order {
  color: #187a16;
}
.justify-start {
  justify-content: flex-start !important;
}

.record_summary_text {
  font-family: var(--font-work-sans);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #27971d;
}

.record_time_text {
  font-family: var(--font-work-sans);
  font-weight: 500;
  font-size: 22px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #000000;
}
.text_container {
  text-align: center;
  margin: 10px 0;
  margin-top: 15px;
}

.text_paused {
  font-family: var(--font-work-sans);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #747474;
  margin-top: 15px;
}

.record_image_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-top: 0;
  /* padding-bottom: 20px; */
  margin-top: -10px;
}
.record_image {
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.record_image1 {
  height: 35px;
  width: 35px;
  cursor: pointer;
  margin-top: 15px;
}

.edit-note-nav-title-AI {
  font-size: 15px;
  font-weight: 500;
  font-family: var(--font-work-sans);
  color: #2d3748;
  margin: 4px 0 0 28px;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.aicharting .btn-primary.disabled {
  border: none !important;
}
.cli_nav_right {
  width: calc(100% - 210px) !important;
  padding-bottom: 1px !important;
  margin-left: 210px !important;
}
.cli_nav_right1 {
  width: calc(100% - 80px) !important;
  padding-bottom: 1px !important;
  margin-left: 80px !important;
}

.aicharting.btn-primary:not(:disabled):not(.disabled):active,
.aicharting.btn-primary:not(:disabled):not(.disabled).active,
.aicharting.show > .btn-primary.dropdown-toggle {
  background-color: white !important;
  border-color: white !important;
}

.wid_562 .modal-dialog-centered {
  width: 562px !important;
  max-width: 562px;
}
.vitals_main1 {
  margin-top: 0px !important;
  margin-bottom: 16px !important;
}
.appoint-main1 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.appt-block-main2 {
  margin-top: 0px !important;
  margin-left: 20px;
}
.imm-main-block1 {
  margin-top: 0px !important;
  margin-left: 20px;
}
.patname_dashboard1 {
  font-size: 30px !important;
}
.m-w-84 {
  max-width: 84%;
}
.p-r-5 {
  padding-left: 5px;
}

.p-l-5 {
  padding-right: 5px;
}
.vitals_explore {
  transform: translate(35%, -176%) !important;
}
.appointment_explore {
  transform: translate(70%, -174%) !important;
}
.user_explore {
  transform: translate(0, 380px) !important;
}
.roles_explore {
  transform: translate(-20%, 270px) !important;
}
.top-nav-pat-search.mobcountry_radius {
  border-top-right-radius: 0%;
}
.aicharting.btn-primary {
  background-color: white !important;
  border-color: white !important;
  border: none !important;
}

.aicharting.btn-primary:not(:disabled):not(.disabled):active,
.aicharting.btn-primary:not(:disabled):not(.disabled).active,
.aicharting.show > .btn-primary.dropdown-toggle {
  background-color: white !important;
  border-color: white !important;
}
.top_pat_search .ant-select-dropdown-empty .ant-select-item-empty,
.ant-select-item-option .ant-select-item-option-content {
  color: #000 !important;
  font-weight: 400;
}
.health_audiofile .modal-header {
  border-bottom: none !important;
}

.health_audiofile .modal-body {
  padding: 0px !important;
  padding-bottom: 20px !important;
}

.health_audiofile .modal-dialog-centered {
  max-width: 1080px !important;
}

.health_audiofile audio {
  width: 400px !important;
}

.font-size-16px {
  font-size: 16px !important;
}

.health_audiofile thead th tr {
  font-weight: 600 !important;
}

.health_audiofile .modal-body .Tabs_Tabpane {
  margin-top: 0px !important;
}

/* .audio_small_modal .modal-body {
  padding-top: 0px !important;
} */
.audio_small_modal .modal-header {
  position: absolute;
  z-index: 1;
  border-bottom: none !important;
}

.audio_small_modal .modal-dialog {
  width: 240px;
}

.health_audiofile .btn-danger,
.health_audiofile .btn-danger:active,
.health_audiofile .btn-danger:hover {
  background: #fff !important;
  color: #dc3545 !important;
}

.custom-file-upload {
  height: 135px !important;
}

.upload_audio_popup .modal-content {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
}

.upload_audio_popup .ant-upload-drag-container input[type="file"] {
  margin: 8px 6px 15px 14px !important;
}

.health_audiofile .Tabs_Tabpane {
  max-height: 396px;
  overflow-y: auto;
}

.search_icon_whole .ant-select-selection-search {
  padding-left: 21px !important;
}
.search_icon_whole1 .ant-select-selection-search {
  padding-left: 18px !important;
}
.pat_search_width {
  width: 242px !important;
}
.w-242 {
  width: 242px !important;
}
.reportWrapBtom .w-242 {
  width: 242px !important;
}

.global_popup_ai {
  position: fixed;
  top: 35%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  width: 240px;
  border-radius: 8px;
  height: 180px;
}
.global_popup_ai .close_icon {
  height: 20px;
  width: 20px;
  margin-top: 10px;
}

.upload_audio_popup .file-icon-bg {
  font-size: 12px !important;
}

.upload_audio_popup .up-d-progress_bar {
  margin-top: 4px !important;
}

.upload_audio_popup .red-col {
  font-size: 12px;
  display: flex;
}
.education_search {
  width: 70% !important;
  margin-left: 21px;
  margin-bottom: 20px;
}
.f-s-12 {
  font-size: 12px !important;
}
.ant-search-pat-edu1
  .ant-input-group-addon
  .ant-input-search-button
  .anticon
  svg {
  margin-top: -8px;
}

.edu-modal-body {
  padding: 20px;
  overflow-y: auto;
  height: 552px;
  overflow-x: hidden;
}
.edu-modal-body::-webkit-scrollbar {
  display: none;
}
.text_label_5 {
  color: #718096;
  font-size: 14px;
  line-height: 17px;
  margin-top: 8px;
}
/* saranya */
.cursor-pointer {
  cursor: pointer !important;
}
.pat-demo-contact-btn {
  margin-left: auto;
  width: max-content;
  display: block;
  margin-bottom: 20px;
}

.mr_reverse12 {
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 12px;
}
.viewMoreBtn {
  color: #2c7be5;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}
.erx-background-gap-fix{
  margin-bottom: 14px !important;
  padding-bottom: 0px !important;
}
iframe.drx-iframe-border{
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #cbd5e0;
  width: 100%;
  height: 100%;
}
.h-100{
  height: 100% !important;
}
.drx-iframe-div{
  height: calc(100% - 62px) !important;
}
.billing_without_enc {
  border: 1px solid rgba(46, 46, 46, 0.25);
  /* height: 750px; */
  height:auto;
  width: auto;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
}
.Billing-Heading {
  color: #2c7be5;
  font-family: "Open Sans";
  font-weight: 600;
  size: 18px;
}

.height-750{
  height:750px !important;
}

.dropdown-enclist .dropdown-menu{
  left: -94% !important;
  transform: translate(-100px, 36px) !important;
  box-shadow: 0px 1px 8px rgb(255 255 255) !important;
}

.dropdown-enclist .modal-dialog{
  margin: 12px !important;
  width: 155px !important;
}

.dropdown-enclist .modal-content span{
  padding-bottom: 3px;
  cursor: pointer;
}

.up-d-mar-t-6{
  margin-top: 6px;
}
.vieweobpopup_height{
  height: 280px;
  overflow-y: scroll;
}

.eobdisabled {
  color: gray;
  cursor: not-allowed !important;
}

.billing_without_enc .ant-select {
  width: 110px !important;
}
.billing_without_enc
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 26px;
  align-items: center;
}