.App {
  text-align: center;
  height: 100%;
}
#root {
  height: 100%;
}

input[type="color"] {
  -webkit-appearance: none;
  border: none;
  height: 32px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

/* .app-body {
height: 100%;
} */
.modal-backdrop.show {
  opacity: 0.3 !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-20 {
  margin-top: 20px;
}

.m-l-25 {
  margin-left: 25px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.app-body {
  display: flex;
  width: 100%;
  padding-top: 61px;
}

.leftnav {
  width: 250px;
  height: 100%;
  position: fixed;
}
.leftnav a {
  text-align: left;
}

.schedule-title {
  font-size: 20px;
  color: black;
  font-weight: 500;
  margin-left: 15px;
  text-align: left;
}

.schedule-menu {
  font-size: 16px;
  color: #718096;
  font-weight: 500;
  margin-left: 25px;
  padding-top: 20px;
}
.grey-3 {
  color: #718096 !important;
}
.leftnav .active {
  color: #2c7be5 !important;
  font-weight: 600;
  font-size: 16px;
}
.right-block {
  width: 100%;
}
.right-heading {
  font-size: 24px;
  text-align: left;
}

.m-t-25 {
  margin-top: 25px;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px;
}
.p-t-10 {
  padding-top: 10px !important;
}
.w-100 {
  width: 100% !important;
}
.f-s-20 {
  font-size: 20px !important;
}
.f-s-16 {
  font-size: 16px !important;
}
.w-50 {
  width: 50%;
}

.m-t-33 {
  margin-top: 33px;
}

.w-99 {
  width: 99%;
}

.m-l-20 {
  margin-left: 20px;
}

.p-l-r-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-l-r-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.m-t-29 {
  margin-top: 29px;
}

.m-r-25 {
  margin-right: 25px;
}
.m-l-250 {
  margin-left: 250px;
}

.dis-grid {
  display: grid;
}
.m-b-10 {
  margin-bottom: 10px;
}

.red {
  color: #ef5f5f;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-16 {
  margin-bottom: 10px !important;
  font-size: 14px;
}
.ant-dropdown-menu {
  border-radius: 5px !important;
}
.ant-dropdown-menu-item
  ant-dropdown-menu-item-only-child
  ant-dropdown-menu-item-active {
  color: red;
}

.m-t-15 {
  margin-top: 15px !important;
}
.m-l-r--24 {
  margin-left: -24px;
  margin-right: -24px;
}

.t-a-c {
  text-align: center !important;
}

.t-a-r {
  text-align: right !important;
}

.m-l-10 {
  margin-left: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.w-130 {
  width: 130px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-t--3 {
  margin-top: -3px !important;
}

.m-l-5 {
  margin-left: 5px;
}

.f-w-500 {
  font-weight: 500;
}

.m-r-12 {
  margin-right: 12px;
}

.v-blue {
  color: #2066c2;
}

.w-o-b-b {
  background-color: #ecedef;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 1px solid #cbd5e0 !important;
}

.m-t-8 {
  margin-top: 8px;
}

.m-l-au {
  margin-left: auto;
}
.m-r-au {
  margin-right: auto;
}
.m-r-20 {
  margin-right: 20px;
}
.m-l-r--29 {
  margin-left: -29px;
  margin-right: -29px;
}

.m-l-r--20 {
  margin-left: -20px;
  margin-right: -20px;
}
.m-t--13 {
  margin-top: -13px;
}

.grey-color {
  color: #718096 !important;
}

.t-a-l {
  text-align: left;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-34 {
  margin-top: 34px;
}

.btn-link {
  color: #2066c2;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.m-l-15 {
  margin-left: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cbd5e0 !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cbd5e0 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cbd5e0 !important;
}

.icon-align-btn svg {
  margin-top: -5px;
  margin-right: 5px;
}

.h-36 {
  height: 36px;
}

.p-l-r-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-t-5 {
  padding-top: 5px;
}

.w-90px {
  width: 90px;
}

.m-b-6 {
  margin-bottom: 6px;
}

.ant-input-group-addon button {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.h-34 {
  height: 34px;
}
.h-500 {
  height: 500px;
}
.hover-bg-color {
  background-color: #fafafa !important;
}

.hover-btn {
  color: #2d3748;
  background-color: #fafafa;
  border: 1px solid #cbd5e0;
}

.vozo-green {
  background-color: #57bf7f !important;
  border: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.spl-text {
  color: #747474;
  font-size: 12px;
}

.m-t-27 {
  margin-top: 27px;
}

.h-w-10 {
  height: 10px;
  width: 10px;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-r-10 {
  margin-right: 10px;
}

.blue-color {
  color: #2c7be5 !important;
}
.opa-5 {
  opacity: 0.5 !important;
}

.p-l-25 {
  padding-left: 25px;
}

.m-l-25 {
  margin-left: 25px;
}

.p-r-50 {
  padding-right: 50px;
}

.m-l-r-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.m-w-30 {
  max-width: 30px;
}

.italic-text {
  font-style: italic;
  color: #747474;
}

.p-b-15 {
  padding-bottom: 15px;
}

.yes-no-radio label {
  border: 0px !important;
  background-color: #f7f8fa;
}
.yes-no-radio .ant-radio-button-wrapper-checked {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 4px;
  border-color: white !important;
}

.yes-no-radio .ant-radio-button-checked {
  background: #ffffff;
  border-radius: 4px;
  border-color: white !important;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  width: 0px !important;
}
.yes-no-radio .ant-radio-button-wrapper:not(:first-child)::before {
  width: 0px !important;
}

.yes-no-radio
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: white !important;
}

.yes-no-radio
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  border-color: white !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.h-48 {
  height: 48px;
}

.f-w-400 {
  font-weight: 400;
}

.edit-email {
  color: #2c7be5;
}

.m-l-r--15 {
  margin-left: -15px;
  margin-right: -15px;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.w-350 {
  width: 350px;
}

.w-450 {
  width: 450px;
}

.p-l-35 {
  padding-left: 35px;
}

.pick-color-1 {
  background-color: #708693 !important;
  border: 0px;
}

.pick-color-2 {
  background-color: #524ca5 !important;
  border: 0px;
}

.pick-color-3 {
  background-color: #51b4e1 !important;
  border: 0px;
}

.pick-color-4 {
  background-color: #10995a !important;
  border: 0px;
}

.pick-color-5 {
  background-color: #ffc117 !important;
  border: 0px;
}

.pick-color-6 {
  background-color: #ff9500 !important;
  border: 0px;
}

.pick-color-7 {
  background-color: #bb2e12 !important;
  border: 0px;
}

.pick-color-8 {
  background-color: #ff7a5c !important;
  border: 0px;
}

.h-300 {
  height: 300px !important;
}

.onclick-curser {
  cursor: pointer;
}

.m-t--1 {
  margin-top: -1px !important;
}

.p-l-r-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.flex-reverse input {
  flex-direction: row-reverse !important;
}

.w-80px {
  width: 80px !important;
}

.w-230 {
  width: 230px;
}

.w-100px {
  width: 100px;
}

.w-150px {
  width: 150px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
hr {
  border-top: 1px solid #cbd5e0 !important;
}
/* Update By Vijay On 08-02-2022 Start */

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Work+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
.tab_title_main {
  padding: 20px;
}
.tab_title_name {
  font-family: Work Sans, sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #2e2c34;
  /* border: 1px solid #2E2C34; */
}

.Tabs_Tabpane {
  border: 1px solid #e2e8ed;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 20px !important;
  padding-top: 20px !important;
  padding-bottom: 56px !important;
  overflow-x: hidden;
}
.Tabs_Tabpane .ant-tabs-tab {
  font-family: Inter, sans-serif;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #718096;
}
.Tabs_Tabpane .ant-tabs-tab-active .ant-tabs-tab-btn,
.Tabs_Tabpane .ant-tabs-tab .ant-tabs-tab-active {
  color: #2c7be5 !important;
}

.Tab_content_Table .MuiTableContainer-root {
  margin-top: 20px !important;
}
.Tab_content_Table_Header tr {
  background: #edf2f8 !important;
  border: none !important;
}
.Tab_content_Table_Header th {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 17px;
  color: #2c7be5;
}
.Tab_Filter_Background {
  background: #f7f7f7;
  padding: 20px !important;
  align-items: center;
}

.Pat_Stmt_pat_Serach .input .prompt {
  background-color: #ffffff !important;
}
.Pat_Stmt_pat_Serach .input .icon {
  left: 78% !important;
}
.Fiter_Submit_Btn {
  align-items: center;
  padding: 8px 12px;
  background: #2c7be5;
  border-radius: 4px;
  color: #ffffff;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-left: 10px;
}
/* Update By Vijay On 08-02-2022 End */

/*Update By dhilip On 08-02-2022 start */

.export-btn {
  border: 1px solid #ebeaed !important;
  background: #fafafa !important;
  color: #2d3748 !important;
}
.export-btn:hover {
  border: 1px solid #ebeaed !important;
  background: #e1dcdc !important;
  color: #2d3748 !important;
}

/*Update By dhilip On 08-02-2022 start */
/*Update By saravanan On 08-02-2022 start */
.main-title-style {
  font-family: Work Sans, serif !important;
  font-size: 28px !important;
  color: #2e2c34 !important;
  display: flex;
  align-items: center;
}
.rep-top-layer {
  display: flex;
  align-items: center;
  margin: 20px 20px 20px 20px;
}
/*Update By saravanan On 08-02-2022 end */
/* Editing started by Vanitha */

.dropdown-menu .arrowup {
  width: 0!;
  height: 0!;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  min-width: 3rem !important;
  border-bottom: 10px solid black !important;
  display: block;
  border: none;
  border-radius: none;
}

.dropdown-menu .card_container {
  width: 258px !important;
  height: 210px !important;
  background: #ffffff !important;
  margin-left: -180px !important;
  box-shadow: 0px 6px 4px -4px rgba(0, 0, 0, 0.06),
    0px 16px 16px -8px rgba(0, 0, 0, 0.06) !important;
  border-radius: 12px !important;
  padding-left: 19px !important;
  margin-top: 20px !important;
  border: none !important;
  display: block;
}
.card_top {
  display: flex;
  margin-top: 7px;
}

hr.horizline {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 242px;
}
.avatarcard_img {
  margin-top: 6px;
  /* border-radius: 50%; */
}

.avatarcard_edit {
  font-size: 12px !important;
  line-height: 15px !important;
  color: #2c7be5;
  cursor: pointer;
  margin-left: 50px;
  font-family: inter, sans-serif;
}
.avatarcard_info {
  padding-left: 18px !important;
}
.aname {
  color: #000000;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !;
}
.amail {
  color: #636d73;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 15px !important;
}

.planpass {
  color: #5f6368;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 17px !important;
}
.pass {
  display: flex;
  margin-top: 10px;
  margin-left: -10px;
  padding-top: 5px;
  padding-left: 7px;
}
.plan {
  display: flex;
}
.log {
  display: flex;
  margin-top: 10px !important;
  margin-left: -10px !important;
  color: #ff5e55 !important;
  font-family: Inter, sans-serif;
  font-size: 14px !important;
  line-height: 17px !important;
  cursor: pointer !important;
  padding-top: 5px;
  padding-left: 84px;
}
.bar {
  width: 242px;
  height: 30px;
  background: #f7f7f7 !important;
  border-radius: 4px;
}
.ico_word {
  padding-left: 7px;
}
.lock {
  display: flex;
}

/*Ended*/

/* .cal_container {
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;

box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
border-radius: 6px;
height: 374px;
width: 400px;
} */
/* .cal_first {
width: 100%;
height: 100%;
background-image: linear-gradient(#2d65e8 60%, #ffffff 40%);
} */

/* Written by Vanitha */

.cal_first {
  /* background: rgba(221, 232, 253, 0.4); */
  height: 200px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
}

.cal_container .modal-dialog {
  width: 400px !important;
  border: none !important;
  box-shadow: none !important;
}
.cal_container .modal-body {
  padding: 0px !important;
}

.close_icon_main {
  /* height: 14px;
width: 14px;
margin-left: auto;
margin-right: 170px; */
  margin-top: -3px;
  color: #ffffff;
  font-size: 18px;
}
.close_icon_main svg {
  margin-top: 17px;
}
.cal_sec_row {
  /* border: 1px solid black; */
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  letter-spacing: 0.0025em;
  color: #2c7be5;
  margin-top: 21px;
}
.cal_secondrow_main {
  padding-left: 20px;
  /* height: 174px; */
  width: 400px !important;
}
.cal_sec_row_one {
  /* border: 1px solid black; */
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  letter-spacing: 0.0025em;
  color: #b0b0b0;
  margin-top: 12px;
  margin-right: 26px;
}
.cal_sec_row_two {
  background: #2d65e8;
  border-radius: 4px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 20px;
  padding: 7px 24px;
  color: #ffffff;
}
/* Ended */

/* ------------Vanitha--------25-02-22--------started */

.modal_body {
  height: 400px !important;
  overflow: auto !important;
}
.mod_bod_alt {
  overflow-x: hidden !important;
}
.modal_body_widthalt {
  height: 380px !important;
}
.modal_body_widthalt2 {
  height: 393px !important;
}

.modal_body_widthalt1 {
  height: 360px !important;
}
.mod_bod_alt1 {
  overflow: auto;
}
.create_btn {
  margin-left: auto;
  background-color: #f1f3f0;
}
.modal-dialog .create_btn button {
  margin-left: auto;
  margin-right: 20px;
}
/* ------------Vanitha--------25-02-22--------ended */

/*dhilip */
.left-nav-pos {
  position: fixed !important;
  height: 90% !important;
}

/*Vijay 13.03.22 start*/
.loading-more {
  text-align: -webkit-center;
  /* padding-top: 15px;
padding-bottom: 10px; */
}
.loading-more-div {
  padding: 4px 16px 6px 16px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  background: #ffffff !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
  box-sizing: border-box;
  border-radius: 4px;
}
.loading-more-div:hover {
  /* background: #e6f0ff !important;
border: 1px solid #3182ce !important;
color: #2a69ac !important; */
  background: rgba(244, 246, 249, 0.75) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.loading-more-div:active {
  /* background: #d7ebff !important;
border: 1px solid #3182ce !important;
color: #2c7be5 !important; */
  background: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.btn-primary:not(:disabled):not(.disabled):active.loading-more-div {
  background: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.loading-more-div:hover {
  /* background: #e6f0ff !important;
border: 1px solid #3182ce !important;
color: #2a69ac !important; */
  /* background: #2c7be5 !important;
  border: 1px solid #3182ce !important; */
  background: rgba(244, 246, 249, 0.75) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.loading-more-div:active {
  /* background: #d7ebff !important;
border: 1px solid #3182ce !important;
color: #2c7be5 !important; */
  /* background: #2c7be5 !important;
  border: 1px solid #3182ce !important; */
  background: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.btn-primary:not(:disabled):not(.disabled):active.loading-more-div {
  background: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}

.btn-primary:not(:disabled):not(.disabled):active.for_bill_edit_ {
  background: rgba(44, 123, 229, 0.1) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
.btn-primary:hover.for_bill_edit_ {
  background: rgba(244, 246, 249, 0.75) !important;
  border: 1px solid #2c7be5 !important;
  color: #2c7be5 !important;
}
/* .loading-more-div:focus {
box-shadow: none !important;
color: #2a69ac !important;
} */
/*vijay 13.03.22 end*/
/* cheran 14-3-22 start*/
.schedule-comp1 {
  box-shadow: 0px 1px 8px rgb(20 46 110 / 10%) !important;
  border-radius: 8px !important;
}

.mar-pad {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.pad-bottm {
  padding-bottom: 20px !important;
}
.neg-marg-btm {
  height: max-content !important;
}
.bord-radius {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.pading-nil {
  padding-bottom: 0px !important;
}
.bord-radius1 {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.all-flex-center-between {
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
}
.all-marg-r-6 {
  margin-right: 6px;
}
.all-pad-all-20 {
  padding: 20px 20px 8px 20px !important;
  text-align: left;
}
.all-tbl-text-main.MuiTableCell-body {
  color: #2e2e2e !important;
  font-weight: 500;
}
.tbl-txt-clr-holder .MuiTableCell-body:not(.all-tbl-text-main) {
  color: #636d73 !important;
}
.all-pd-lf-5 {
  padding-left: 5px !important;
}
.all-mk-marg-nill {
  margin: 0px !important;
}
.all-font-w-600 {
  font-weight: 600 !important;
}
.all-btn-height-32.btn.btn-primary,
.all-btn-height-32.btn.btn-success,
.all-btn-height-32.btn.btn-light,
.all-btn-height-32.btn.btn-outline-danger {
  height: 32px !important;
}
.eob-misc-class1 {
  margin-top: -1px;
  margin-bottom: 20.5px;
}

.fade {
  transition: opacity 0.7s linear, right 7s ease-out !important;
}

/* .modal-dialog {
margin: 0px 12px 0px 0px !important;
top: 0px !important;
}

.modal {
top: 72px !important;
} */

.modal .modal-dialog {
  transform: inherit !important;
}

.foot-btn-margin-remove button {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.make-margin-nill {
  margin: 0px !important;
}

.mk-all-pdng-20-2.modal-body {
  padding: 0px 20px 20px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

button[disabled]:hover {
  cursor: not-allowed;
}

.app-body {
  height: calc(100vh - 22px) !important;
}

.upgradFont {
  font-weight: bold !important;
}
.ant_select_dropdown_alt .ant-select-selection-item {
  display: flex !important;
}

.w-192 {
  width: 192px !important;
}

.ant-select-show-arrow .ant-select-arrow {
  display: flex !important;
  align-items: center !important;
}

.m-t-0 {
  margin-top: 0px;
}

.m-r-14 {
  margin-right: 14px;
}
.align_item_center {
  align-items: center;
}
