@media only screen and (max-width: 1100px) {
  .nav-menu-st {
    margin-right: 0px !important;
  }
  .success_bg span {
    font-size: 10px !important;
  }

  .failure_bg span {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 1350px) {
  .edit_note_accordion .accordion {
    width: 175px !important;
  }
  .schedule-menu {
    font-size: 14px !important;
  }
  /* video */
  .help-modal-video .modal-dialog {
    max-width: 400px !important;
  }
  /* video */
  .schedule-title {
    font-size: 16px !important;
  }
  .leftnav {
    width: 18% !important;
  }
  .right-block {
    width: 82% !important;
  }
  .pat-details {
    font-size: 12px !important;
  }
  .pro-name {
    font-size: 12px !important;
  }
  .edit-profile {
    font-size: 12px;
  }
  .edit-profile svg {
    width: 15px;
    height: 15px;
  }
  .other-details {
    font-size: 12px !important;
  }
  .bill-head {
    font-size: 12px !important;
  }
  .bill-left {
    font-size: 10px !important;
  }
  .bill-right {
    font-size: 10px !important;
  }
  .appt-stat {
    font-size: 11px !important;
  }
  .add-pay {
    font-size: 12px !important;
  }
  .insurance-head {
    font-size: 12px !important;
  }

  .ant-tabs-tab {
    font-size: 12px !important;
  }
  .MuiTableRow-root th,
  .MuiTableRow-root td {
    font-size: 12px !important;
  }

  .pat-mid-div {
    width: 75% !important;
  }
  .pat-right-div {
    width: 25% !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .pat-demo-data-left {
    width: 30% !important;
  }
  .pat-para {
    font-size: 10px !important;
  }
  .edit-nav .ant-menu-item {
    font-size: 11px !important;
    padding-left: 30px !important;
  }
  .edit-nav .ant-menu-submenu-title {
    padding-left: 26px !important;
    font-size: 14px;
  }
  .ins-clm-t-block {
    display: block !important;
  }

  .ins-cml-t-f-b {
    margin-bottom: 20px !important;
  }
  .css-1wa3eu0-placeholder {
    font-size: 11px !important;
  }

  /* .save-info-set {
    margin-top: 51px !important;
  } */
}
@media only screen and (min-width: 1350px) and (max-width: 1550px) {
  .man-top-note {
    width: 30% !important;
  }
  /* video */
  .help-modal-video .modal-dialog {
    max-width: 450px !important;
  }
  /* video */
}
/* video */
@media only screen and (min-width: 1551px) and (max-width: 1650px) {
  .help-modal-video .modal-dialog {
    max-width: 550px !important;
  }
}
/* video */
@media only screen and (max-width: 1474px) {
  .man-legend-text {
    width: unset !important;
  }
}
@media only screen and (max-width: 1350px) {
  .man-right-tab {
    width: 67% !important;
  }

  .man-left-tab {
    width: 30% !important;
  }
  .man-top-note {
    width: 50% !important;
  }
  .for-fee-sch-cpt-but {
    width: 9% !important;
  }
  .for-icd-9-srch-box-res-but {
    width: 10% !important;
  }
  .era {
    max-width: 13.666667%;
  }
  .remit {
    max-width: 80%;
  }
  .service {
    padding-top: 0px !important;
  }
  .new11 {
    margin-top: 250px !important;
  }
  .iborder {
    width: 180px !important;
  }
  .pate {
    width: 410px !important;
  }
  .tabd {
    padding-right: 0px;
    padding-left: 10px;
    font-size: 14px !important;
  }
  .ant-form-item-has-error .ant-input {
    width: 100%;
    margin-left: 20px;
  }
  .remit {
    max-width: 72% !important;
  }
  .remito {
    max-width: 52% !important;
  }
  .con {
    padding: 10px 24px 0px 15px !important;
  }

  .recharts-surface {
    width: 100%;
    /* margin-left: -110px; */
  }
  .back {
    width: 96% !important;
    margin-left: 0 !important;
  }
  .page {
    overflow: hidden;
  }

  .mgs {
    margin-left: 20% !important ;
  }
  .bth {
    width: 126px !important;
  }
  .ht {
    margin-left: -50px !important;
  }
  .send {
    font-size: 12px;
  }
  .fax {
    font-size: 12px;
  }
  .view {
    font-size: 12px;
  }
  .gf {
    margin-left: -81px;
  }
  .plm {
    padding-left: 0px !important;
    padding-right: 60px !important;
  }
  .bi-btn {
    padding-left: 3px !important;
    padding-right: 0px !important;
  }
  .be-btn {
    font-size: 10px;
  }
  .check-text {
    font-size: 12px;
  }
  .editt {
    margin-left: 59% !important;
  }
  .editer {
    margin-left: 60% !important ;
  }
  .editer button {
    font-size: 12px !important;
    height: 31px !important;
  }
  .thead {
    font-size: 12px !important;
  }
  .verti {
    padding-left: 32px !important;
    padding-right: 35px !important;
  }
  .date-w {
    width: 14% !important;
  }
  .wrt {
    width: 341px;
  }
  .pates {
    width: 100% !important;
  }
  .width-tab {
    width: 230px !important;
  }
  .wid-space {
    width: 590px !important;
  }
  .vitals_main {
    font-size: 10px !important;
    padding-left: 0px !important;
  }
  .tab-item-width {
    width: 714px !important;
  }
  .m-l-l {
    margin-left: -93px !important;
  }
  .serviceq {
    padding-top: 0px !important;
    font-size: 12px !important;
  }
  .e-quick-popup-wrapper {
    margin-left: 90% !important;
  }
  .e-toolbar .e-toolbar-items .e-toolbar-item button {
    width: 100% !important;
  }
  .etop-dropdown {
    width: 90px !important;
    height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 5px;
    color: #718096;
    font-size: 14px;
    margin-left: -45px !important;
    margin-right: 20px;
  }
  .etops-dropdown {
    width: 130px !important;
    height: 43px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 10px;
    color: #718096;
    font-size: 14px;
    margin-left: -20px;
  }
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
    margin-left: -20px !important;
  }
  .e-toolbar-center {
    margin-right: 40px !important;
  }
  #e-tbr-btn_2 span {
    padding: 0px 2px 0px 2px !important;
  }
  .e-overflow-show button {
    margin-right: 20px !important;
  }
  .e-schedule-print button {
    margin-right: 22px !important;
  }
  .e-active-view .e-tbar-btn-text {
    margin-left: -15px !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1012px) {
  .clinic_rep_table {
    max-width: calc(100vw - 9.95vw) !important;
  }
}

@media only screen and (min-width: 1014px) and (max-width: 1070px) {
  .clinic_rep_table {
    max-width: calc(100vw - 12vw) !important;
  }
}

@media only screen and (min-width: 1070px) and (max-width: 1100px) {
  .clinic_rep_table {
    max-width: calc(100vw - 13vw) !important;
  }
}

/* @media only screen and (min-width: 1100px) and (max-width: 1400px) {
  .clinic_rep_table {
    max-width: calc(100vw - 296px) !important;
  }
} */

@media only screen and (min-width: 1401px) and (max-width: 2000px) {
  .clinic_rep_table {
    max-width: calc(100vw - 9.95vw) !important;
  }
}

/* @media only screen and (min-width: 1054px) {
  .clinic_rep_table {
    max-width: calc(100vw - 252px) !important;
  }
} */
