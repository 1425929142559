.formbuilder {
  display: flex;
  /* flex-direction: row-reverse; */
  flex-wrap: nowrap !important;
  height: calc(100vh - 165px);
}
.formio-builder-components .builder-component {
  /* padding-bottom: 15px; */
  margin-bottom: 0px;
  padding: 10px;
  border-bottom: 1px solid #cbd5e0;
  padding-left: 24px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.main_column_vozo .formio-builder-components .builder-component:hover {
  background: #deefff;
  border: 0px solid #cbd5e0;
}
.drag-container {
  padding: 0px;
  border: 0px solid #cbd5e0;
}
.main_column_vozo {
  border: 0px solid #cbd5e0;
  border-right: 0px solid #cbd5e0;
  border-bottom: 0px solid #cbd5e0;
  margin-top: 62px;
}
.formio-component-signature div[ref="element"] {
  width: 260px;
}
.signature-pad .signature-pad-canvas {
  width: auto !important;
  max-width: none !important;
  max-height: none !important;
  cursor: auto;
}

.main_column_vozo .col-md-6:nth-child(1) .drag-container {
  border-right: 1px solid #cbd5e0 !important;
}

.main_column_vozo .drag-container {
  border-right: 0px solid #cbd5e0;
}

.main_column_vozo .col-md-6 {
  padding-left: 0px;
  padding-right: 0px;
}

.main_column_vozo .drag-container:hover {
  border: 0px solid #cbd5e0;
  border-right: 1px solid #cbd5e0;
}
.main_column_vozo .formio-builder-components .builder-component:last-child {
  border-bottom: none;
}
.bottom_select_vozo label {
  display: none;
}
.bottom_select_vozo {
  padding-left: 24px;
}
.bottom_clm_vozo .col-md-6 {
  padding-left: 0px;
  padding-right: 0px;
}
.bottom_clm_vozo .col-md-3 {
  padding-left: 0px;
  padding-right: 0px;
}

.bottom_clm_vozo .col-md-6 .builder-component {
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 0px solid #cbd5e0;
}

.bottom_clm_vozo select {
  background: #ecedef;
  border-radius: 4px;
  width: 115px;
  font-size: 14px;
  border: 0px;
}
.save_as_btn button {
  border: 1px solid #2c7be5;
  border-radius: 4px;
  background-color: white;
  color: #2c7be5;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 14px;
  padding-left: 14px;
}
.save_as_btn {
  float: right;
}

.save_btn button {
  border-radius: 4px;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 14px;
  padding-left: 14px;
}
.save_btn {
  text-align: center;
}

.drag-and-drop-alert {
  display: block;
  padding-top: 25px;
  padding-bottom: 25px;
  height: 92px;
  margin-bottom: 0px;
  border-radius: 0px;
  border: 0px;
  line-height: 45px;
}

.main_column_vozo .form-group {
  margin-bottom: 0px;
}
.main_column_vozo .form-group {
  text-align: left;
}
.main_column_vozo .col-form-label {
  padding-top: 0px;
  margin-bottom: 3px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.main_column_vozo .form-group input {
  width: 260px;
  /* margin-top: -5px; */
  /* border: 0px solid #dbdbdb; */
  border: 1px solid #ebeaed;
  border-radius: 4px;
  /* border: 0px solid #dbdbdb; */
  margin-bottom: 10px;
}

.vozo_multiple_select input {
  border: 0px solid #dbdbdb;
}

.formcomponents .card-body .formcomponent {
  width: 100%;
  display: inline-block;
  background: #fafafa;
  color: #2d3748;
  border: 1px solid #ebeaed;
  /* jeyabalaji start */
  padding: 12px 15px 12px 40px;
  /* jeyabalaji end */
  /* margin-bottom: 24px; */
  text-align: right;
  font-size: 12px;
  margin-top: 5px;
}
.formcomponents .builder-sidebar_scroll_clipboard .card-body .formcomponent {
  margin-top: 0px;
  font-size: 12px;
}
.formcomponents_clipboard {
  top: 260px !important;
}
.formcomponents .card-body .formcomponent i {
  margin-right: 5px;
  color: #718096;
}
.formcomponents #group-custom {
  display: block !important;
}
.formcomponents #heading-custom {
  display: none;
}
.formcomponents .card-body {
  padding: 0px !important;
  text-align: initial;
}

.field_clipboard_section .formcomponents .card-body {
  padding: 0px !important;
  text-align: initial;
  margin-top: 60px;
}

.formcomponents .card {
  border: 0px;
}
.builder-sidebar_search:after {
  content: "";
  display: block;
  height: 40px; /*height of icon */
  width: 40px; /*width of icon */
  position: absolute;
  /*where to replace the icon */
  top: 0px;
  left: -40px;
  /*background */
  /* background: #f8e6ae url(ico.gif) no-repeat 0px 0px; */
}

.radio_active {
  background: white !important;

  color: #2c7be5 !important;
}

.radio_active_tab {
  color: #2c7be5 !important;
  border-bottom: 1px solid #2c7be5 !important;
}
.radio_second_active_tab {
  color: #2c7be5 !important;
  border-bottom: 1px solid #2c7be5 !important;
}

.builder-sidebar {
  width: 100%;
  margin-left: 0px;
  padding-top: 15px;
  /* background-color: white; */
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-left-radius: 8px;
  /* height: calc(100% - 220px); */
  /* height: calc(100% + 100px); */
  height: calc(100% + 24px);
  padding-bottom: 20px;
  overflow: auto;
  /* background-color: white; */
}

/* .formarea {
margin-top: -120px;
}  */

.component-settings .lead {
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  border-bottom: 1px solid #cbd5e0;
  padding-left: 20px;
  padding-right: 20px;
}
.component-settings .formio-dialog-content {
  background-color: white;
}

.component-settings
  .formio-dialog.formio-dialog-theme-default
  .formio-dialog-close:before {
  display: block;
  padding: 0px;
  background: 0 0;
  color: #8a8a8a;
  content: "×";
  font-size: 20px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.main_column_vozo .builder-component .component-settings-button-edit {
  /* position: absolute;
left: -490px !important;
margin-top: -34px; */
  background-color: #ffffff00;
  border: 0px;
  box-shadow: unset;
}

.main_column_vozo .builder-component .component-settings-button-edit i {
  color: #2e2e2e;
  font-size: 16px;
}

.main_column_vozo .builder-component .component-settings-button-edit-json {
  display: none;
}

.main_column_vozo .builder-component .component-settings-button-paste {
  display: none !important;
}

.main_column_vozo .builder-component .component-settings-button-copy {
  background-color: #ffffff00;
  border: 0px;
  box-shadow: unset;
}

.main_column_vozo .builder-component .component-settings-button-copy i {
  color: #2e2e2e;
  font-size: 16px;
}

.main_column_vozo .builder-component .component-settings-button-move,
.builder-component .component-settings-button-move {
  background-color: #ffffff00;
  border: 0px;
  box-shadow: unset;
}

.main_column_vozo .builder-component .component-settings-button-move i,
.builder-component .component-settings-button-move i {
  color: #0b5fff;
  font-size: 16px;
}

.main_column_vozo .builder-component .component-settings-button-paste {
  background-color: #ffffff00;
  border: 0px;
  box-shadow: unset;
  display: none !important;
}

.main_column_vozo .builder-component .component-settings-button-paste i {
  color: #2e2e2e;
  font-size: 16px;
  display: none !important;
}

.main_column_vozo .builder-component .component-settings-button-remove,
.builder-component .component-settings-button-remove {
  background-color: #ffffff00;
  border: 0px;
  box-shadow: unset;
}

.main_column_vozo .builder-component .component-settings-button-remove i,
.builder-component .component-settings-button-remove i {
  color: #ff3e3d;
  font-size: 16px;
}

.main_column_vozo .component-btn-group {
  margin-top: 7px;
  margin-right: 10px;
}

.component-settings-button-edit i::before {
  content: "\f044";
}

.component-settings-button-copy i::before {
  content: "\f0c6";
}

.formio-builder-form {
  padding-bottom: 16px !important;
}

.formarea-save {
  flex: 0 0 80%;
  max-width: 79%;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 0px;
}
/* .empty_block {
  margin-top: -135px;
} */

.submit_block {
  background-color: white;
}
.appt-block-main-bill_form {
  width: 100%;
}
.p-r-0 {
  padding-right: 0px;
}

@media only screen and (max-width: 1574px) {
  .formcomponents .card-body .formcomponent {
    width: 100%;
  }
}

/* edit note */

.edit_note .main_column_vozo {
  /* christina */
  /* border: 1px solid #e2e8ed; */
  margin-left: 0px;
  margin-right: -1px;
  /* padding-left: 15px; */
  padding-right: 0px;
  /* margin-left: 15px; */
  border-left: 0px;
  border-radius: 0px;
  margin-top: 10px;
}

.edit_note .main_column_vozo {
  border-bottom: 0px solid #cbd5e0;
}
.formio-component-modal-wrapper {
  border-bottom: 1px solid #e2e8ed;
  padding: 15px;
  padding-bottom: 13px;

  text-align: left;
  margin-bottom: 0px;
}
.invalid-feedback {
  display: none;
}

.form_selection {
  margin-top: -341px !important;
}

.field_clipboard_section .card-body .formcomponent {
  width: 100% !important;
  display: inline-flex;
}

.field_clipboard_section .card-body .formcomponent i {
  margin-right: 5px;
  order: 1;
  margin-left: auto;

  padding: 4px;
}

.field_clipboard_section .builder-sidebar_search {
  display: none;
}

.paste-all-btn {
  width: 87%;
  position: absolute;
  border: 0px;
  color: #2e2e2e;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  top: 30px;
  left: 20px;
}

.cancel-all-btn {
  width: 87%;
  position: absolute;
  border: 0px;
  color: #ff3e3d;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  left: 20px;
  background: #ffdada;
}

.extra_close {
  margin-right: 5px !important;
  order: 1 !important;
  margin-left: unset !important;

  background: #ffdada;
  color: #ff3e3d !important;
  padding: 4px;
}

/* .main_column_vozo .builder-component .component-settings-button-copy {
display: none;
} */
/* .main_column_vozo .builder-component .component-settings-button-copy {
display: none;
} */

.vozo_form_header {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 4px !important;
  height: 66px;
}
.formio-component-form .vozo_form_header {
  padding-left: 25px;
  height: 92px;
}
.formio-component-form .vozo_form_sub_header {
  padding-left: 25px;
  height: 92px;
}
.vozo_form_sub_header {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
  margin-bottom: 4px !important;
  height: 66px;
}
.m-r-16 {
  margin-right: 16px;
}
.vozo_yes_no {
  padding: 24px;
  border: 1px solid #e2e8ed;
  border-radius: 4px;
  padding-top: 3px;
  padding-bottom: 2px;
}
.vozo_yes_no_block {
  display: flex;
}
.vozo_yes_no_block input {
  width: unset !important;
}
.vozo_yes_no_block input[type="checkbox"] {
  /* outline: 2px solid #c00; */
  background-color: orange;
  margin-right: 8px;
}

.vozo_switch .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.vozo_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.vozo_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.vozo_switch .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 7%), 0px 0.5px 2px rgb(0 0 0 / 10%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.vozo_switch input:checked + .slider {
  background-color: #2196f3;
}

.vozo_switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.vozo_switch input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.vozo_switch .slider.round {
  border-radius: 34px;
  background: #cbd5e0;
  /* border-radius: 34px; */
  width: 68px;
  height: 30px;
}

.vozo_switch .slider.round:before {
  border-radius: 50%;
}

.vozo_multiple_select input {
  background-color: #ffffff00;
}

.vozo_multiple_select::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red !important;
  opacity: 1; /* Firefox */
}

.vozo_multiple_select::-webkit-input-placeholder {
  /* Edge */
  color: red !important;
}

.vozo_multiple_select:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red !important;
}

.vozo_multiple_select .ui.fluid.dropdown {
  /* width: 90% !important; */
  max-height: unset;
  min-height: unset;
  padding-top: 4px;
  padding-bottom: 3px;
}
.fraction_field input {
  border: 1px solid #e2e8ed;
  width: 120px !important;
  height: 34px;
  border-radius: 4px;
  margin-right: 28px;
}
.fraction_field span {
  border: 1px solid #cbd5e0;
  transform: rotate(112.11deg);
  height: 0px;
  width: 34.54px;
  position: absolute;
  left: 140px;
  top: 52px;
}
.vozo_single_select input {
  margin-top: -7px;
}
.vozo_single_select .col-form-label {
  padding-bottom: 4px;
}
.vozo_single_select .ui.selection.dropdown {
  line-height: 2em;
}
.vozo_single_select .choices__item--selectable:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==) !important;
  padding: 0 !important;
  background-size: 8px !important;
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  margin-top: -10px !important;
  margin-right: 25px !important;
  height: 20px !important;
  width: 20px !important;
  border-radius: 10em !important;
  opacity: 0.5 !important;
  background-repeat: no-repeat;
}
.choices[data-type*="select-one"]:after {
  display: none;
}
.choices[data-type*="select-one"] .choices__button {
  display: none;
}

.signature-pad .signature-pad-canvas {
  height: 32px !important;
  width: 260px !important;
  border-radius: 1px;
  box-shadow: 0 0 5px rgb(0 0 0 / 2%) inset;
  border: 1px solid #f4f4f4;
  /* max-height: 101px; */
}
.signature-pad-body {
  height: 29px !important;
}
.signature-pad-footer {
  text-align: left;
  display: none;
}
.signature-pad-body {
  width: 260px;
  height: 32px;
}

/* .formio-component-labelPosition {
display: none;
}

.formio-component-description {
display: none;
}
.formio-component-tooltip {
display: none;
}

.formio-component-labelPosition {
display: none;
}

.formio-component-labelPosition {
display: none;
}

.formio-component-customClass {
display: none;
}

.formio-component-tabindex {
display: none;
}

.formio-component-hidden {
display: none;
}

.formio-component-hideLabel {
display: none;
}

.formio-component-autofocus {
display: none;
}

.formio-component-tableView {
display: none;
}
.formio-component-disabled {
display: none;
}
.formio-component-modalEdit {
display: none;
}
.formio-component-backgroundColor {
display: none;
}
.formio-component-prefix {
display: none;
}

.formio-component-suffix {
display: none;
}
.formio-component-widget.type {
display: none;
}
.formio-component-widget {
display: none;
}
.formio-component-inputMask {
display: none;
}
.formio-component-displayMask {
display: none;
}
.formio-component-inputMaskPlaceholderChar {
display: none;
}
.formio-component-allowMultipleMasks {
display: none;
}
.formio-component-inputMasks {
display: none;
}
.formio-component-autocomplete {
display: none;
}
.formio-component-showWordCount {
display: none;
}
.formio-component-showCharCount {
display: none;
}


.formio-component-widget {
display: none;
}
.formio-component-mask {
display: none;
}
.formio-component-spellcheck {
display: none;
}
.formio-component-footer {
display: none;
} */

.component-edit-container .formio-component-label {
  display: block !important;
}
.component-edit-container .formio-component-placeholder {
  display: block !important;
}
.component-edit-container .has-feedback {
  display: none;
}
.card-header-tabs {
  display: none;
}
.component-edit-container .col-sm-6 {
  flex: 0 0 100%;
  max-width: 100%;
}
.preview-panel {
  display: none;
}
.component-edit-container .float-right {
  display: none;
}
.component-edit-container .card-header {
  display: none;
}
.component-edit-container .btn-danger {
  display: none;
}

.component-edit-container .btn-secondary {
  float: left;
  border-color: #6c757d;
  color: #414d55;
  border: 0px;
  padding: 9px 20px;
  background: #ecedef;
  margin-left: 20px;
}

.component-edit-container .btn-success {
  background: #2c7be5;
  border-radius: 4px;
  border: 0px;
  padding: 9px 20px;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
  background: #ffffff;
  padding-left: 0px;
  padding-right: 0px;
  width: 360px;
  margin-left: auto;
  margin-right: 15px;
  padding-bottom: 20px;
}

.component-edit-container .card {
  border: 0px solid #ebeaed;
  /* padding-left: 0px;
padding-right: 0px; */
}

.formio-dialog.formio-dialog-theme-default .component-edit-container {
  padding: 0px;
}

.component-edit-container .card-body {
  padding: 16px 20px;
  padding-bottom: 0px !important;
}

.formio-component-switchComp {
  margin-top: 1px;
  margin-bottom: 1px !important;
}
.new_name_unset {
  display: block;
}

.new_name_body {
  /* max-height: 290px !important; */
  height: inherit !important;
  line-height: initial;
}

.new_btn_close {
  border: 0px solid !important;
  background: #ecedef !important;
  color: #414d55 !important;
  font-weight: 500 !important;
  /* margin-top: auto; */
  /* margin-bottom: auto; */
  line-height: initial !important;
}

.new_btn_save {
  color: #ffffff !important;
  line-height: initial !important;
}
/* .formio-builder-form .builder-component .formio-component {
display: none;
} */
/* .formio-builder-form .builder-component .main_column_vozo {
display: blflexock !important;
} */

/* .formio-builder-form > .builder-component:first-child {
display: block !important;
margin-bottom: 4px;
}
.formio-builder-form > .builder-component {
display: none !important;
}

.formio-builder-form > .builder-component {
display: none !important;
} */

.formio-builder-form
  > .builder-component:first-child
  > .component-btn-group:first-child {
  display: none !important;
}

.edit_note .invalid-feedback {
  display: block !important;
}

#macro_btn {
  display: none;
}
.vozo_yesno input {
  width: unset !important;
}
.vozo_yesno .col-form-label {
  padding-bottom: 5px;
}
.vozo_yesno .form-check-inline label {
  padding: 3px 24px;
  border: 1px solid #e2e8ed;
  border-radius: 4px;
}
.vozo_fractional_field table tr:last-child {
  display: none;
}
.vozo_fractional_field table tr td:last-child {
  display: none;
}
.vozo_fractional_field table tbody {
  display: flex;
}
.vozo_fractional_field table {
  border: 0px solid #dee2e6;
}
/* christina */
.main_column_vozo .vozo_fractional_field table tr td {
  /* width: 120px; */
  padding-left: 0px;
  padding-top: 17px;
  padding-bottom: 0px;
  border: unset !important;
  border-right: unset !important;
}
/* christina */
.vozo_fractional_field table tr:nth-child(2) td:nth-child(1) {
  width: 120px;
  /* border-left: 1px solid #cbd5e0 !important;
transform: rotate(112.11deg); */
}

.vozo_fractional_field table tr:nth-child(2) td:nth-child(1) input::before {
  /* width: 120px; */
  border-left: 1px solid #cbd5e0 !important;
  transform: rotate(112.11deg);
}
.table-bordered td {
  border: 0px solid #dee2e6;
}
.table-bordered {
  margin-top: -17px !important;
}
.vozo_fractional_field table tr td input {
  width: 124px !important;
}

.vozo_switch label {
  position: relative !important;
  display: inline-block !important;
  width: 60px !important;
  height: 25px !important;
}
.vozo_switch .form-check {
  padding-left: 0px;
}
.vozo_switch input {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}
.vozo_switch span {
  position: absolute !important;
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  /* jeyabalaji start */
  background-color: #cbd5e0 !important;
  /* jeyabalaji end */
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
}

.vozo_switch span:before {
  position: absolute !important;
  content: "" !important;
  /* jeyabalaji start */
  height: 24px !important;
  width: 24px !important;
  left: 3px !important;
  top: 3.2px !important;
  /* jeyabalaji end */
  bottom: 3px !important;
  background-color: white !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
  bottom: 5px !important;
}

/* jeyabalaji start */
.vozo_switch span:after {
  content: "No" !important;
  color: white !important;
  font-size: 16px;
  margin-left: 25px;
}
/* jeyabalaji end */

.vozo_switch input:checked + span {
  background-color: #2196f3 !important;
}

.vozo_switch input:focus + span {
  box-shadow: 0 0 1px #2196f3 !important;
}

.vozo_switch input:checked + span:before {
  /* jeyabalaji start */
  -webkit-transform: translateX(38px) !important;
  -ms-transform: translateX(38px) !important;
  transform: translateX(38px) !important;
  /* jeyabalaji end */
}
/* jeyabalaji start */
.vozo_switch input:checked + span:after {
  content: "Yes" !important;
  display: block;
  color: white !important;
  margin-top: -22px;
  margin-left: 10px;
  font-size: 16px;
}
/* jeyabalaji end */

.vozo_switch span {
  border-radius: 34px !important;
  color: #7fffd400;
  /* jeyabalaji start */
  height: 31px;
  width: 68px;
  padding-top: 2px;
  /* jeyabalaji end */
}

.vozo_switch span:before {
  border-radius: 50% !important;
}

/* .formio-component-checkbox:after {
content: "DDk";
} */
.vozo_switch .col-form-label {
  width: unset !important;
}

.edit_note .formio-form .col-md-6:first-child {
  border-right: 1px solid #e2e8ed;
}
.edit_note .form-group {
  /* christina start */
  /* border-bottom: 1px solid #e2e8ed; */
  width: 90%;
  padding: 15px;
  margin-left: -15px;
  padding-bottom: 4px !important;
  padding-left: 39px;
  /* christina end */
}

/* .edit_note .vozo_form_sub_header {
padding-bottom: 19px;
} */
.edit_note .formio-component-shortTextField {
  padding-bottom: 7px;
}

.edit_note .col-form-label {
  padding-bottom: 8px;
}
.edit_note .vozo_switch .col-form-label {
  padding-bottom: 27px;
}
/* .edit_note .vozo_multiple_select .invalid-feedback {
display: none !important;
} */
.edit_note .vozo_multiple_select .form-group {
  border-bottom: 0px solid #e2e8ed;
  padding: 0px;
  padding-bottom: 4px;
  padding-left: 15px;
}
.edit_note .formio-form .col-md-6 .form-group:last-child {
  border-bottom: 1px solid #e2e8ed !important;
}

.edit_note .vozo_single_select .form-group {
  padding: 0px !important;
  padding-left: 15px !important;
}

/* .edit_note .formio-form > .div:nth-child(2) {
display: none !important;
} */

.edit_note .col-md-6:nth-child(2) .form-group {
  margin-left: 0px;
}

.edit_note .open-modal-button {
  width: 280px;
}
.form_list {
  cursor: pointer;
  font-size: 14px !important;
  color: #718096;
  font-weight: 500 !important;
  margin-left: 25px;
}
.unlock_notes {
  padding: 0px !important;
}

.unlock_notes .MuiTableContainer-root {
  margin-top: 0px !important;
}
.unlock_notes_main .modal-dialog {
  width: 600px !important;
}

.lock_note_btn {
  background: #ebeaed !important;
  border: 1px solid #ebeaed !important;
  border-radius: 4px !important;
  color: #2e2e2e !important;
}

.edit_note_btn {
  color: #2c7be5 !important;
  border: 0px !important;
}

.edit_note_icon_btn {
  margin-right: 5px;
}

.unlock_notes .MuiTableHead-root tr th {
  color: #777984 !important;
}

.edit_note_patient {
  color: #2c7be5 !important;
  font-weight: 500 !important;
}

.main_column_vozo .choices__input {
  margin-top: 0px !important;
  margin-bottom: 0px;
}

.note_btn_lite {
  border: 1px solid #cbd5e0 !important;
  border-radius: 4px !important;
  background: #fafafa !important;
  color: #718096 !important;
  line-height: 5px !important;
}

.note_btn_dark {
  color: #ffffff !important;
  line-height: 5px !important;
}
.w-100_notes {
  width: 100px !important;
}
.invalid-feedback #macro_btn {
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 48%;
}

.switch_editnote {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.switch_editnote input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider_editnote {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* jeyabalaji start */
  background-color: #cbd5e0;
  /* jeyabalaji end */
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_editnote:before {
  position: absolute;
  content: "";
  /* jeyabalaji start */
  height: 24px;
  width: 24px;
  left: 5px;
  top: 3px;
  /* jeyabalaji end */
  bottom: 5px !important;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* jeyabalaji start */
input:checked + .slider_editnote:after {
  content: "Yes" !important;
  display: block;
  color: white !important;
  margin-left: -25px;
  font-size: 16px;
}
/* jeyabalaji end */

input:checked + .slider_editnote {
  background-color: #2196f3;
}

input:focus + .slider_editnote {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider_editnote:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(36px);
  transform: translateX(36px);
}

/* Rounded sliders */
.slider_editnote.round_editnote {
  /* jeyabalaji start */
  height: 30px;
  width: 68px;
  padding-top: 1px;
  border-radius: 34px !important;
  /* jeyabalaji end */
}

/* jeyabalaji start */
.slider_editnote.round_editnote:after {
  content: "No" !important;
  color: white !important;
  font-size: 16px;
  margin-left: 25px;
}
/* jeyabalaji end */

.slider_editnote.round_editnote:before {
  border-radius: 50%;
}
.edit_note_header {
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 17px;
  align-items: center;
}

.editnote_include {
  padding-left: 15px;
}

.editnote_add {
  margin-left: auto;
}
.active_fb {
  background: #2c7be5;
  color: white;
}

.lock_note_footer button {
  margin-left: auto;
}

.unlock_notes_main .MuiTableContainer-root {
  margin-bottom: 15px;
}

.unlock_notes_main .md-code-table {
  border: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.edit_note_accordion .accordion {
  width: 200px;
}
.edit_note_accordion .accordion .show {
  max-height: 500px;
  overflow-y: auto;
}
.editnote_lockStatus {
  text-align: left;
  padding-left: 35px;
}
/* form builder table */
.main_column_vozo .table {
  margin-top: 0px !important;
}
.formarea {
  padding-left: 0px;
  padding-right: 0px;
}
.main_column_vozo .table tr td {
  padding: 0px !important;
  border-top: 1px solid #cbd5e0 !important;
  border-bottom: 1px solid #cbd5e0 !important;

  width: 50%;
}
.main_column_vozo .table tr td:first-child {
  border-right: 1px solid #cbd5e0;
}
.formio-builder-form > .builder-component {
  display: block !important;
  margin-bottom: -45px;
}
.formio-builder-form
  > .builder-component
  > .component-btn-group
  .component-settings-button-edit,
.formio-builder-form
  > .builder-component
  > .component-btn-group
  .component-settings-button-edit-json,
.formio-builder-form
  > .builder-component
  > .component-btn-group
  .component-settings-button-copy {
  display: none;
}
/* #form_header_block,
.formarea,
.formarea-save {
position: fixed;
} */

/* App container-fluid p-r-0 */
/* christina */
.vozo_multiple_select input:focus::placeholder {
  color: transparent !important;
  display: none !important;
}
.vozo_multiple_select input::placeholder {
  color: transparent !important;
}
.vozo_multiple_select .choices__item {
  color: #2c7be5;
  font-weight: 400;
  line-height: 17px;
  font-family: "Inter";
  background: #ebf3ff;
  border-radius: 4px;
  padding: 4px 8px;
  border: unset;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  background-image: url("../../assets/images/close.png") !important;
}

.vozo_multiple_select .choices__item .choices__button {
  color: #747474 !important;
  border-left: unset;
}
/* christina */
.component-edit-container .card-body .formio-component-datagrid {
  display: block !important;
  margin-bottom: 0rem;
  border: 0px;
}
.formio-component-tabs .card .tab-pane:nth-child(2),
.formio-component-tabs .card .tab-pane:nth-child(3) {
  display: block !important;
  padding: 0px 20px !important;
}
.formio-component-tabs .card .tab-pane:nth-child(3) .col-form-label {
  display: block !important;
  margin-bottom: 20px;
}
.formio-component-tabs .card .tab-pane:nth-child(3) .table-bordered td {
  border: 1px solid #dee2e6;
}
.formio-component-tabs
  .card
  .tab-pane:nth-child(3)
  .table-bordered
  td
  .has-feedback {
  display: block !important;
}

.formio-component-tabs .card .formio-component-panel .border {
  display: none;
}

.component-edit-container
  .card-body
  .formio-component-values
  table
  thead
  tr
  th:nth-child(4) {
  display: none !important;
}
.component-edit-container
  .card-body
  .formio-component-values
  table
  tbody
  tr
  td:nth-child(4) {
  display: none !important;
}

/* jeyabalaji start */
.vozo_checkbox {
  height: 70px;
}
.vozo_checkbox input {
  margin-top: 25px !important;
  /* margin-left: -58px; */
}

/* .vozo_checkbox label:before {
  content: "CheckBox";
  margin-left: -20px;
} */
.vozo_checkbox .form-check {
  margin-top: 5px;
}

/* jeyabalaji end */

/* Vijay 09.06.2022 Start */
.formarea {
  /* box-shadow: 0px 1px 8px rgb(20 46 110 / 10%);
  border-radius: 8px; */
  background-color: #ebf1f7;
  /* flex: 0 0 80%; */
  /* opacity: 0.1; */
  max-height: calc(100vh - 165px);
  overflow: auto;
  max-width: 79%;
  margin-right: 15px;
  /* margin-top: -72px; */
  padding-left: 0px;
  padding-right: 0px;
}

.formcomponents {
  flex: 0 0 18.8% !important;
  max-width: 19%;
  order: 2;
  padding-left: 0px;
  position: absolute;
  z-index: 2;
  left: 80%;
  top: 180px;
  padding-right: 0px;
  height: calc(100vh - 345px);
  /* height: 427px !important; */
  /* margin-right: auto; */
  /* position: absolute; */
  /* padding-left: 3px; */

  border-radius: 8px;
  /* height: 100; */

  /* height: calc(100% + 58vh);
hei */
  /* background-color: white; */
}
@media (max-width: 1334px) {
  .formcomponents {
    top: 235px !important;
  }
}
@media (min-width: 1335px) and (max-width: 1515px) {
  .formcomponents {
    top: 235px !important;
    /* Your other styles for .formcomponents within the specified width range */
    /* Add more styles as needed */
  }
}
.formio-component-label-hidden {
  background-color: #ffffff;
  /* border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px; */
}
.alert-info {
  background-color: #ffffff;
  color: #718096;
}
/* Vijay 09.06.2022 End */

/*Ahamed 13-09-2022 Start */

.form-check-input[type="checkbox"]:before,
.form-check-input[type="checkbox"]:after {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ebeaed;
  content: "";
  background: #fff;
  border-radius: 4px;
}

.form-check-input[type="checkbox"]:after {
  top: -20px;
  background-position: center;
}

.form-check-input[type="checkbox"]:checked:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 4px;
}

.form-check-label input[type="checkbox"]:checked::before {
  background: #407bff;
}

.main_column_vozo .form-group input[type="checkbox"] {
  width: 0px !important;
  border: 0;
}
.form-check-input[type="checkbox"] {
  position: relative;
  margin-left: 0;
  cursor: pointer;
}

.form-check-label {
  display: flex;
  flex-direction: column-reverse;
}

/* .vozo_checkbox .form-check-label {
  display: unset;
  flex-direction: unset;
} */
.vozo_checkbox .form-check-input[type="checkbox"] {
  position: absolute;
  /* margin-left: -58px; */
}
.formio-component .form-check {
  display: inline-block;
}
.formio-component-signature div[ref="element"] {
  width: 260px;
}
.signature-pad .signature-pad-canvas {
  width: auto !important;
  max-width: none !important;
  max-height: none !important;
  cursor: auto;
}
/*Ahamed 13-09-2022 End */

.form-check-input[type="checkbox"]:before,
.form-check-input[type="checkbox"]:after {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ebeaed;
  content: "";
  background: #fff;
  border-radius: 4px;
}

.form-check-input[type="checkbox"]:after {
  top: -20px;
  background-position: center;
}

.form-check-input[type="checkbox"]:checked:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 4px;
}

.form-check-label input[type="checkbox"]:checked::before {
  background: #407bff;
}

.main_column_vozo .form-group input[type="checkbox"] {
  width: 0px !important;
  border: 0;
}
.form-check-input[type="checkbox"] {
  position: relative;
  margin-left: 0;
  cursor: pointer;
}

.form-check-label {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 10px;
}

/* .vozo_checkbox .form-check-label {
  display: unset;
  flex-direction: unset;
} */
.vozo_checkbox .form-check-input[type="checkbox"] {
  position: absolute;
  /* margin-left: -58px; */
}
.formio-component .form-check {
  display: flex;
}
/*Ahamed 13-09-2022 End */
/* cheran 13.9.22 start */

.main_column_vozo .input-group-append .fa-calendar {
  display: none;
}
.main_column_vozo .input-group-append .input-group-text {
  display: none;
}

.main_column_vozo
  .formio-component-datePicker
  > div:nth-child(2)
  > div
  > input.form-control.input {
  background-image: url("../../assets/images/form_builder_new_calendar.png") !important;
  background-repeat: no-repeat !important;
  background-size: 16px 16px !important;
  background-position: right 10px top 8px;
  border-radius: 4px !important;
}
.main_column_vozo
  .formio-component-datePicker1
  > div:nth-child(2)
  > div
  > input.form-control.input {
  background-image: url("../../assets/images/form_builder_new_calendar.png") !important;
  background-repeat: no-repeat !important;
  background-size: 16px 16px !important;
  background-position: right 10px top 8px;
  border-radius: 4px !important;
}
.main_column_vozo .form-check-inline label {
  display: flex;
  padding: 10px 20px;
  font-family: "Inter";
  font-size: 18px !important;
  line-height: 21px;
  font-weight: 400;
}
.main_column_vozo .form-check-inline .form-check-input {
  width: 20px !important;
  height: 20px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 12px !important;
}
/* cheran end */

.Preview_Note {
  padding: 20px !important;
  border-radius: 8px !important;
  height: calc(100vh - 170px) !important;
  margin-bottom: 0px !important;
}

.Print_Note {
  padding: 20px !important;
  border-radius: 8px !important;
  height: calc(100vh - 150px) !important;
  width: 80%;
  margin-bottom: 0px !important;
}

/*Ahamed 15-09-2022 start */
.main_column_vozo .form-group input[aria-label="Multiple Select"] {
  visibility: hidden;
  position: relative;
}

.vozo_multiple_select .ui.fluid.dropdown::after {
  content: "";
  width: 30px;
  height: 30px;
  background: url("../../assets/images/arrow-down.png") no-repeat;
  top: 30%;
  right: 0;
  position: absolute;
}
.formio-component textarea.form-control {
  height: 40px;
  resize: none;
}
/*Ahamed 15-09-2022 End */

/*  Vanitha 15-09-22 */

.upload_consent_form .modal-dialog {
  height: 330px !important;
}

.form-check-inline .form-check-input {
  margin-top: 3px !important;
}

.vozo_yesno .form-check-inline label {
  display: flex !important;
  padding-top: 10px !important;
  padding-bottom: 9px !important;
}

.form-check-label {
  flex-direction: row !important;
  align-items: center !important;
  /* margin-left: -20px !important; */
}

.main_column_vozo .form-group input[type="checkbox"] {
  margin-right: 26px !important;
}

/*  Vanitha 15-09-22 */
/* persist start */
.persist {
  background-color: #718096;
  float: right;
  border-radius: 2px;
  height: 16px;
  width: 16px;
  padding: 1px;
  margin-top: 3px;
}
.persist path {
  color: white;
}

/* persist end */

/* clipboard start */
/* .builder-sidebar_scroll {
  display: none;
} */
.builder-sidebar_scroll_active {
  display: block;
}
.builder-sidebar_scroll_clipboard {
  margin-top: 0px;
}
.builder-sidebar_scroll_clipboard .builder-sidebar_search {
  display: none;
}
.formcomponents .builder-sidebar_scroll_clipboard .card-body span {
  /* background-image: url("../../assets/images/form_builder_new_yes_no.png") !important; */
  background-repeat: no-repeat;
  background-size: 40px 20px !important;
  background-position: 220px !important;
  padding: 12px 73px 12px 12px !important;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="header"] {
  background-image: url("../../assets/images/form_builder_new_text.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 15px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="textField"] {
  background-image: url("../../assets/images/form_builder_new_short_text.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="textArea"] {
  background-image: url("../../assets/images/form_builder_new_short_text.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="subheader"],
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="subHeader"] {
  background-image: url("../../assets/images/form_builder_new_text.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 15px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="Short_text_field"],
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="shortTextField"] {
  background-image: url("../../assets/images/form_builder_new_short_text.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="Switch"] {
  background-image: url("../../assets/images/form_builder_new_switch.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="fraction"] {
  background-image: url("../../assets/images/form_builder_new_fraction.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="yesnoComp"] {
  background-image: url("../../assets/images/form_builder_new_yes_no.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="radio"] {
  background-image: url("../../assets/images/form_builder_new_yes_no.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}

.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="multiple"] {
  background-image: url("../../assets/images/form_builder_new_multiple.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="singleselect"] {
  background-image: url("../../assets/images/form_builder_new_single.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="select"] {
  background-image: url("../../assets/images/form_builder_new_single.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 25px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="freedraw"] {
  background-image: url("../../assets/images/form_builder_new_signature.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="signature"] {
  background-image: url("../../assets/images/form_builder_new_signature.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 20px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="selectBoxes"] {
  background-image: url("../../assets/images/form_builder_new_switch.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="date_picker"],
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="date"] {
  background-image: url("../../assets/images/form_builder_new_calendar.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 20px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="check_box"],
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="checkbox"] {
  background-image: url("../../assets/images/form_builder_new_checkbox.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 20px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="text_area"] {
  background-image: url("../../assets/images/form_builder_new_short_text.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}

.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="headerComp"] {
  background-image: url("../../assets/images/form_builder_new_yes_no.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 20px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.formcomponents
  .builder-sidebar_scroll_clipboard
  .card-body
  span[data-key*="multiple"] {
  background-image: url("../../assets/images/form_builder_new_multiple.png"),
    url("../../assets/images/Rectangle_close.png") !important;
  background-repeat: no-repeat;
  background-size: 40px 20px, 20px 20px !important;
  background-position: 80%, 96% !important;
  padding: 12px 10px 12px 12px;
}
.builder-sidebar_scroll_clipboard {
  height: calc(100% + -30px) !important;
}
.builder-paste-mode .component-settings-button-paste {
  display: none !important;
}

.formcomponents .builder-sidebar_scroll_clipboard .card-body .formcomponent i {
  display: block !important;
  float: right;
  color: transparent !important;
  margin-right: -5px;
  height: 14px;
  width: 15px;
  height: 19px;
  width: 21px;
  margin-left: 83%;
  position: absolute;
}

.builder-sidebar_scroll_clipboard
  .btn-primary:not(:disabled):not(.disabled):active,
.builder-sidebar_scroll_clipboard
  .btn-primary:not(:disabled):not(.disabled).active
  > .top-pat-dropdown.btn-primary.dropdown-toggle {
  background-color: #fafafa !important;
  color: #2d3748 !important;
  border: 1px solid #ebeaed !important;
}
.builder-sidebar_scroll_active .card {
  border-radius: 0px;
}
/* clipboard end */

@media only screen and (max-width: 1495px) {
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="Short_text_field"],
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="shortTextField"] {
    background-image: url("../../assets/images/form_builder_new_short_text.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="Switch"] {
    background-image: url("../../assets/images/form_builder_new_switch.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="fraction"] {
    background-image: url("../../assets/images/form_builder_new_fraction.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="yesnoComp"] {
    background-image: url("../../assets/images/form_builder_new_yes_no.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="radio"] {
    background-image: url("../../assets/images/form_builder_new_yes_no.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }

  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="multiple"] {
    background-image: url("../../assets/images/form_builder_new_multiple.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="singleselect"] {
    background-image: url("../../assets/images/form_builder_new_single.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="select"] {
    background-image: url("../../assets/images/form_builder_new_single.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="freedraw"] {
    background-image: url("../../assets/images/form_builder_new_signature.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="signature"] {
    background-image: url("../../assets/images/form_builder_new_signature.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="selectBoxes"] {
    background-image: url("../../assets/images/form_builder_new_switch.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="date_picker"],
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="date"] {
    background-image: url("../../assets/images/form_builder_new_calendar.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="check_box"],
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="checkbox"] {
    background-image: url("../../assets/images/form_builder_new_checkbox.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="text_area"] {
    background-image: url("../../assets/images/form_builder_new_short_text.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }

  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="headerComp"] {
    background-image: url("../../assets/images/form_builder_new_yes_no.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="multiple"] {
    background-image: url("../../assets/images/form_builder_new_multiple.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="textField"] {
    background-image: url("../../assets/images/form_builder_new_short_text.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    span[data-key*="textArea"] {
    background-image: url("../../assets/images/form_builder_new_short_text.png"),
      url("../../assets/images/Rectangle_close.png") !important;
    background-repeat: no-repeat;
    background-size: 0px 0px, 15px 15px !important;
    background-position: 80%, 96% !important;
    padding: 12px 10px 12px 12px;
  }
  .formcomponents .builder-sidebar_scroll_clipboard .card-body span {
    /* background-image: url("../../assets/images/form_builder_new_yes_no.png") !important; */
    font-size: 12px !important;
    font-weight: 500;
    margin-bottom: 9px;
    padding: 12px 50px 12px 12px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .formcomponents
    .builder-sidebar_scroll_clipboard
    .card-body
    .formcomponent
    i {
    display: block !important;
    float: right;
    color: transparent !important;
    height: 19px;
    width: 26px;
    margin-left: 73%;
    position: absolute;
  }
}
/* @media only screen and (min-width: 1529px) and (max-width: 1875px) {
.builder-sidebar {
  margin-left: 1px;
}
}

@media only screen and (min-width: 1200px) and (max-width: 1529px) {
.builder-sidebar {
  margin-left: -1px;
}
} */

/* Vanitha 07-10-22 */

/* .formio-component-select .formio-choices {
    position: absolute;
  } */

/* Vanitha 07-10-22 */

/* Vanitha 11-10-22 */

/* .formio-component-select {
    height: 105px !important;
  } */
.choices__list--dropdown {
  top: 40px !important;
}

/* Vanitha 11-10-22 */

/* Vanitha 1-10-22 */

.vozo_radiobtn .form-check-input[type="radio"] {
  outline: none;
  margin-bottom: 0px !important;
}

.vozo_radiobtn input {
  margin-bottom: 0px !important;
}

/* Vanitha 1-10-22 */

/* Vanitha 2-10-22 */

.vozo_radiobtn .form-radio input {
  width: 15px !important;
  margin-top: 3px !important;
}

.vozo_radiobtn .form-radio {
  display: flex !important;
  flex-wrap: wrap !important;
}

.vozo_radiobtn .form-radio .form-check {
  padding-right: 20px !important;
}

/* Vanitha 2-10-22 */

.task_management_task-checckbox .form-check-input[type="checkbox"]:before {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ebeaed;
  content: "";
  background: #1890ff;
  border-radius: 4px;
}

.task_management_task-checckbox .form-check-input[type="checkbox"]:after {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #ebeaed;
  content: "";
  background: #fff;
  border-radius: 4px;
}

.task_management_task-checckbox
  .form-check-input[type="checkbox"]:checked:after {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: #fff;
}

/* .choices__list.choices__list--multiple {
    background-color: #ebf3ff !important;
  } */

.vozo_fractional_field table tr:nth-child(2) {
  margin-top: 0 !important;
}

.vozo_checkbox > .checkbox > .form-check-label > span {
  margin-left: 35px;
  padding-top: 10px;
}

.vozo_single_select
  > .formio-choices
  > .dropdown
  > .choices__list
  > .choices__item {
  background: none !important;
}
.edit_note .is-flipped .choices__list--dropdown {
  bottom: unset;
}
.builder-sidebar .form-control {
  margin-bottom: 10px;
}
.formcomponents .card-body {
  column-count: 1;
}
::-webkit-scrollbar {
  width: 5px !important; /* width of the vertical scrollbar */
  margin-left: 1px !important;
}
.formio-button-add-row {
  border: 1px solid #2c7be5;
  background-color: white;
  color: #2c7be5;
}
.formio-button-add-row i {
  font-size: 12px;
}
.component-edit-container .btn-secondary {
  border: 1px solid #2c7be5;
  background-color: white;
  color: #2c7be5;
}

.formio-button-remove-row {
  border: 0px solid #2c7be5 !important;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-close:before {
  color: #000000;
  font-size: 40px;
  line-height: 40px;
}
/* .formio-component-textarea {
  margin-bottom: 18px !important;
} */
.builder-component .formio-component .form-control {
  width: 240px;
}
.formio-select-autocomplete-input {
  display: none;
}
.formio-component-selectboxes .form-radio {
  height: 43px !important;
}

.main_column_vozo .form-group .selection input {
  border: 0px solid #ebeaed !important;
}

.formio-builder-form .builder-component {
  background-color: white;
}
.main_column_vozo .table .builder-component:hover {
  background: #deefff;
}
.main_column_vozo .table .builder-component {
  background: transparent;
}
.form_builder_spinner {
  z-index: 9999;
}
.edit_note_accordion .btn-link {
  text-decoration: none !important;
}
.main_column_vozo .table tr td:hover {
  background-color: #deefff;
}

.main_column_vozo .vozo_fractional_field .table:hover {
  background-color: #deefff;
}
.main_column_vozo .vozo_fractional_field .table {
  background-color: transparent;
}

.formio-form {
  min-height: 75px;
}
.formio-component-table
  .formio-builder-components
  .component-settings-button-move {
  display: block;
}

.formio-builder-form .component-settings-button-move {
  display: none;
}
.vozo_form_header input {
  display: none;
}
.vozo_form_sub_header input {
  display: none;
}
.formcomponents_height_auto.formcomponents {
  height: auto !important;
}

textarea#chiefComplaintField-chiefComplaint {
  height: 160px;
  resize: auto;
}
div#chiefComplaintField {
  margin-top: -20px;
  width: 100%;
}

textarea#historyOfPresentIllnessField-historyOfPresentIllness {
  height: 160px;
  resize: auto;
}
div#historyOfPresentIllnessField {
  margin-top: -20px;
  width: 100%;
}
textarea#reviewOfSystemsField-reviewOfSystems {
  height: 160px;
  resize: auto;
}

div#reviewOfSystemsField {
  margin-top: -20px;
  width: 100%;
}
textarea#pastMedicalHistoryField-pastMedicalHistory {
  height: 160px;
  resize: auto;
}

div#pastMedicalHistoryField {
  margin-top: -20px;
  width: 100%;
}
textarea#assessmentField-assessment {
  height: 160px;
  resize: auto;
}

div#assessmentField {
  margin-top: -20px;
  width: 100%;
}
textarea#planField-plan {
  height: 160px;
  resize: auto;
}
div#planField {
  margin-top: -20px;
  width: 100%;
}

textarea#physicalExaminationField-physicalExamination {
  height: 160px;
  resize: auto;
}
div#physicalExaminationField {
  margin-top: -20px;
  width: 100%;
}
textarea#pastFamilyHistoryField-pastFamilyHistory {
  height: 160px;
  resize: auto;
}

div#pastFamilyHistoryField {
  margin-top: -20px;
  width: 100%;
}
textarea#pastSocialHistoryField-pastSocialHistory {
  height: 160px;
  resize: auto;
}
div#pastSocialHistoryField {
  margin-top: -20px;
  width: 100%;
}

textarea#diagnosticTestingField-diagnosticTesting {
  height: 160px;
  resize: auto;
}
div#diagnosticTestingField {
  margin-top: -20px;
  width: 100%;
}
.custom-line {
  border: none;
  border-top: 2px solid #ccc;
  margin: 15px 0;
}

.ai_input_text {
  font-size: 14px;
  font-family: var(--font-work-sans);
  text-align: left;
  color: #000000;
}

.ai_input .form-group {
  width: 100% !important;
}
